/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

function Icon({ name, color, size, style, className }) {
  const IconTag = Icons[name];
  return <IconTag color={color} size={size} style={style} className={className} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: 24,
  style: null,
  className: '',
};

export default Icon;
