import React, {useState, useEffect, useCallback} from 'react';
import {Link, useParams, Route} from 'react-router-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import useUserStore from '../../stores/Users';
import {newAPI, oldAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import Dropdown from '../../components/Dropdown';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';
import './Agriculture.css';
import Npk from './npk/index';

function Agriculture() {
  // State & Fetch
  const {page} = useParams();
  const [menu, setMenu] = useState('plant');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useUserStore(state => state.token);

  const fetchArticle = useCallback(async () => {
    setData([]);
    setLoading(true);
    await newAPI
      .get('/articles')
      .then(res => {
        setData(res.data);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  }, []);

  const fetchDisease = useCallback(async () => {
    setData([]);
    setLoading(true);
    await newAPI
      .get('/v4/disease', {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        setData(res.data);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  }, []);

  const fetchGrowth = useCallback(async () => {
    setData([]);
    setLoading(true);
    await newAPI
      .get('/growth')
      .then(res => {
        setData(res.data);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  }, []);

  const fetchPlant = useCallback(async () => {
    setData([]);
    setLoading(true);
    await newAPI
      .get('/plants')
      .then(res => {
        setData(res.data);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  }, []);

  const fetchCultivation = useCallback(async () => {
    setData([]);
    setLoading(true);
    await oldAPI
      .get('/v2/articles')
      .then(res => {
        setData(res.data.data);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setMenu(page);

    switch (page) {
      case 'plant':
        fetchPlant();
        break;
      case 'cultivation':
        fetchCultivation();
        break;
      case 'disease':
        fetchDisease();
        break;
      case 'article':
        fetchArticle();
        break;
      case 'growth':
        fetchGrowth();
        break;

      default:
        break;
    }
  }, [page]);

  // VIEW
  // #region Dropdown view
  const getBreadItems = routeMenu => {
    const defaultBreadItems = [
      {label: 'Show', action: 'show'},
      {label: 'Edit', action: 'edit'},
    ];

    const growthBreadItems = [
      {label: 'Add Data', action: 'add'},
      {label: 'Show Data', action: 'show'},
      {label: 'Add Image', action: 'image'},
      {label: 'Analytics', action: 'analytic'},
      {label: 'Heat Map', action: 'heatmap'},
    ];

    switch (routeMenu) {
      case 'growth':
        return growthBreadItems;
      default:
        return defaultBreadItems;
    }
  };

  const handleBread = (routeMenu, routeId) => (
    <div>
      <Dropdown
        name="Action"
        url={`/agriculture/${routeMenu}/${routeId}`}
        items={getBreadItems(routeMenu)}
      />
    </div>
  );
  // #endregion

  const headerView = () => {
    let name = '';
    if (menu === 'article') name = 'Artikel';
    if (menu === 'disease') name = 'Hama Penyakit';
    if (menu === 'growth') name = 'Data Pertumbuhan';
    if (menu === 'plant') name = 'Tanaman';
    if (menu === 'cultivation') name = 'Budidaya';
    if (menu === 'npk') name = 'Form NPK';
    if (menu === 'calendar') name = 'Kalender Budidaya';
    return (
      <>
        <div className="page-header">
          <div className="row align-items-center">
            {/* Title */}
            <div className="col">
              <div className="page-pretitle">Menu</div>
              <h2 className="page-title">
                <span>Pertanian</span>
                <span className="mx-2"> / </span>
                <span>{name}</span>
              </h2>
            </div>

            {/* Actions Button */}
            {menu !== 'npk' && menu !== 'calendar' ? (
              <div className="col-auto ml-auto d-print-none">
                <Link
                  className="btn btn-gray"
                  to={`/agriculture/${menu}/create`}>
                  <div className="d-flex align-items-center">
                    <Icon
                      name="PlusCircle"
                      size={16}
                      style={{marginRight: 5}}
                    />{' '}
                    <span>Tambah {name}</span>
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };
  // #endregion

  const sidebarView = () => (
    <div className="col-md-3">
      <Route>
        <Card title="Menu" styleBody={{padding: '12px', textAlign: 'center'}}>
          <>
            <Link
              to="/agriculture/plant"
              className={`btn my-1 w-100 ${
                menu === 'plant' ? 'btn-blue' : ''
              }`}>
              Tanaman
            </Link>
            <Link
              to="/agriculture/cultivation"
              className={`btn my-1 w-100 ${
                menu === 'cultivation' ? 'btn-blue' : ''
              }`}>
              Budidaya
            </Link>
            <Link
              to="/agriculture/disease"
              className={`btn my-1 w-100 ${
                menu === 'disease' ? 'btn-blue' : ''
              }`}>
              Hama Penyakit
            </Link>
            <Link
              to="/agriculture/article"
              className={`btn my-1 w-100 ${
                menu === 'article' ? 'btn-blue' : ''
              }`}>
              Artikel
            </Link>

            <Link
              to="/agriculture/growth"
              className={`btn my-1 w-100 ${
                menu === 'growth' ? 'btn-blue' : ''
              }`}>
              Data Pertumbuhan
            </Link>

            <Link
              to="/agriculture/npk"
              className={`btn my-1 w-100 ${menu === 'npk' ? 'btn-blue' : ''}`}>
              Form NPK
            </Link>

            <Link
              to="/agriculture/calendar"
              className={`btn my-1 w-100 ${
                menu === 'calendar' ? 'btn-blue' : ''
              }`}>
              Kalender Budidaya
            </Link>
          </>
        </Card>
      </Route>
    </div>
  );

  const articleView = () => {
    const articleColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Judul',
        accessor: 'title',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '50%',
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: cellInfo => (
          <span className="text-capitalize">
            {cellInfo.row.original.category}
          </span>
        ),
      },
      {Header: 'Tanggal', accessor: 'created_at'},
      {
        Header: '',
        accessor: 'action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => handleBread('article', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="artikel"
                  columns={articleColumns}
                  data={data}
                  formOne={2}
                  formTwo={null}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const diseaseView = () => {
    const diseaseColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Gambar',
        accessor: 'img',
        width: '20%',
        Cell: cellInfo => {
          const image = `https://api.habibigarden.com/diseases/${cellInfo.row.original.img}`;
          return <img src={image} alt="penyakit" height="70" />;
        },
      },
      {
        Header: 'Nama Penyakit',
        accessor: 'name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '60%',
      },
      {
        Header: '',
        accessor: 'action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => handleBread('disease', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="Hama Penyakit"
                  columns={diseaseColumns}
                  data={data}
                  formOne={2}
                  formTwo={null}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const growthView = () => {
    const growthColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Tanaman',
        accessor: 'plant_name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: 'Kebun',
        accessor: 'farm_name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: 'Tgl Tanam',
        accessor: 'date_planted',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
        Cell: cellInfo => (
          <span className="text-capitalize">
            {cellInfo.row.original.date_planted
              ? moment(cellInfo.row.original.date_planted).format('DD/MM/YYYY')
              : '-'}
          </span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => handleBread('growth', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="Data Pertumbuhan"
                  columns={growthColumns}
                  data={data}
                  formOne={1}
                  formTwo={2}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const plantView = () => {
    const plantColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Tanaman',
        accessor: 'plants_name',
        filter: 'includes',
        width: '30%',
      },
      {
        Header: 'Keluarga',
        accessor: 'family',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '30%',
      },
      {
        Header: 'Genus',
        accessor: 'genus',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '30%',
      },
      {
        Header: '',
        accessor: 'action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => handleBread('plant', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                {/* <p>{JSON.stringify(data)}</p> */}
                <HabibiTable
                  title="tanaman"
                  columns={plantColumns}
                  data={data}
                  formOne={2}
                  formTwo={3}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const cultivationView = () => (
    <>
      <div className="col-md-9">
        <div className="col-md-12 text-center">
          <Card className="d-flex align-items-center py-1">
            <p className="text-red">Under Construction</p>
          </Card>
        </div>
      </div>
    </>
  );

  const npkView = () => (
    <div className="col-md-9">
      <div className="row">
        <Npk />
      </div>
    </div>
  );

  const calendarView = () => (
    <div className="col-md-9">
      <div className="col-md-12 text-center">
        <Card className="py-1 card-calendar">
          <FullCalendar
            initialView="dayGridMonth"
            selectable
            editable
            droppable
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            headerToolbar={{
              start: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,listMonth',
            }}
            eventClick={event =>
              console.log({
                title: event.event.title,
              })
            }
            dateClick={event =>
              console.log({
                dateStr: event.dateStr,
              })
            }
            select={event =>
              console.log({
                startStr: event.startStr,
                endStr: event.endStr,
              })
            }
            eventDrop={event =>
              console.log({
                event: event.event.startStr,
              })
            }
            events={[
              {
                title: 'Tanam Benih',
                start: '2022-06-12T12:30:00',
                end: null,
                note: 'Catatan 1',
                codeFarm: 'KODE_1',
              },
              {
                title: 'Pemupukan Lahan',
                start: '2022-06-08T12:30:00',
                end: '2022-06-10T15:30:00',
                note: 'Catatan 1',
                codeFarm: 'KODE_2',
              },
            ]}
            locale="id"
          />
        </Card>
      </div>
    </div>
  );

  const handleView = () => {
    switch (page) {
      case 'article':
        return articleView();

      case 'disease':
        return diseaseView();

      case 'growth':
        return growthView();

      case 'plant':
        return plantView();

      case 'cultivation':
        return cultivationView();

      case 'npk':
        return npkView();

      case 'calendar':
        return calendarView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row row-cards">
        {sidebarView()}

        {/* content */}
        {loading ? (
          <div className="col-md-9">
            <LoadingView />
          </div>
        ) : (
          handleView()
        )}
      </div>
    </div>
  );
}

export default Agriculture;
