import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { newAPI } from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

function FarmerEdit() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const { id } = useParams();
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const { register, errors, handleSubmit, setValue } = useForm();
  const history = useHistory();

  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUsers] = useState([]);
  const fetchUsers = async () => {
    // setIsLoading(true);
    await newAPI
      .get(`/v3/users/${id}/show`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const dataUsers = res.data[0];

        setValue('birth_date', moment(dataUsers.birth_date).format('YYYY-MM-DD'));
        setValue('email', dataUsers.email);
        setValue('first_name', dataUsers.first_name);
        setValue('last_name', dataUsers.last_name);
        setValue('gender', dataUsers.gender);
        setValue('phone', dataUsers.phone);
        setValue('city', dataUsers.city);
        setValue('status', dataUsers.role_id);
        setDataUsers({
          birth_date: moment(dataUsers.birth_date).format('YYYY-MM-DD'),
          email: dataUsers.email,
          first_name: dataUsers.first_name,
          last_name: dataUsers.last_name,
          gender: dataUsers.gender,
          phone: dataUsers.phone,
          city: dataUsers.city,
          status: dataUsers.role_id,
        });
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const updateFarmer = async (params) => {
    setIsLoading(true);
    const body = new FormData();
    body.append('first_name', params.first_name);
    body.append('last_name', params.last_name);
    body.append('email', params.email);
    body.append('birth_date', params.birth_date);
    body.append('gender', params.gender);
    body.append('phone', params.phone);
    body.append('city', params.city);
    body.append('password', params.password ? params.password : null);
    body.append('status', params.status);
    newAPI
      .put(`/v3/users/${id}`, body, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log(`berhasil upload ${JSON.stringify(res)}`);
      })
      .catch((error) => {
        console.log(`error ${error}`);
        Swal.fire('Changes are not saved', 'there is something wrong', 'error')
      })
      .finally(() => {
        fetchUsers();
        Swal.fire('Saved!', 'user has been updated', 'success')
      });
  };

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farmer')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Ubah</div>
            <h2 className="page-title"> Pengguna</h2>
          </div>
        </div>
      </div>
    </>
  );

  const EditView = () => (
    <>
      <Card title="Form Petani">
        <form onSubmit={handleSubmit(updateFarmer)}>
          <div className="row row-decks row-cards">
            {/* First Name */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Depan
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.first_name}
                name="first_name"
                type="text"
                className="form-control"
                placeholder="Masukan First Name"
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  First Name wajib diisi
                </div>
              )}
            </div>
            {/* Last Name */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Belakang
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.last_name}
                name="last_name"
                type="text"
                className="form-control"
                placeholder="Masukan Last Name"
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Last Name wajib diisi
                </div>
              )}
            </div>

            {/* Email */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Email
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.email}
                name="email"
                type="email"
                className="form-control"
                placeholder="Masukan Email"
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Email wajib diisi
                </div>
              )}
            </div>

            {/* Phone */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nomor Telepon
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.phone}
                name="phone"
                type="text"
                className="form-control"
                placeholder="Masukan Phone"
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Phone wajib diisi
                </div>
              )}
            </div>
            {/* Birt Date */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Tanggal Lahir
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.birth_date}
                name="birth_date"
                type="date"
                className="form-control"
                placeholder="Masukan Birt Date"
                onChange={(e) => console.log(e.target.value)}
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Birt Date wajib diisi
                </div>
              )}
            </div>

            {/* Gender */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Jenis Kelamin
              </label>
              <select
                name="gender"
                defaultValue={dataUser.gender}
                ref={register({ required: true })}
                className="form-select form-control"
              >
                <option value="Male">Laki-Laki</option>
                <option value="Female">Perempuan</option>
              </select>
              {errors.gender && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Gender wajib diisi
                </div>
              )}
            </div>

            {/* City */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Kota
              </label>
              <input
                ref={register({ required: true })}
                defaultValue={dataUser.city}
                name="city"
                type="text"
                className="form-control"
                placeholder="Masukan City"
              />
              {errors.name && (
                <div className="pt-1" style={{ color: 'red' }}>
                  City wajib diisi
                </div>
              )}
            </div>

            {/* Password */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Password
              </label>
              <input
                ref={register}
                name="password"
                type="text"
                className="form-control"
                placeholder="Masukan Password"
              />
              {/* {errors.modem && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Password wajib diisi
                </div>
              )} */}
            </div>

            {/* Status */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Status
              </label>
              <select
                name="status"
                defaultValue={dataUser.status}
                ref={register({ required: true })}
                className="form-select form-control"
              >
                {users.status === ADMIN ? (
                  <>
                    <option value="1">Admin</option>
                    <option value="2">Owner</option>
                    <option value="3">Petani</option>
                    <option value="4">Penjual</option>
                    <option value="6">Agronomist</option>
                    <option value="7">Engineer & Mekanikal</option>
                  </>
                ) : (
                  <option value="3">Petani</option>
                )}
              </select>
              {errors.status && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Status wajib diisi
                </div>
              )}
            </div>

            {/* tombol */}
            <div className="col-md-12">
              <input className="btn w-100 btn-primary px-5" type="submit" value="Tambah Petani" />
            </div>
          </div>
        </form>
      </Card>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          EditView()
        )}
      </div>
    </div>
  );
}

export default FarmerEdit;
