import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

import { newAPI } from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import { HabibiTable, SelectColumnFilter } from '../../components/HabibiTable';

function Notification() {
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const AGRONOMIST = 6;
  const ENGINEER = 7;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [farm, setFarm] = useState(null)
  const [title, setTitle] = useState(null)
  const [textBody, setTextBody] = useState(null)
  const [note, setNote] = useState(null)
  const [type, setType] = useState(null)
  const [day, setDay] = useState(null)
  const [startDate, onChangeStartDate] = useState(moment().utcOffset(7, true).toDate());
  const [startTime, setStartTime] = useState(moment().utcOffset(7, true).toDate());
  const [notifContent, setNotifContent] = useState(null)
  const [listFarm, setListFarm] = useState()
  const [listType, setListType] = useState([
    { label: 'Push Notification', value: 'push_notif' },
    { label: 'One Time Schedule', value: 'one_time_sch' },
    { label: 'Daily Repetition', value: 'daily_rep' },
    { label: 'Date Repetition', value: 'date_rep' },
  ])
  const [listDailyRep, setListDailyRep] = useState([
    { label: 'S', value: 1 },
    { label: 'S', value: 2 },
    { label: 'R', value: 3 },
    { label: 'K', value: 4 },
    { label: 'J', value: 5 },
    { label: 'S', value: 6 },
    { label: 'M', value: 7 },
  ])
  const [status, setStatus] = useState()
  const tempData = [];
  const [notif, setNotif] = useState([
    {
      id: 100,
      sensor: 'Arah Angin',
      notification_name: 'Arah Angin',
      notification_min: 'Arah Angin Kurang',
      notification_max: 'Arah Angin Lebih',
      action: 'Edit',
    },
  ]);
  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      width: '5%',
      Cell: (cellInfo) => <span>{cellInfo.row.index + 1}</span>,
    },
    {
      Header: 'Sensor',
      accessor: 'sensor',
      width: '20%',
    },
    {
      Header: 'Judul Notifikasi',
      accessor: 'notification_name',
      filter: 'includes',
      width: '20%',
    },
    { Header: 'Notifikasi Min', accessor: 'notification_min', width: '25%' },
    { Header: 'Notifikasi Max', accessor: 'notification_max', width: '25%' },
    {
      Header: '',
      accessor: 'action',
      Filter: false,
      disableSorting: true,
      Cell: (cellInfo) => (
        <div className="text-end">
          <span className="dropdown">
            <button
              type="button"
              className="btn dropdown-toggle align-text-top"
              data-bs-boundary="viewport"
              data-bs-toggle="dropdown"
            >
              Actions
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to={`/notification/${cellInfo.row.original.id}/edit`}>
                Edit
              </Link>
            </div>
          </span>
        </div>
      ),
    },
  ];

  const getDataNotification = async () => {
    setLoading(true);

    await newAPI
    .get('/v3/notification')
      .then((res) => {
        const data = res.data.data.notificationList;
        // console.log(data);
        for (let i = 0; i < data.length; i += 1) {
          tempData.push({
            id: data[i].id_jenis_sensor,
            sensor: data[i].jenis_sensor,
            notification_name: data[i].title,
            notification_min: data[i].text_kekurangan,
            notification_max: data[i].text_kelebihan,
            action: 'Edit',
          });
        }
        setNotif(tempData);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchDataFarm = async () => {
    setLoading(true);
    const tokenAuth = `Bearer ${token}`;
    await newAPI
    .get(`/v3/farms`, { headers: { Authorization: tokenAuth } })
    .then((res) => {
      const allData = res.data.data.last_data_device;

      const allFarm = []
      const dataFarm = []
      allData.forEach(item => {
        if (item.jenis_data === "config") {
          dataFarm.push({
            value: item.code_farm,
            label: item.name
          })
        }
      });
      allFarm.push({ value: "all", label: "Semua Kebun" }, ...dataFarm)
      setListFarm(allFarm);
      setLoading(false);
    })
    .catch((error) => {
      console.log(`error ${error}`);
      setLoading(false);
    });
  }

  const resetInputField = () => {
    setTitle("");
    setTextBody("");
    setNote("");
    setType(null);
    setFarm(null);
    setDay(null);
    onChangeStartDate(moment().utcOffset(7, true).toDate());
    setStatus("berhasil")
  };

  const sendDataNotif = async () => {
    setLoading(true);
    console.log(day);
    console.log(startTime);
    const body = new FormData();
    body.append('title', title);
    body.append('textbody', textBody);
    body.append('note', note);
    body.append('codefarm', farm);
    body.append('type', type);
    body.append('rep_day', day);
    body.append('pick_date', moment(startDate).format());
    body.append('pick_time', moment(startTime).format('HH:mm'));

    await newAPI
      .post(`/v3/notification`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log(`berhasil upload ${JSON.stringify(res)}`);
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
        resetInputField();
        Swal.fire('Success!', 'Notifikasi berhasil dikirim', 'success')
      })

  }

  useEffect(() => {
    // getDataNotification();
    fetchDataFarm()
  }, []);

  const headerView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Notifikasi</h2>
          </div>

          {/* Actions Button */}
          {/* <div className="col-auto ml-auto d-print-none">
            <Link className="btn ml-2 btn-gray" to="/notification/create">
              <div className="d-flex align-items-center">
                <Icon name="PlusCircle" size={16} style={{ marginRight: 5 }} />{' '}
                <span>Tambah Notifikasi</span>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );

  const pushNotificationView = () => (
    <div className="col-12">
      <Card title="Push Notifikasi">
        <div className="row">
          <div className="col md">
            <div className="border-start ps-4 pt-3 pb-3 border-5 ">
              <b><h4 style={{color: "red"}}>Perhatian !</h4></b>
              <p>Inputan yang anda kirim, akan langsung diterima oleh user</p>
              <p>Periksa terlebih dahulu sebelum mengirim notifikasi</p>
            </div>
          </div>

          <div className="col md mb-2">
            <div className="mb-3">
              <label htmlFor="floatingTextarea2" className="fw-bold mb-1">Judul</label>
              <input
                type="text"
                className="form-control"
                // id="floatingInputGrid"
                value={title}
                placeholder="Judul Notifikasi"
                onInput={e => setTitle(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="floatingTextarea2" className="fw-bold mb-1">Masukan konten notifikasi</label>
              <div className="col md">
                <textarea
                  className="form-control"
                  placeholder="Konten notifikasi"
                  value={textBody}
                  style={{ height: '100px' }}
                  onChange={e => setTextBody(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="floatingTextarea2" className="fw-bold mb-1">Masukan note</label>
              <div className="col md">
                <textarea
                  className="form-control"
                  placeholder="Note notifikasi"
                  value={note}
                  style={{ height: '100px' }}
                  onChange={e => setNote(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="floatingSelect" className="fw-bold mb-1">Kebun</label>
              <div className="mb-2">
                <Select
                  options={listFarm}
                  placeholder="Pilih kebun"
                  onChange={(e) => setFarm(e.value)}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="floatingSelect" className="fw-bold mb-1">Tipe Notifikasi</label>
              <div className="mb-2">
                <Select
                  options={listType}
                  placeholder="Pilih tipe"
                  onChange={(e) => setType(e.value)}
                />
              </div>
            </div>
            
            {(type === 'one_time_sch' || type === 'date_rep') &&
              <div className="mb-3">
                <label htmlFor="floatingSelect" className="fw-bold mb-1">Pilih tanggal dan waktu</label>
                <div className="mb-2">
                  <DatePicker
                    placeholderText="Pilih tanggal dan waktu"
                    className="form-control datepicker"
                    selected={startDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={(date) => onChangeStartDate(date)}
                  />
                </div>
              </div>
            }

            {(type === 'daily_rep') &&
            <>
              <div className="mb-3">
                <label htmlFor="floatingSelect" className="fw-bold mb-1">Pilih waktu</label>
                <div className="mb-2">
                  <DatePicker
                    selected={startTime}
                    className="form-control datepicker"
                    onChange={(time) => setStartTime(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="floatingSelect" className="fw-bold mb-1">Pilih hari</label>
                <div className="d-flex mb-3">
                  {listDailyRep.map((item, index) => (
                    <button
                      type="button" 
                      className="btn btn-outline-primary rounded-circle mx-1" 
                      onClick={() => setDay(item.value)}>
                      {item.label}
                    </button>
                  ))}
                </div>
              </div>
            </>
            }

            <div className="d-grid">
              <button
                disabled={!title || !farm || !textBody || !type}
                type="button" 
                className="btn btn-success" 
                onClick={() => sendDataNotif()}>
                {type === 'push_notif' ? 'Kirim' : 'Simpan'} Notifikasi
              </button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const adminView = () => (
    <div className="col-12">
      <Card styleBody={{ padding: 0, paddingTop: 20 }}>
        <HabibiTable
          title="pengguna"
          columns={columns}
          data={notif}
          formOne={null}
          formTwo={null}
          formThree={null}
          rowSize={[10, 30, 50]}
        />
      </Card>
    </div>
  );

  const ownerView = () => (
    <div className="col-12">
      <Card styleBody={{ padding: 0, paddingTop: 20 }}>
        <HabibiTable
          title="petani"
          columns={columns}
          data={notif}
          formOne={null}
          formTwo={null}
          formThree={null}
          rowSize={[10, 30, 50]}
        />
      </Card>
    </div>
  );

  const handleView = () => {
    switch (users.status) {
      case ADMIN:
      // case AGRONOMIST:
      // case ENGINEER:
        return pushNotificationView();
        // return adminView();

      // case OWNER:
      //   return ownerView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {/* header */}
      {headerView()}

      {/* content */}
      <div className="row row-deck row-cards">
        {loading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
}

export default Notification;
