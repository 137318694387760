/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import axios from "axios";
import idx from "idx";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import Icon from "../../components/Icon";
import LoadingView from "../../components/Loading";
import useUserStore from "../../stores/Users";
import { newAPI } from "../../services/baseAPI";
import "./FarmShow.css";
import plantImage from "./plantTemplateReport.png"


const FarmShow = () => {
  const { farmId, reportId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const token = useUserStore((state) => state.token);

  const [dataReport, setDataReport] = useState(null);
  const [kebun, setKebun] = useState(null);
  const [tanggal, setTanggal] = useState(null);
  const [tanaman, setTanaman] = useState(null);
  const [dataHabibiGrow, setDataHabibiGrow] = useState(null);
  const [dataHabibiStation, setDataHabibiStation] = useState(null);
  const [gambar, setGambar] = useState(null);
  const [kondisiIdealTanaman, setKondisiIdealTanaman] = useState(null);

  const TabelHijau = ({ prop: tabel }) => {
    const columnTitles = Object.keys(tabel[0]);
    const thData = () =>
      columnTitles.map((data) => <th className="th-hijau">{data}</th>);

    const tdData = () =>
      tabel.map((data) => (
        <tr className="tr-hijau">
          {columnTitles.map((title) =>
            title === "Parameter" ? (
              <td className="td-hijau" style={{ fontWeight: "bold" }}>
                {data[title]}
              </td>
            ) : (
              <td className="td-hijau">{data[title]}</td>
            )
          )}
        </tr>
      ));

    return (
      <table className="tabel">
        <thead>
          <tr>{thData()}</tr>
        </thead>
        <tbody>{tdData()}</tbody>
      </table>
    );
  };

  const TabelHitam = ({ prop: tabel }) => {
    const columnTitles = Object.keys(tabel[0]);
    const thData = () =>
      columnTitles.map((data) => <th className="th-hitam">{data}</th>);

    const tdData = () =>
      tabel.map((data) => (
        <tr className="tr-hitam">
          {columnTitles.map((title) =>
            title === "Parameter" ? (
              <td className="td-hitam" style={{ fontWeight: "bold" }}>
                {data[title]}
              </td>
            ) : (
              <td className="td-hitam">{data[title]}</td>
            )
          )}
        </tr>
      ));

    return (
      <table className="tabel">
        <thead>
          <tr>{thData()}</tr>
        </thead>
        <tbody>{tdData()}</tbody>
      </table>
    );
  };

  const fetchAll = async () => {
    // fetch data laporan mingguan start
    const resReport = await newAPI.get(`/v3/farms/${farmId}/weekly_report/${reportId}`, 
      {headers: { 
        Authorization: `Bearer ${token}` 
      }});
      console.log(resReport);
    setDataReport(resReport.data);
    setGambar(resReport.data.images);
    setTanggal(JSON.parse(resReport.data.report_date));
    setTanaman(resReport.data.commodity.split(","));
    // fetch data laporan mingguan end

    // fetch data kondisi ideal tanaman start
    const resIdeal = await newAPI.get(`/plants/${resReport.data.commodity.split(",")[0]}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setKondisiIdealTanaman([
      {
        Min: resIdeal.data[0].min_temperature,
        Parameter: "Suhu (°C)",
        Max: resIdeal.data[0].max_temperature,
      },
      // {
      //   Min: "-",
      //   Parameter: "Suhu Tanah (°C)",
      //   Max: "-",
      // },
      {
        Min: resIdeal.data[0].min_humidity,
        Parameter: "Kelembapan (%)",
        Max: resIdeal.data[0].max_humidity,
      },
      {
        Min: resIdeal.data[0].min_nutrient,
        Parameter: "Nutrisi Tanah (ppm)",
        Max: resIdeal.data[0].max_nutrient,
      },
      {
        Min: resIdeal.data[0].min_light,
        Parameter: "Intensitas Cahaya (lux)",
        Max: resIdeal.data[0].max_light,
      },
      {
        Min: resIdeal.data[0].min_moisture,
        Parameter: "Kadar Air (%)",
        Max: resIdeal.data[0].max_moisture,
      },
      {
        Min: resIdeal.data[0].min_ph,
        Parameter: "pH",
        Max: resIdeal.data[0].max_ph,
      },
    ]);
    // fetch data kondisi ideal tanaman end

    // fetch data nama kebun dan kode kebun start
    const resFarm = await newAPI.get(`/v3/farms/${farmId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setKebun({
      nama: resFarm.data.data.farms.name,
      kode: resFarm.data.data.farms.code_farm,
    });
    // fetch data nama kebun dan kode kebun end
    const fetchGrowData = async (device) => {
      const tanggal = JSON.parse(resReport.data.report_date);
      const from = moment(tanggal[0], "DD-MM-YYYY")
        .subtract(7, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      const end = moment(tanggal[1], "DD-MM-YYYY")
        .add({ hours: 16, minutes: 59, seconds: 59 })
        .format("YYYY-MM-DD HH:mm:ss");
      const growFormat = [
        [
          " ",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 1",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 2",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 3",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Zona 4",
          "Seluruh Zona",
        ],
        [
          "Tanggal",
          "Suhu (°C)",
          "Th Temp Min (°C)",
          "Th Temp Max (°C)",
          "Kelembapan (%)",
          "Th Hum Min (%)",
          "Th Hum Max (%)",
          "Kadar Air (%)",
          "Th Mois Min",
          "Th Mois Max",
          "Nutrisi (ppm)",
          "Th Nutr Min",
          "Th Nutr Max",
          "Cahaya (Lux)",
          "Th Light Min (Lux)",
          "Th Light Max (Lux)",
          "Batre (V)",
          "Th Bat Min (V)",
          "Th Bat Max (V)",
          "Tekanan (hPa)",
          "PH Tanah",
          "Th pH Min",
          "Th pH Max",
          "Suhu Tanah (°C)",
          "Th SoilTemp Min (°C)",
          "Th SoilTemp Max (°C)",
          "N",
          "P",
          "K",
          "Suhu (°C)",
          "Th Temp Min (°C)",
          "Th Temp Max (°C)",
          "Kelembapan (%)",
          "Th Hum Min (%)",
          "Th Hum Max (%)",
          "Kadar Air (%)",
          "Th Mois Min",
          "Th Mois Max",
          "Nutrisi (ppm)",
          "Th Nutr Min",
          "Th Nutr Max",
          "Cahaya (Lux)",
          "Th Light Min (Lux)",
          "Th Light Max (Lux)",
          "Batre (V)",
          "Th Bat Min (V)",
          "Th Bat Max (V)",
          "Tekanan (hPa)",
          "PH Tanah",
          "Th pH Min",
          "Th pH Max",
          "Suhu Tanah (°C)",
          "Th SoilTemp Min (°C)",
          "Th SoilTemp Max (°C)",
          "N",
          "P",
          "K",
          "Suhu (°C)",
          "Th Temp Min (°C)",
          "Th Temp Max (°C)",
          "Kelembapan (%)",
          "Th Hum Min (%)",
          "Th Hum Max (%)",
          "Kadar Air (%)",
          "Th Mois Min",
          "Th Mois Max",
          "Nutrisi (ppm)",
          "Th Nutr Min",
          "Th Nutr Max",
          "Cahaya (Lux)",
          "Th Light Min (Lux)",
          "Th Light Max (Lux)",
          "Batre (V)",
          "Th Bat Min (V)",
          "Th Bat Max (V)",
          "Tekanan (hPa)",
          "PH Tanah",
          "Th pH Min",
          "Th pH Max",
          "Suhu Tanah (°C)",
          "Th SoilTemp Min (°C)",
          "Th SoilTemp Max (°C)",
          "N",
          "P",
          "K",
          "Suhu (°C)",
          "Th Temp Min (°C)",
          "Th Temp Max (°C)",
          "Kelembapan (%)",
          "Th Hum Min (%)",
          "Th Hum Max (%)",
          "Kadar Air (%)",
          "Th Mois Min",
          "Th Mois Max",
          "Nutrisi (ppm)",
          "Th Nutr Min",
          "Th Nutr Max",
          "Cahaya (Lux)",
          "Th Light Min (Lux)",
          "Th Light Max (Lux)",
          "Batre (V)",
          "Th Bat Min (V)",
          "Th Bat Max (V)",
          "Tekanan (hPa)",
          "PH Tanah",
          "Th pH Min",
          "Th pH Max",
          "Suhu Tanah (°C)",
          "Th SoilTemp Min (°C)",
          "Th SoilTemp Max (°C)",
          "N",
          "P",
          "K",
          "Relay",
        ],
      ];

      const stationFormat = [
        [
          "Tanggal",
          "Arah Angin (°)",
          "Kecepatan Angin (m/s)",
          "Hembusan Angin (m/s)",
          "Hujan Per Jam (mm/jam)",
          "Hujan Per Hari",
          "Akumulasi Hujan",
          "Suhu (°C)",
          "Kelembapan (%)",
          "Tekanan (hPa)",
          "Volt Batre (V)",
          "Arus Batre (mA)",
          "Volt Solar (V)",
          "Arus Solar (mA)",
          "UV",
          "Solar",
          "Cahaya (Lux)",
        ],
      ];
      const avgFormat = [];
      const maxFormat = [];
      const minFormat = [];
      const arrayGrand = [];
      const arrayGrandMin = [];
      const arrayGrandMax = [];

      await newAPI
        .get(
          `/v3/analytic/${resFarm.data.data.farms.code_farm}/${device}/${from}/${end}`
        )
        .then((res) => {
          // console.log(res);
          // Format Date Grow
          if (device === "main") {
            const count = Object.keys(JSON.parse(res.data[0].data).zona).length;
            // console.log(count);
            const sensorAvg = [];
            const arrayTmp = [];
            const arrayTmpZone = [];
            const arrayMinTmpZone = [];
            const arrayMaxTmpZone = [];

            const zeroArray = [];
            let arrayTotal = [];
            let arrayAvgTemp = [];
            let increment = [];
            let numberAvgTemp = [];
            let dateNow = moment(res.data[0].time).format("DD/MM/YYYY");
            // arrayTmp.push("Tanggal");
            // arrayTmpZone.push("");
            // arrayMinTmpZone.push("Tanggal");
            // arrayMaxTmpZone.push("Tanggal");
            for (let i = 1; i <= count; i += 1) {
              arrayTmpZone.push(
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`
              );
              arrayTmp.push(
                "Suhu (°C)",
                "Kelembapan (%)",
                "Kadar Air (%)",
                "Nutrisi (ppm)",
                "Cahaya (Lux)",
                "Batre (V)",
                "Tekanan (hPa)",
                "PH Tanah",
                "Suhu Tanah (°C)",
                "N",
                "P",
                "K"
              );
              arrayMinTmpZone.push(
                "Suhu (°C)",
                "Kelembapan (%)",
                "Kadar Air (%)",
                "Nutrisi (ppm)",
                "Cahaya (Lux)",
                "Batre (V)",
                "Tekanan (hPa)",
                "PH Tanah",
                "Suhu Tanah (°C)",
                "N",
                "P",
                "K"
              );
              arrayMaxTmpZone.push(
                "Suhu (°C)",
                "Kelembapan (%)",
                "Kadar Air (%)",
                "Nutrisi (ppm)",
                "Cahaya (Lux)",
                "Batre (V)",
                "Tekanan (hPa)",
                "PH Tanah",
                "Suhu Tanah (°C)",
                "N",
                "P",
                "K"
              );

              arrayAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
              increment.push(0);
            }
            for (let j = 0; j < 12 * count; j += 1) {
              zeroArray.push(0);
            }

            avgFormat.push(arrayTmpZone, arrayTmp);
            minFormat.push(arrayTmpZone, arrayMinTmpZone);
            maxFormat.push(arrayTmpZone, arrayMaxTmpZone);

            res.data.forEach((item, k, arr) => {
              const sensor = JSON.parse(item.data);
              const sensorZone = [];
              const dateTmp = moment(item.time).format("DD/MM/YYYY");
              sensorZone.push(moment(item.time).format("DD-MM-YYYY HH:mm:ss"));
              // olah data avg,min,max
              if (dateNow === dateTmp) {
                numberAvgTemp = [];
                const numberMMTemp = [];
                for (let i = 1; i <= count; i += 1) {
                  const numberTemp = [];
                  numberTemp.push(
                    idx(sensor, (_) => _.zona[i.toString()].sensor.temp)
                      ? parseFloat(sensor.zona[i.toString()].sensor.temp)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.hum)
                      ? parseFloat(sensor.zona[i.toString()].sensor.hum)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.mois)
                      ? parseFloat(sensor.zona[i.toString()].sensor.mois)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.nutr)
                      ? parseFloat(sensor.zona[i.toString()].sensor.nutr)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.light)
                      ? parseFloat(sensor.zona[i.toString()].sensor.light)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.batre)
                      ? parseFloat(sensor.zona[i.toString()].sensor.batre)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.pressure)
                      ? parseFloat(sensor.zona[i.toString()].sensor.pressure)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.soilph)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilph)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.soilt)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilt)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.N)
                      ? parseFloat(sensor.zona[i.toString()].sensor.N)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.P)
                      ? parseFloat(sensor.zona[i.toString()].sensor.P)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.K)
                      ? parseFloat(sensor.zona[i.toString()].sensor.K)
                      : 0
                  );

                  if (numberTemp.filter((x) => x === 0).length < 9) {
                    numberAvgTemp.push(
                      ...numberTemp.map((e) => (Number.isNaN(e) ? 0 : e))
                    );
                    numberMMTemp.push(...numberTemp);
                    increment[i - 1] += 1;
                  } else {
                    numberAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  }
                }

                arrayTotal.push(numberMMTemp);

                for (let j = 0; j < 12 * count; j += 1) {
                  arrayAvgTemp[j] += numberAvgTemp[j];
                }
              } else {
                const max = [];
                const min = [];

                for (let i = 0; i < arrayTotal.length; i += 1) {
                  if (
                    JSON.stringify(arrayTotal[i]) === JSON.stringify(zeroArray)
                  ) {
                    arrayTotal.splice(i, 1);
                  }
                }
                for (let i = 1; i <= count; i += 1) {
                  for (let j = 0; j < 12 * i; j += 1) {
                    if (increment[i - 1] !== 0) {
                      arrayAvgTemp[j + 12 * (i - 1)] /= increment[i - 1];
                    }
                  }
                }
                for (let i = 0; i < 12 * count; i += 1) {
                  const minArray = arrayTotal.map((e) => e[i]);
                  const filterZero = minArray.filter((e) => e > 0);
                  max.push(
                    Math.max(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                  min.push(
                    Math.min(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                }
                minFormat.push([dateNow, ...min]);
                maxFormat.push([dateNow, ...max]);
                avgFormat.push([
                  dateNow,
                  ...arrayAvgTemp.map((e) => e.toFixed(2)),
                ]);
                const numberMMTemp = [];
                increment = [];
                arrayAvgTemp = [];
                numberAvgTemp = [];
                arrayTotal = [];
                dateNow = dateTmp;
                for (let i = 1; i <= count; i += 1) {
                  arrayAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  increment.push(0);
                }
                for (let i = 1; i <= count; i += 1) {
                  const numberTemp = [];
                  numberTemp.push(
                    idx(sensor, (_) => _.zona[i.toString()].sensor.temp)
                      ? parseFloat(sensor.zona[i.toString()].sensor.temp)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.hum)
                      ? parseFloat(sensor.zona[i.toString()].sensor.hum)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.mois)
                      ? parseFloat(sensor.zona[i.toString()].sensor.mois)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.nutr)
                      ? parseFloat(sensor.zona[i.toString()].sensor.nutr)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.light)
                      ? parseFloat(sensor.zona[i.toString()].sensor.light)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.batre)
                      ? parseFloat(sensor.zona[i.toString()].sensor.batre)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.pressure)
                      ? parseFloat(sensor.zona[i.toString()].sensor.pressure)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.soilph)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilph)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.soilt)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilt)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.N)
                      ? parseFloat(sensor.zona[i.toString()].sensor.N)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.P)
                      ? parseFloat(sensor.zona[i.toString()].sensor.P)
                      : 0,
                    idx(sensor, (_) => _.zona[i.toString()].sensor.K)
                      ? parseFloat(sensor.zona[i.toString()].sensor.K)
                      : 0
                  );
                  if (numberTemp.filter((x) => x === 0).length < 9) {
                    numberAvgTemp.push(
                      ...numberTemp.map((e) => (Number.isNaN(e) ? 0 : e))
                    );
                    increment[i - 1] += 1;
                  } else {
                    numberAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  }
                  numberMMTemp.push(...numberTemp);
                }
                arrayTotal.push(numberMMTemp);

                for (let j = 0; j < 12 * count; j += 1) {
                  arrayAvgTemp[j] += numberAvgTemp[j];
                }
              }

              if (k === arr.length - 1) {
                const max = [];
                const min = [];
                for (let i = 0; i < arrayTotal.length; i += 1) {
                  if (
                    JSON.stringify(arrayTotal[i]) === JSON.stringify(zeroArray)
                  ) {
                    arrayTotal.splice(i, 1);
                  }
                }

                for (let i = 1; i <= count; i += 1) {
                  for (let j = 0; j < 12; j += 1) {
                    if (increment[i - 1] !== 0) {
                      arrayAvgTemp[j + 12 * (i - 1)] /= increment[i - 1];
                    }
                  }
                }

                for (let i = 0; i < 12 * count; i += 1) {
                  const minArray = arrayTotal.map((e) => e[i]);
                  const filterZero = minArray.filter((e) => e > 0);
                  max.push(
                    Math.max(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                  min.push(
                    Math.min(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                }
                minFormat.push([dateNow, ...min]);
                maxFormat.push([dateNow, ...max]);
                avgFormat.push([
                  dateNow,
                  ...arrayAvgTemp.map((e) => e.toFixed(2)),
                ]);

                for (let j = 1; j <= 12 * count; j += 1) {
                  let totalGrand = 0;
                  const totalMin = [];
                  const totalMax = [];
                  for (let i = 2; i < avgFormat.length; i += 1) {
                    totalGrand += parseFloat(avgFormat[i][j]);
                    totalMin.push(parseFloat(minFormat[i][j]));
                    totalMax.push(parseFloat(maxFormat[i][j]));
                  }
                  arrayGrand.push(totalGrand / (avgFormat.length - 2));

                  arrayGrandMin.push(Math.min(...totalMin));
                  arrayGrandMax.push(Math.max(...totalMax));
                }

                avgFormat.push([...arrayGrand.map((e) => e.toFixed(2))]);
                minFormat.push([...arrayGrandMin]);
                maxFormat.push([...arrayGrandMax]);
              }

              for (let i = 1; i <= 4; i += 1) {
                sensorZone.push(
                  idx(sensor, (_) => _.zona[i.toString()].sensor.temp)
                    ? sensor.zona[i.toString()].sensor.temp
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[1]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[1]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.hum)
                    ? sensor.zona[i.toString()].sensor.hum
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[2]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[2]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.mois)
                    ? sensor.zona[i.toString()].sensor.mois
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[8]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[8]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.nutr)
                    ? sensor.zona[i.toString()].sensor.nutr
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[3]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[3]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.light)
                    ? sensor.zona[i.toString()].sensor.light
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[4]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[4]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.batre)
                    ? sensor.zona[i.toString()].sensor.batre
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[6]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[6]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.pressure)
                    ? sensor.zona[i.toString()].sensor.pressure
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].sensor.soilph)
                    ? sensor.zona[i.toString()].sensor.soilph
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[5]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[5]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.soilt)
                    ? sensor.zona[i.toString()].sensor.soilt
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[7]
                        .toString()
                        .split("-")[0]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(";")[7]
                        .toString()
                        .split("-")[1]
                    : "-",
                  idx(sensor, (_) => _.zona[i.toString()].sensor.N)
                    ? sensor.zona[i.toString()].sensor.N
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].sensor.P)
                    ? sensor.zona[i.toString()].sensor.P
                    : 0,
                  idx(sensor, (_) => _.zona[i.toString()].sensor.K)
                    ? sensor.zona[i.toString()].sensor.K
                    : 0
                );
              }

              sensorZone.push(
                idx(sensor, (_) => _.info.relay)
                  ? sensor.info.relay.toString()
                  : "-"
              );

              growFormat.push(sensorZone);
            });
            // editan hasani start
            const dataLingkungan = [];
            const dataMediaTanam = [];
            const objAvg = [
              { Parameter: "Average" },
              { Parameter: "Average" },
              { Parameter: "Average" },
              { Parameter: "Average" },
            ]; // 0 & 1 untuk zona 1, 2 & 3 untuk zona 2
            const objMin = [
              { Parameter: "Min" },
              { Parameter: "Min" },
              { Parameter: "Min" },
              { Parameter: "Min" },
            ]; // 0 & 1 untuk zona 1, 2 & 3 untuk zona 2
            const objMax = [
              { Parameter: "Max" },
              { Parameter: "Max" },
              { Parameter: "Max" },
              { Parameter: "Max" },
            ]; // 0 & 1 untuk zona 1, 2 & 3 untuk zona 2

            // lakukan pengulangan sebanyak data avgFormat[0]
            for (let i = 0; i < avgFormat[0].length; i += 1) {
              // kalau data pada grandtotal = 0 maka data tersebut tidak dimasukkan
              if (
                avgFormat[avgFormat.length - 1][i] !== "0.00" &&
                maxFormat[maxFormat.length - 1][i] !== "0.00"
              ) {
                // untuk zona 1
                if (avgFormat[0][i] === "Zona 1") {
                  // parameter suhu dan kelembapan dipisah karena tabelnya khusus pada tabel lingkungan.
                  // kalau bukan suhu dan kelembapan, maka data tsb dimasukkan ke bagian terpisah
                  // data dimasukkan dalam bentuk object dengan key = nama parameter, dan value = nilai parameter tsb
                  if (
                    avgFormat[1][i] === "Suhu (°C)" ||
                    avgFormat[1][i] === "Kelembapan (%)"
                  ) {
                    objAvg[0][avgFormat[1][i]] =
                      avgFormat[avgFormat.length - 1][i];
                    objMin[0][minFormat[1][i]] =
                      minFormat[minFormat.length - 1][i];
                    objMax[0][maxFormat[1][i]] =
                      maxFormat[maxFormat.length - 1][i];
                  } else {
                    objAvg[1][avgFormat[1][i]] =
                      avgFormat[avgFormat.length - 1][i];
                    objMin[1][minFormat[1][i]] =
                      minFormat[minFormat.length - 1][i];
                    objMax[1][maxFormat[1][i]] =
                      maxFormat[maxFormat.length - 1][i];
                  }
                } else if (avgFormat[0][i] === "Zona 2") {
                  if (
                    avgFormat[1][i] === "Suhu (°C)" ||
                    avgFormat[1][i] === "Kelembapan (%)"
                  ) {
                    objAvg[2][avgFormat[1][i]] =
                      avgFormat[avgFormat.length - 1][i];
                    objMin[2][minFormat[1][i]] =
                      minFormat[minFormat.length - 1][i];
                    objMax[2][maxFormat[1][i]] =
                      maxFormat[maxFormat.length - 1][i];
                  } else {
                    objAvg[3][avgFormat[1][i]] =
                      avgFormat[avgFormat.length - 1][i];
                    objMin[3][minFormat[1][i]] =
                      minFormat[minFormat.length - 1][i];
                    objMax[3][maxFormat[1][i]] =
                      maxFormat[maxFormat.length - 1][i];
                  }
                } else return;
              }
            }
            // masukkan data-data zona 1
            dataLingkungan.push([objAvg[0], objMin[0], objMax[0]]);
            dataMediaTanam.push([objAvg[1], objMin[1], objMax[1]]);

            // kalau ada lebih dari satu properties dari objAvg[2], artinya ada data zona 2, masukkan data-datanya
            if (Object.keys(objAvg[2]).length !== 1) {
              dataLingkungan.push([objAvg[2], objMin[2], objMax[2]]);
              dataMediaTanam.push([objAvg[3], objMin[3], objMax[3]]);
            }
            // hasil akhir dimasukkan ke dalam dataHabibiGrow
            setDataHabibiGrow({
              lingkungan: dataLingkungan,
              mediaTanam: dataMediaTanam,
            });
          }

          // Format Data Station
          if (device === "station") {
            let arrayAvg = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let arrayMM = [];
            let iteration = 0;

            const finalAvg = [
              [
                "Tanggal",
                "Arah Angin (°)",
                "Kecepatan Angin (m/s)",
                "Hembusan Angin (m/s)",
                "Hujan Per Jam (mm/jam)",
                "Hujan Per Hari",
                "Akumulasi Hujan",
                "Suhu (°C)",
                "Kelembapan (%)",
                "Tekanan (hPa)",
                "Volt Batre (V)",
                "Arus Batre (mA)",
                "Volt Solar (V)",
                "Arus Solar (mA)",
                "UV",
                "Solar",
                "Cahaya (Lux)",
              ],
            ];
            const finalMin = [
              [
                "Tanggal",
                "Arah Angin (°)",
                "Kecepatan Angin (m/s)",
                "Hembusan Angin (m/s)",
                "Hujan Per Jam (mm/jam)",
                "Hujan Per Hari",
                "Akumulasi Hujan",
                "Suhu (°C)",
                "Kelembapan (%)",
                "Tekanan (hPa)",
                "Volt Batre (V)",
                "Arus Batre (mA)",
                "Volt Solar (V)",
                "Arus Solar (mA)",
                "UV",
                "Solar",
                "Cahaya (Lux)",
              ],
            ];
            const finalMax = [
              [
                "Tanggal",
                "Arah Angin (°)",
                "Kecepatan Angin (m/s)",
                "Hembusan Angin (m/s)",
                "Hujan Per Jam (mm/jam)",
                "Hujan Per Hari",
                "Akumulasi Hujan",
                "Suhu (°C)",
                "Kelembapan (%)",
                "Tekanan (hPa)",
                "Volt Batre (V)",
                "Arus Batre (mA)",
                "Volt Solar (V)",
                "Arus Solar (mA)",
                "UV",
                "Solar",
                "Cahaya (Lux)",
              ],
            ];
            res.data.forEach((item) => {
              const dataStation = [];
              if (item.data.toString().charAt(0) === "{") {
                const station = JSON.parse(item.data);
                // console.log(station);
                dataStation.push(
                  moment(item.time).format("DD-MM-YYYY HH:mm:ss").toString(),
                  idx(station, (_) => _.sensor.windDirection)
                    ? station.sensor.windDirection.toString()
                    : "-",
                  idx(station, (_) => _.sensor.windSpeedAverage)
                    ? station.sensor.windSpeedAverage.toString()
                    : "-",
                  idx(station, (_) => _.sensor.windSpeedMax)
                    ? station.sensor.windSpeedMax.toString()
                    : "-",
                  idx(station, (_) => _.sensor.rainFallOneHour)
                    ? station.sensor.rainFallOneHour.toString()
                    : "-",
                  idx(station, (_) => _.sensor.rainFallOneDay)
                    ? station.sensor.rainFallOneDay.toString()
                    : "-",
                  idx(station, (_) => _.sensor.recordRain)
                    ? station.sensor.recordRain.toString()
                    : "-",
                  idx(station, (_) => _.sensor.temperature)
                    ? station.sensor.temperature.toString()
                    : "-",
                  idx(station, (_) => _.sensor.humidity)
                    ? station.sensor.humidity.toString()
                    : "-",
                  idx(station, (_) => _.sensor.pressure)
                    ? station.sensor.pressure.toString()
                    : "-",
                  idx(station, (_) => _.sensor.voltBatt)
                    ? station.sensor.voltBatt.toString()
                    : "-",
                  idx(station, (_) => _.sensor.currBatt)
                    ? station.sensor.currBatt.toString()
                    : "-",
                  idx(station, (_) => _.sensor.voltSolar)
                    ? station.sensor.voltSolar.toString()
                    : "-",
                  idx(station, (_) => _.sensor.currSolar)
                    ? station.sensor.currSolar.toString()
                    : "-",
                  idx(station, (_) => _.sensor.uv)
                    ? station.sensor.uv.toString()
                    : "-",
                  idx(station, (_) => _.sensor.solar)
                    ? station.sensor.solar.toString()
                    : "-",
                  idx(station, (_) => _.sensor.light)
                    ? station.sensor.light.toString()
                    : "-"
                );
              } else {
                const station = item.data.split(",");
                dataStation.push(
                  moment(item.time).format("DD-MM-YYYY HH:mm:ss")
                );
                for (let i = 0; i < 15; i += 1) {
                  dataStation.push(
                    idx(station, (_) => _[i]) ? station[i].toString() : "-"
                  );
                }
              }

              stationFormat.push(dataStation);
            });
            // setCsvData(stationFormat);
            // ----------------
            let dateNow = stationFormat[1][0].slice(0, 10);

            stationFormat.forEach((g, k, arr) => {
              if (k > 0) {
                const dateTmp = g[0].slice(0, 10);
                if (dateNow === dateTmp) {
                  arrayMM.push([
                    g[1] === "-" ? 0 : parseFloat(g[1]),
                    g[2] === "-" ? 0 : parseFloat(g[2]),
                    g[3] === "-" ? 0 : parseFloat(g[3]),
                    g[4] === "-" ? 0 : parseFloat(g[4]),
                    g[5] === "-" ? 0 : parseFloat(g[5]),
                    g[6] === "-" ? 0 : parseFloat(g[6]),
                    g[7] === "-" ? 0 : parseFloat(g[7]),
                    g[8] === "-" ? 0 : parseFloat(g[8]),
                    g[9] === "-" ? 0 : parseFloat(g[9]),
                    g[10] === "-" ? 0 : parseFloat(g[10]),
                    g[11] === "-" ? 0 : parseFloat(g[11]),
                    g[12] === "-" ? 0 : parseFloat(g[12]),
                    g[13] === "-" ? 0 : parseFloat(g[13]),
                    g[14] === "-" ? 0 : parseFloat(g[14]),
                    g[15] === "-" ? 0 : parseFloat(g[15]),
                    g[16] === "-" ? 0 : parseFloat(g[16]),
                  ]);
                  arrayAvg = [
                    g[1] === "-" ? 0 : (arrayAvg[0] += parseFloat(g[1])),
                    g[2] === "-" ? 0 : (arrayAvg[1] += parseFloat(g[2])),
                    g[3] === "-" ? 0 : (arrayAvg[2] += parseFloat(g[3])),
                    g[4] === "-" ? 0 : (arrayAvg[3] += parseFloat(g[4])),
                    g[5] === "-" ? 0 : (arrayAvg[4] += parseFloat(g[5])),
                    g[6] === "-" ? 0 : (arrayAvg[5] += parseFloat(g[6])),
                    g[7] === "-" ? 0 : (arrayAvg[6] += parseFloat(g[7])),
                    g[8] === "-" ? 0 : (arrayAvg[7] += parseFloat(g[8])),
                    g[9] === "-" ? 0 : (arrayAvg[8] += parseFloat(g[9])),
                    g[10] === "-" ? 0 : (arrayAvg[9] += parseFloat(g[10])),
                    g[11] === "-" ? 0 : (arrayAvg[10] += parseFloat(g[11])),
                    g[12] === "-" ? 0 : (arrayAvg[11] += parseFloat(g[12])),
                    g[13] === "-" ? 0 : (arrayAvg[12] += parseFloat(g[13])),
                    g[14] === "-" ? 0 : (arrayAvg[13] += parseFloat(g[14])),
                    g[15] === "-" ? 0 : (arrayAvg[14] += parseFloat(g[15])),
                    g[16] === "-" ? 0 : (arrayAvg[15] += parseFloat(g[16])),
                  ];
                  iteration += 1;
                  // console.log(arrayAvg);
                } else {
                  finalAvg.push([
                    dateNow,
                    ...arrayAvg.map((f) => (f / iteration).toFixed(2)),
                  ]);
                  const max = [];
                  const min = [];
                  for (let i = 0; i <= 15; i += 1) {
                    const minArray = arrayMM.map((f) => f[i]);
                    max.push(
                      Math.max(...(minArray.length === 0 ? [0] : minArray))
                    );
                    min.push(
                      Math.min(...(minArray.length === 0 ? [0] : minArray))
                    );
                  }
                  finalMin.push([dateNow, ...min]);
                  finalMax.push([dateNow, ...max]);
                  arrayMM = [];
                  arrayAvg = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  dateNow = dateTmp;
                  iteration = 0;

                  arrayMM.push([
                    g[1] === "-" ? 0 : parseFloat(g[1]),
                    g[2] === "-" ? 0 : parseFloat(g[2]),
                    g[3] === "-" ? 0 : parseFloat(g[3]),
                    g[4] === "-" ? 0 : parseFloat(g[4]),
                    g[5] === "-" ? 0 : parseFloat(g[5]),
                    g[6] === "-" ? 0 : parseFloat(g[6]),
                    g[7] === "-" ? 0 : parseFloat(g[7]),
                    g[8] === "-" ? 0 : parseFloat(g[8]),
                    g[9] === "-" ? 0 : parseFloat(g[9]),
                    g[10] === "-" ? 0 : parseFloat(g[10]),
                    g[11] === "-" ? 0 : parseFloat(g[11]),
                    g[12] === "-" ? 0 : parseFloat(g[12]),
                    g[13] === "-" ? 0 : parseFloat(g[13]),
                    g[14] === "-" ? 0 : parseFloat(g[14]),
                    g[15] === "-" ? 0 : parseFloat(g[15]),
                    g[16] === "-" ? 0 : parseFloat(g[16]),
                  ]);
                  arrayAvg = [
                    g[1] === "-" ? 0 : (arrayAvg[0] += parseFloat(g[1])),
                    g[2] === "-" ? 0 : (arrayAvg[1] += parseFloat(g[2])),
                    g[3] === "-" ? 0 : (arrayAvg[2] += parseFloat(g[3])),
                    g[4] === "-" ? 0 : (arrayAvg[3] += parseFloat(g[4])),
                    g[5] === "-" ? 0 : (arrayAvg[4] += parseFloat(g[5])),
                    g[6] === "-" ? 0 : (arrayAvg[5] += parseFloat(g[6])),
                    g[7] === "-" ? 0 : (arrayAvg[6] += parseFloat(g[7])),
                    g[8] === "-" ? 0 : (arrayAvg[7] += parseFloat(g[8])),
                    g[9] === "-" ? 0 : (arrayAvg[8] += parseFloat(g[9])),
                    g[10] === "-" ? 0 : (arrayAvg[9] += parseFloat(g[10])),
                    g[11] === "-" ? 0 : (arrayAvg[10] += parseFloat(g[11])),
                    g[12] === "-" ? 0 : (arrayAvg[11] += parseFloat(g[12])),
                    g[13] === "-" ? 0 : (arrayAvg[12] += parseFloat(g[13])),
                    g[14] === "-" ? 0 : (arrayAvg[13] += parseFloat(g[14])),
                    g[15] === "-" ? 0 : (arrayAvg[14] += parseFloat(g[15])),
                    g[16] === "-" ? 0 : (arrayAvg[15] += parseFloat(g[16])),
                  ];
                  iteration += 1;
                }

                if (k === arr.length - 1) {
                  finalAvg.push([
                    dateNow,
                    ...arrayAvg.map((f) => (f / iteration).toFixed(2)),
                  ]);
                  const max = [];
                  const min = [];
                  for (let i = 0; i <= 15; i += 1) {
                    const minArray = arrayMM.map((f) => f[i]);
                    max.push(
                      Math.max(...(minArray.length === 0 ? [0] : minArray))
                    );
                    min.push(
                      Math.min(...(minArray.length === 0 ? [0] : minArray))
                    );
                  }
                  finalMin.push([dateNow, ...min]);
                  finalMax.push([dateNow, ...max]);

                  for (let j = 1; j <= 16; j += 1) {
                    let totalGrand = 0;
                    const totalMin = [];
                    const totalMax = [];
                    for (let i = 1; i < finalAvg.length; i += 1) {
                      totalGrand += parseFloat(finalAvg[i][j]);
                      totalMin.push(parseFloat(finalMin[i][j]));
                      totalMax.push(parseFloat(finalMax[i][j]));
                    }
                    arrayGrand.push(totalGrand / (finalAvg.length - 1));

                    arrayGrandMin.push(Math.min(...totalMin));
                    arrayGrandMax.push(Math.max(...totalMax));
                  }

                  finalAvg.push([
                    "Grand Total",
                    ...arrayGrand.map((e) => e.toFixed(2)),
                  ]);
                  finalMin.push(["Grand Total", ...arrayGrandMin]);
                  finalMax.push(["Grand Total", ...arrayGrandMax]);
                }
              }
            });

            const dataCuaca = [];
            const objAvg = { Parameter: "Average" };
            const objMin = { Parameter: "Min" };
            const objMax = { Parameter: "Max" };
            for (let i = 1; i < finalAvg[0].length; i += 1) {
              if (
                finalAvg[finalAvg.length - 1][i] !== "0.00" &&
                finalMax[finalMax.length - 1][i] !== "0.00"
              ) {
                objAvg[finalAvg[0][i]] = finalAvg[finalAvg.length - 1][i];
                objMin[finalMin[0][i]] = finalMin[finalMin.length - 1][i];
                objMax[finalMax[0][i]] = finalMax[finalMax.length - 1][i];
              }
            }
            dataCuaca.push(objAvg, objMin, objMax);
            setDataHabibiStation(dataCuaca);
          }

          console.log("Sukses");
        })
        .catch((error) => {
          console.log("Err: ", error);
        });
    };

    await fetchGrowData("main");
    await fetchGrowData("station");
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const Laporan1 = () => (
      <>
        {/* tombol back dan save start */}
        <div className="container-fluid noprint ">
          <div className="page-header">
            <div className="row align-items-center">
              {/* tombol back save edit start */}
              <button
                aria-label="Back Button"
                type="button"
                onClick={() => history.push(`/farm/${farmId}/detail`)}
                className="col-2 "
                style={{
                  marginLeft: "8px",
                  marginRight: "10px",
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                  backgroundColor: "rgba(255, 0, 0, 0)",
                  borderWidth: 0,
                }}
              >
                <Icon name="ArrowLeft" color="#333" size={20} />
              </button>
              <div className="col-auto">
                <div className="page-pretitle">Kembali</div>
                <h2 className="page-title">Farm</h2>
              </div>
              {/* tombol back end */}

              {/* tombol save start */}
              <div className="col-auto">
                <button
                  className="btn btn-primary ms-3"
                  type="button"
                  onClick={() => window.print()}
                >
                  Save
                </button>
              </div>
              {/* tombol save end */}

              {/* tombol edit start */}
              {/* <div className="col-auto">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() =>
                    history.push(`/farm/${farmId}/detail/${reportId}/edit`)
                  }
                >
                  Edit
                </button>
              </div> */}
              {/* tombol edit end */}
            </div>
          </div>
        </div>
        {/* tombol back save edit end */}

        <div className="container-fluid" contentEditable>
          <div className="row gx-5">
            <div className="col">
              {/* identitas start */}
              <p className="text-center laporan-mingguan">Laporan Mingguan</p>
              <p className="text-center nama-kebun">{kebun.nama}</p>
              <img
                className="mx-auto d-block mb-3 logo-habibi"
                src="https://www.habibigarden.com/assets/img/Logo-HG.png"
                alt="logo habibi"
              />
              {/* identitas end */}

              {/* tanaman start */}
              <div className="row row-cols-2 kotak my-3 ms-2">
                <div className="col-4 text-center">
                  <img
                    className="icon-tanaman"
                    src={plantImage}
                    alt="tanaman"
                  />
                  <p className="kebun-produksi">
                    Kebun Produksi {dataReport.production}
                  </p>
                </div>
                <div className="col text-center">
                  <p className="nama-tanaman">{tanaman[1]}</p>
                  <p className="hst">{dataReport.hst} HST</p>
                  <p className="fase-jumlah-tanaman">
                    {dataReport.plant_phase}
                  </p>
                  <p className="fase-jumlah-tanaman">
                    {dataReport.plant_amount} tanaman
                  </p>
                </div>
              </div>
              {/* tanaman end */}
            </div>
            <div className="col">
              <p className="judul-tabel mt-4">Kondisi Ideal Tanaman</p>
              <TabelHijau prop={kondisiIdealTanaman} />
            </div>
            {/* identitas end */}
          </div>

          <div className="row">
            <div className="col">
              {dataHabibiGrow ? (
                <>
                  {/* tabel kondisi lingkungan start */}
                  <div className="row align-items-end">
                    <div className="col">
                      <p className="judul-tabel">Kondisi Lingkungan (Zona 1)</p>
                      <TabelHijau prop={dataHabibiGrow.lingkungan[0]} />
                    </div>
                  </div>
                  {/* tabel kondisi lingkungan end */}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col">
              {/* tabel status start */}
              <div className="d-flex justify-content-center mt-1">
                <table style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th className="judul-tabel">Tanggal</th>
                      <th className="judul-tabel">Sistem</th>
                      <th className="judul-tabel">Habibi Climate</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "rgb(217, 255, 217)" }}>
                    <tr>
                      <td className="judul-tabel" style={{ color: "black" }}>
                        {tanggal[0]} <br /> s.d. <br /> {tanggal[1]}
                      </td>
                      <td className="judul-tabel" style={{ color: "black" }}>
                        {dataReport.system}
                      </td>
                      <td className="judul-tabel" style={{ color: "black" }}>
                        {dataReport.habibi_climate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* tabel status end */}
            </div>
          </div>
          {dataHabibiGrow ? (
            <>
              <div className="row mt-1">
                <div className="col">
                  {/* tabel kondisi media tanam start */}
                  <p className="judul-tabel">Kondisi Media Tanam (Zona 1)</p>
                  <TabelHijau prop={dataHabibiGrow.mediaTanam[0]} />
                  {/* tabel kondisi media tanam end */}
                </div>
              </div>

              {dataHabibiGrow.lingkungan.length === 2 ? (
                <>
                  {/* Zona 2 kondisi lingkungan start */}
                  <div className="row mt-1">
                    <div className="col">
                      <p className="judul-tabel">Kondisi Lingkungan (Zona 2)</p>
                      <TabelHijau prop={dataHabibiGrow.lingkungan[1]} />
                    </div>
                  </div>
                  {/* Zona 2 kondisi lingkungan end */}
                  {/* Zona 2 kondisi media tanam start */}
                  <div className="row mt-1">
                    <div className="col">
                      <p className="judul-tabel">
                        Kondisi Media Tanam (Zona 2)
                      </p>
                      <TabelHijau prop={dataHabibiGrow.mediaTanam[1]} />
                    </div>
                  </div>
                  {/* Zona 2 kondisi media tanam end */}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {dataHabibiStation ? (
            <div className="row mt-1">
              <div className="col">
                {/* tabel kondisi cuaca start */}
                <p className="judul-tabel">Kondisi Cuaca</p>
                <TabelHitam prop={dataHabibiStation} />
                {/* tabel kondisi cuaca end */}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col">
              {/* kotak kendala start */}
              <div className="kotak-kendala my-3">
                <p className="judul-kotak ps-4">Kendala</p>
                <div
                  className="isi-kotak ps-4"
                  dangerouslySetInnerHTML={{ __html: dataReport.problem }}
                />
              </div>
              {/* kotak kendala end */}
            </div>
          </div>
          <div
            className={`row mt-3 ${
              dataHabibiGrow && dataHabibiGrow.lingkungan.length === 2 && dataHabibiStation 
                ? "halaman-baru"
                : ""
            }`}
          >
            <div className="col">
              {gambar && (
                <img
                  className="gambar-laporan border border-dark"
                  src={`https://api.habibigarden.com/report/${gambar}`}
                  alt="gambar pengamatan"
                  // style={{width: '400px'}}
                />
              )}
            </div>
            <div className="col">
              <div className="kotak-kendala">
                <p className="judul-kotak ps-4">Budidaya</p>
                <div
                  className="isi-kotak ps-4"
                  dangerouslySetInnerHTML={{ __html: dataReport.cultivate }}
                />
              </div>
            </div>
            <div className="col">
              <div className="kotak-kendala">
                <p className="judul-kotak ps-4">Kesimpulan</p>
                <div
                  className="isi-kotak ps-4"
                  dangerouslySetInnerHTML={{ __html: dataReport.conclusion }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return <>{isLoading ? <LoadingView /> : Laporan1()}</>;
};

export default FarmShow;
