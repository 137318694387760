import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory, Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import { newAPI, oldAPI } from '../../services/baseAPI';
import Card from '../../components/SimpleCard';
import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';
import useUserStore from '../../stores/Users';

function AgricultureShow() {
  // State & Fetch
  const { menu, id, index } = useParams();
  const history = useHistory();
  const token = useUserStore((state) => state.token);

  const [data, setData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // growth
  const [dataParameter, setDataParameter] = useState([]);
  const [dataDate, setDataDate] = useState([]);

  const fetchDisease = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await oldAPI.get(`/v2/pestdisease/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(res.data.data.pest_disease);

      setPlantData(res.data.data);
      setIsLoading(false);
      console.log(res.data.data.plant);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, []);

  const fetchArticle = useCallback(async () => {
    setIsLoading(true);
    await newAPI
      .get(`/articles/${id}`)
      .then((res) => {
        setData(res.data[0]);
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchGrowth = async () => {
    setIsLoading(true);
    await newAPI
      .get(`/growth/${id}/show`)
      .then((res) => {
        const growth = res.data;
        setData(growth[0][0]);
        setDataParameter(growth[1]);
        // setDataParameter(JSON.parse(growth[0].parameters));
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchDataGrowth = async () => {
    await newAPI
      .get(`/growth/${id}/show/${index}`)
      .then((res) => {
        if (res.data === undefined) {
          setDataDate([]);
        } else {
          setDataDate(JSON.parse(res.data.parameters_data));
        }
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => {});
  };

  const fetchDeleteDataGrowth = async (param) => {
    setIsLoading(true);
    await newAPI
      .delete(`/growth/${id}/show/${index}/${param}`)
      .then((res) => {
        // alert('berhasil');
        if (res.data === 1) {
          setDataDate([]);
        }
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => {
        fetchDataGrowth();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    switch (menu) {
      case 'article':
        fetchArticle();
        break;

      case 'disease':
        fetchDisease();
        break;
      case 'growth':
        if (index === undefined) {
          fetchGrowth();
        } else {
          fetchGrowth();
          fetchDataGrowth();
        }
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (index) {
      fetchDataGrowth();
    }
  }, [index]);

  // Controller

  // View
  const headerView = () => {
    let name = '';
    if (menu === 'article') name = 'Artikel';
    if (menu === 'disease') name = 'Hama Penyakit';
    if (menu === 'growth') name = 'Data Pertumbuhan';
    if (menu === 'plant') name = 'Tanaman';
    if (menu === 'cultivation') name = 'Data Budidaya';

    return (
      <>
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <button
              aria-label="Back Button"
              type="button"
              onClick={() => history.push(`/agriculture/${menu}`)}
              className="col-2"
              style={{
                marginLeft: '8px',
                marginRight: '10px',
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: 'rgba(255, 0, 0, 0)',
                borderWidth: 0,
              }}
            >
              <Icon name="ArrowLeft" color="#333" size={20} />
            </button>
            <div className="col">
              <div className="page-pretitle">Lihat</div>
              <h2 className="page-title">{name}</h2>
            </div>
          </div>
        </div>
      </>
    );
  };

  const articleView = () => (
    <Card className="d-flex align-items-center py-1">
      <>
        <h1 className="text-center">{data.title}</h1>
        <p className="text-center text-capitalize">
          {data.category} | {data.created_at}
        </p>
        <div className="text-center">
          <img
            src={`https://api.habibigarden.com/articles/${data.image}`}
            alt=""
            height={200}
            width={300}
            className="mb-3"
          />
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </>
    </Card>
  );

  const diseaseView = () => (
    <Card className="d-flex align-items-center py-1">
      <>
        <h1 className="text-center">{data.name}</h1>

        <div className="text-center">
          <img
            src={`https://habibigarden.com/dashboard/public/dist/img/hamapenyakit/${data.img}`}
            alt=""
            height={200}
            width={300}
            className="mb-3"
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Tanaman :</b>
              {plantData.map((plant) => plant.plants_name)}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Tanaman Inang:</b>
              {plantData}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Nama Ilmiah:</b>
              {data.scientific}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Dekskripsi:</b>
              <div dangerouslySetInnerHTML={{ __html: data.desc }} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Saran Perlakuan:</b>
              <div dangerouslySetInnerHTML={{ __html: data.treatment }} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Jenis HPT : </b>
              {data.hpt === 1 && 'Hama'}
              {data.hpt === 2 && 'Virus'}
              {data.hpt === 3 && 'Jamur'}
              {data.hpt === 4 && 'Bakteri'}
              {data.hpt === 5 && 'Tular Tanah'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Gejala :</b>
              <div dangerouslySetInnerHTML={{ __html: data.symptoms }} />
            </p>
          </div>
          <hr />
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Jenis Pestisida :</b>
              {data.type_pesticide}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Bahan Aktif :</b>
              <div dangerouslySetInnerHTML={{ __html: data.active_ingredient }} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Luas Area :</b>
              {data.area}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Satuan : :</b>
              {data.unit}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <p>
              <b> 0 (Kg/L) : :</b>
              {data.dvs_0}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 15 (g/L) : :</b>
              {data.dvs_15}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 20 (g/L) : :</b>
              {data.dvs_20}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 25 (g/L) : :</b>
              {data.dvs_25}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Dosis rata-rata :</b>
              {data.average_dosis}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Petunjuk Penggunaan :</b>
              <div dangerouslySetInnerHTML={{ __html: data.use_instruction }} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Tingkat Berbahaya : </b>
              {data.hpt === 1 && 'Cukup Berbahaya'}
              {data.hpt === 2 && 'Sangat Berbahaya Sekali'}
              {data.hpt === 3 && 'Berbahaya Sekali'}
              {data.hpt === 4 && 'Berbahaya'}
              {data.hpt === 5 && 'Tidak Berbahaya Pada Pemakaian Normal'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Tindak Pencegahan :</b>
              <div dangerouslySetInnerHTML={{ __html: data.precaution }} />
            </p>
            <hr />
          </div>
        </div>
      </>
    </Card>
  );

  const growthView = () => {
    const array = [];
    const parameter = dataParameter;
    const arrayColumn = (arr, n) => arr.map((x) => x[n]);
    parameter.forEach((element) => {
      const arrayTemp = JSON.parse(element.parameters_data);
      const arrayTmp = [];
      for (let i = 0; i < arrayTemp[0].length; i += 1) {
        const arraySum = arrayColumn(arrayTemp, i).reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        arrayTmp.push(parseFloat((arraySum / arrayTemp.length).toFixed(2)));
      }
      array.push(arrayTmp);
    });
    return (
      <Card className="d-flex  py-1">
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <p>Sayuran / Buah</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.plant_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Kebun</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.farm_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>HST</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.hst}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tangal Tanam</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.planted).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tanngal Panen</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Catatan</p>
                </div>
                <div className="col-md-10">
                  <p>:</p>
                  <div dangerouslySetInnerHTML={{ __html: data.note }} />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <table className="table table-vcenter card-table table-striped">
                <thead>
                  <tr>
                    <td rowSpan="2">No</td>
                    <td rowSpan="2">Parameter</td>
                    <td className="text-center" colSpan={dataParameter.length}>
                      Tanggal
                    </td>
                    <td className="text-center" rowSpan="2">
                      Grafik
                    </td>
                  </tr>
                  <tr>
                    {dataParameter.map((x) => (
                      <td className="text-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {/* <a className="btn btn-blue w-50" href="#">
                          {moment(x.created_at).format('DD/MM/Y')}
                        </a> */}
                        <Link
                          className="btn btn-blue w-50"
                          to={`/agriculture/${menu}/${id}/show/${moment(x.created_at).format(
                            'Y-MM-D'
                          )}`}
                        >
                          {moment(x.created_at).format('DD/MM/Y')}
                        </Link>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.parameters
                    ? JSON.parse(data.parameters).map((x, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{x}</td>
                          {array.map((param) => (
                            <td className="text-center">{param[i]}</td>
                          ))}
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </Card>
    );
  };

  const growthDataView = () => (
    <Card className="d-flex  py-1">
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <p>Sayuran / Buah</p>
              </div>
              <div className="col-md-10">
                <p>: {data.plant_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Kebun</p>
              </div>
              <div className="col-md-10">
                <p>: {data.farm_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>HST</p>
              </div>
              <div className="col-md-10">
                <p>: {data.hst}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tangal Tanam</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.planted).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tanngal Panen</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Catatan</p>
              </div>
              <div className="col-md-10">
                <p>:</p>
                <div dangerouslySetInnerHTML={{ __html: data.note }} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table className="table table-vcenter card-table table-striped">
              <thead>
                <tr>
                  <td rowSpan="2">No</td>
                  <td rowSpan="2">Parameter</td>
                  <td className="text-center" colSpan={dataDate.length}>
                    Nomor
                  </td>
                </tr>
                <tr>
                  {dataDate.map((x, i) => (
                    <td className="text-center">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <button
                        type="button"
                        onClick={() => {
                          fetchDeleteDataGrowth(i);
                        }}
                        className="btn btn-blue w-10"
                      >
                        {i}
                      </button>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.parameters
                  ? JSON.parse(data.parameters).map((x, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{x}</td>
                        {dataDate.map((param) => (
                          <td className="text-center">{param[i]}</td>
                        ))}
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </Card>
  );
  const betaView = () => (
    <>
      <Card className="d-flex align-items-center py-1">
        <p className="text-red">Under Construction</p>
      </Card>
    </>
  );

  const handleView = () => {
    switch (menu) {
      case 'article':
        return articleView();
      case 'disease':
        return diseaseView();
      case 'growth':
        if (index) {
          return growthDataView();
        }
        return growthView();
      default:
        return betaView();
    }
  };

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">
        {/* content */}
        {isLoading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
}

export default AgricultureShow;
