import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, useLoadScript, Marker } from '@react-google-maps/api';

import Modal from "react-modal";
import { awsKelanaAPI, newAPI } from "../../services/baseAPI";
import Icon from "../../components/Icon";
import useUserStore from "../../stores/Users";
import Card from "../../components/SimpleCard";
import LoadingView from "../../components/Loading";
import { HabibiTable, SelectColumnFilter } from "../../components/HabibiTable";
import CardDescMini from "../../components/CardDescMini";

function Kelana() {
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const EMPLOYEES = 6 || 7;
  const users = useUserStore((state) => state.user);
  // State
  const token = useUserStore((state) => state.token);
  const [data, setData] = useState([]);
  const [dataFarm, setDataFarm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false)
  const [modalData, setmodalData] = useState({ city: "", farmName: "" })
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs',
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [generalInfo, setGeneralInfo] = useState({
    allFarms: 0,
    allSumArea: 0,
  });

  const [modalShow, setModalShow] = useState(false);

  // Request Data
  const fetchFarms = useCallback(async () => {
    setLoading(true);

    await awsKelanaAPI
      .get("/")
      .then((res) => {
        setData(res.data.Items);
        const allData = res.data.Items.filter(e => e.status_farm === 'aktif');
        const SumAreaTemp = [];
        allData.forEach((e) => e.plant_data.forEach((f) => SumAreaTemp.push(f.area)));
        const SumArea = SumAreaTemp.filter((e) => e !== undefined).reduce(
          (total, value) => total + value,
          0
        );

        setGeneralInfo({
          allFarms: allData.length,
          allSumArea: SumArea,
        });
      })

      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchBatchFarms = useCallback(async () => {
    const farmBookmark = await newAPI.get('/kelana/batch', {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
    if (farmBookmark?.data?.length) {
      const kelanaIds = farmBookmark.data.filter(e => e.status === 'aktif').map(e => e.kelana_id);
      const farms = await awsKelanaAPI.get(`/${kelanaIds.join()}/batch`)
        .then(res => res.data)
        .catch(error => console.log(error));
      if (farms?.length) {
        setDataFarm(farms)
      }
    }
  }, []);

  useEffect(() => {
    if (users.status === ADMIN) {
      fetchFarms();
    } else {
      fetchBatchFarms();
      fetchFarms();
    }
  }, []);

  // Config
  const handle = (id) => (
    <div className="text-end">
      <span className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle align-text-top"
          data-bs-boundary="viewport"
          data-bs-toggle="dropdown"
        >
          Actions
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <Link className="dropdown-item" to={`/kelana/${id}/analytics`}>
            Analitik
          </Link>

          {users.status === 1 && (
            <Link className="dropdown-item" to={`/kelana/${id}/edit`}>
              Edit
            </Link>
          )}
          <Link className="dropdown-item" to={`/kelana/${id}/data`}>
            Data
          </Link>
          <Link className="dropdown-item" to={`/kelana/${id}/harvest`}>
            Panen
          </Link>
        </div>
      </span>
    </div>
  );

  const columns = [
    {
      Header: "No",
      accessor: "no",
      width: "5%",
      Cell: (cellInfo) => <span>{cellInfo.row.index + 1}</span>,
    },
    { Header: "Nama Kebun", accessor: "farm_name", width: "60%" },
    {
      Header: "Kota",
      accessor: "city",
      width: "30%",
      filter: "includes",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Status",
      accessor: "status_farm",
      Filter: SelectColumnFilter,
      filter: "includes",
      width: "20%",
      Cell: (cellInfo) => {
        let color;
        let role;
        switch (cellInfo.row.original.status_farm) {
          case "aktif":
            color = "badge bg-green w-100";
            role = "Aktif";
            break;

          case "hapus":
            color = "badge bg-orange w-100";
            role = "Tidak Aktif";
            break;
          default:
            color = "badge bg-yellow w-100";
            role = "Tamu";
            break;
        }
        return <span className={color}>{role}</span>;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      width: "30%",
      Filter: false,
      disableSorting: true,
      Cell: (cellInfo) => handle(cellInfo.row.original.id),
    },
  ];

  // View
  const HeaderView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Kelana</h2>
          </div>

          {/* Actions Button */}
          {users.status === ADMIN && (
            <div className="col-auto ml-auto d-print-none">
              <Link to="kelana/create">
                <button type="button" className="btn ml-2 btn-gray">
                  <div className="d-flex align-items-center">
                    <Icon
                      name="PlusCircle"
                      size={16}
                      style={{ marginRight: 5 }}
                    />{" "}
                    <span>Tambah Kebun</span>
                  </div>
                </button>
              </Link>
            </div>
          )}

        </div>
      </div>
    </>
  );

  const mapsView = useMemo(() => (
    <div className="col-sm-12 col-md-12" style={{ height: '400px' }}>
      {/* <LoadScript googleMapsApiKey="AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs"> */}
      <GoogleMap
        mapTypeId="satellite"
        center={{
          lat: -7.110184708035127,
          lng: 108.50068420458112,
        }}
        mapContainerClassName="card-body d-inline-block rounded mobile-100"
        zoom={8}
      >
        {data.map((item) => item.status_farm === "aktif" && (
          <Marker
            key={item.id}
            position={{
              lat: +(item.plant_data[0]?.rsc_data[0]?.latitude),
              lng: +(item.plant_data[0]?.rsc_data[0]?.longitude)
            }}
            onClick={() => {
              setmodalData({ city: item.city, farmName: item.farm_name })
              setisModalOpen(true)
            }}
          />
        ))}
      </GoogleMap>
      {/* </LoadScript> */}
    </div>
  ));

  const kelanaView = () => (
    <div className="row row-deck row-cards">
      {/* Card all farm */}
      <CardDescMini
        col="col-md-6"
        bgcolor="bg-green"
        iconName="MapPin"
        title={`${data ? generalInfo.allFarms : "-"} Kebun`}
        subtitle="Telah Dibuat"
      />

      {/* Card all farm */}
      <CardDescMini
        col="col-md-6"
        bgcolor="bg-orange"
        iconName="Map"
        title={`${data ? Math.round(generalInfo.allSumArea) : "-"} m²
          atau ${data ? (generalInfo.allSumArea / 10000).toFixed(2) : "-"} ha`}
        subtitle="Luas lahan telah terdaftar"
      />

      {/* Google Maps */}
      {isLoaded ? mapsView : <LoadingView />}
      {/* {users.status === ADMIN ? mapsView() : <></>} */}

      {/* Modal GMaps */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setisModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Nama Kebun : {modalData.farmName}</h2>
        <h2>Kota {modalData.city}</h2>
        <br />
        <br />
        <button className="btn w-full" type="button" onClick={() => setisModalOpen(false)}>
          Tutup
        </button>
      </Modal>

      <div className="col-12">
        <Card styleBody={{ padding: 0, paddingTop: 20 }}>
          <HabibiTable
            title="Kebun Kelana"
            columns={columns}
            data={users.status === ADMIN ? data : dataFarm}
            formOne={2}
            formTwo={3}
            formThree={null}
            rowSize={[10, 30, 50]}
          />
        </Card>
      </div>
    </div>
  );

  return (
    <div className="container-xl">
      {/* header */}
      <HeaderView />
      {/* content */}
      {!loading ? kelanaView() : <LoadingView />}
    </div>
  );
}

export default Kelana;
