import React, { useState, useCallback, useEffect, useReducer } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import Modal from "react-modal";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import LoadingView from "../../components/Loading";
import Icon from "../../components/Icon";
import { awsKelanaAPI, newAPI } from "../../services/baseAPI";
import Card from "../../components/SimpleCard";

// try reducer
const reducerGraph = (stateGraph, actionGraph) => {
  switch (actionGraph.type) {
    case "xaxisCategories":
      return {
        ...stateGraph,
        options: {
          ...stateGraph.options,
          xaxis: { ...stateGraph.options.xaxis, categories: actionGraph.data },
        },
      };
    case "series":
      return {
        ...stateGraph,
        series: [...actionGraph.data],
      };
    case "yaxisMax":
      return {
        ...stateGraph,
        options: {
          ...stateGraph.options,
          yaxis: { ...stateGraph.options.yaxis, max: actionGraph.data },
        },
      };
    case "yaxisMin":
      return {
        ...stateGraph,
        options: {
          ...stateGraph.options,
          yaxis: { ...stateGraph.options.yaxis, min: actionGraph.data },
        },
      };
    case "typeGraph":
      return {
        ...stateGraph,
        options: { ...stateGraph.options, type: actionGraph.data },
      };
    default:
      return { ...stateGraph };
  }
};

function KelanaAnalytics() {
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const EMPLOYEES = 6 || 7;
  const history = useHistory();
  const [data, setData] = useState(null);
  const [selectPlant, setSelectPlant] = useState({
    value: 1,
    label: `Pilih Tanaman`,
  });
  const [plant, setPlant] = useState({});
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  //   default latitude longitude
  const [latlng, setLatLng] = useState({
    lat: 0.7893,
    lng: 113.9213,
  });
  const [latplant, setLatplant] = useState([]);
  const [lngplant, setLngplant] = useState([]);
  const [samplenameplant, setsamplenameplant] = useState([]);
  const [newDate, setNewDate] = useState([]);
  const [latestRsc, setLatestRsc] = useState([]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const initialStateGraph = {
    options: {
      chart: {
        id: "graph",
      },
      stroke: {
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        },
      },
      xaxis: {
        type: "date",
        min: undefined,
        max: undefined,
        categories: [],
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      markers: {
        size: [8]
      },      
      yaxis: {
        min: undefined,
        max: undefined,
      },
      noData: {
        text: "Pilih Data Sensor",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      type: "line",
    },
    series: [],
  };
  const keyName = [
    {
      name: "Suhu (°C)",
      key: "temp",
    },
    {
      name: "Nitrogen (mg/kg)",
      key: "n",
    },
    {
      name: "Phospor (mg/kg)",
      key: "p",
    },
    {
      name: "pH",
      key: "ph",
    },
    {
      name: "rH(%)",
      key: "rh",
    },
    {
      name: "TDS (ppm)",
      key: "tds",
    },
    {
      name: "Kalium (mg/kg)",
      key: "k",
    }
  ];
  const keyNameAverage = [
    {
      name: "Suhu rata-rata (°C)",
      key: "tempAverage",
    },
    {
      name: "Nitrogen rata-rata (mg/kg)",
      key: "nAverage",
    },
    {
      name: "Phospor rata-rata (mg/kg)",
      key: "pAverage",
    },
    {
      name: "pH rata-rata",
      key: "phAverage",
    },
    {
      name: "rH rata-rata (%)",
      key: "rhAverage",
    },
    {
      name: "TDS rata-rata (ppm)",
      key: "tdsAverage",
    },
    {
      name: "Kalium rata-rata (mg/kg)",
      key: "kAverage",
    },
  ]
  const [stateGraph, dispatchGraph] = useReducer(
    reducerGraph,
    initialStateGraph
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs",
  });
  const [dataGraph, setDataGraph] = useState([]);

  // Request plant batch Data
  const getDataPlantsBatch = useCallback(
    (kelanaid) =>
      newAPI
        .get(`/kelana/${kelanaid}/plants`)
        .then((res) => res.data)
        .catch((error) => console.log(`error ${error}`)),
    []
  );

  const handlePlant = (keyPlant) => {
    const searchPlant = data.plant_data.find((e) => e.key_plant === keyPlant);

    let dateRsc = [];

    const rscData = searchPlant.rsc_data;
    if (rscData[0]?.latitude && rscData[0]?.longitude) {
      setLatLng({
        lat: rscData[0].latitude,
        lng: rscData[0].longitude,
      })
    }
    if (rscData[0]?.measurement.length) {
      // Date of measurement
      const arrayDate = rscData[0].measurement.map((dt) =>
        moment(dt.dateTime).format("DD/MM/YYYY")
      );
      dateRsc = arrayDate;

      dispatchGraph({ type: "xaxisCategories", data: arrayDate });
    }

    if (rscData[0]?.measurement.length) {
      const newestDate = rscData[0].measurement.sort(
        (a, b) => moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf()
      );
      setNewDate(newestDate[0].dateTime);
    }

    // rata-rata
    const obj = [];
    dispatchGraph({ type: "series", data: [] });
    keyName.forEach((f) => {
      const avg = [];
      dateRsc.forEach((g) => {
        let sumMeasurement = 0;
        let divider = 0;
        rscData.forEach((h) => {
          const dateTemp = h.measurement.find(
            (i) => moment(i.dateTime).format("DD/MM/YYYY") === g
          );
          if (dateTemp) {
            // += adalah
            sumMeasurement += +dateTemp[f.key];
            divider += 1;
          }
        });
        avg.push((sumMeasurement / divider).toFixed(2) || 0);
      });
      obj.push({
        name: `${f.name}`,
        data: avg,
        key: f.key,
      });
    });
    setDataGraph(obj);
    setPlant(searchPlant);
  };

  const typeHandleGraph = (type) => {
    dispatchGraph({ type: "typeGraph", data: type });
  };

  const handlePickData = (check, value) => {
    const dataGraphSeries = stateGraph.series;
    if (check) {
      const dataSensors = dataGraph.filter((sensor) => sensor.key === value);
        dispatchGraph({
        type: "series",
        data: [...dataGraphSeries, ...dataSensors],
      });
    } else {
      const dataSensors = dataGraphSeries.filter(
        (sensor) => sensor.key !== value
      );
      dispatchGraph({ type: "series", data: dataSensors });
    }
  };

  const maxHandleLimit = (value) => {
    if (value) {
      dispatchGraph({ type: "yaxisMax", data: parseInt(value, 10) });
    } else {
      dispatchGraph({ type: "yaxisMax", data: undefined });
    }
  };

  const minHandleLimit = (value) => {
    if (value) {
      dispatchGraph({ type: "yaxisMin", data: parseInt(value, 10) });
    } else {
      dispatchGraph({ type: "yaxisMin", data: undefined });
    }
  };

  // req farm data
  const fetchFarm = useCallback(async () => {
    setLoading(true);

    await awsKelanaAPI
      .get(`/${id}`)
      .then(async (res) => {
        const dataFarm = res.data.Item;
        const plantData = dataFarm.plant_data;
        const plantKey = [...new Set(plantData.map((e) => e.plant_id))].join();
        const dataPlantsBatch = await getDataPlantsBatch(plantKey);
        const newDataPlant = plantData.map((e) => ({
          ...e,
          plantData: dataPlantsBatch.find((f) => f.id === e.plant_id),
        }));
        setData({ ...dataFarm, plant_data: newDataPlant });
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchFarm();
  }, []);

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push("/kelana")}
            className="col-2"
            style={{
              marginLeft: "8px",
              marginRight: "10px",
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: "rgba(255, 0, 0, 0)",
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Analitik</div>
            <h2 className="page-title">Kebun Kelana</h2>
          </div>
        </div>
      </div>
    </>
  );

  const mapsView = () => (
    <>
      <div className="col-sm-12 col-md-12" style={{ height: "400px" }}>
        {/* <LoadScript googleMapsApiKey="AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs"> */}
        <GoogleMap
          mapTypeId="satellite"
          center={latlng}
          mapContainerClassName="card-body d-inline-block rounded mobile-100"
          zoom={25}
        >
          {Object.keys(plant).length &&
            plant.rsc_data.map((item, index) => (
              <Marker
                key={index.key_location}
                position={{ lat: item.latitude, lng: item.longitude }}
                onClick={() => {
                  const latesttempo = [];
                  const latestTemp = item.measurement.find(
                    (i) =>
                      moment(i.dateTime).format("DD/MM/YYYY") ===
                      moment(newDate).format("DD/MM/YYYY")
                  );

                  if (latestTemp) {
                    keyName.map((e) =>
                      latesttempo.push({
                        name: e.name,
                        rsc: latestTemp[e.key],
                      })
                    );
                  }

                  setLatestRsc(latesttempo);
                  setsamplenameplant(item.key_location);
                  setLatplant(item.latitude);
                  setLngplant(item.longitude);
                  setModalShow(!modalShow);
                }}
              />
            ))}
        </GoogleMap>

        {/* </LoadScript> */}
      </div>
    </>
  );

  const generalView = () => (
    <>
      <div className="row row-deck row-cards">
        <div className="col-md-12">
          <Card
            title="Pilih Tanaman"
            styleBody={{
              padding: 0,
              paddingTop: 20,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            {/* Pilih Device */}
            <div className="col-md-5" style={{ marginBottom: 20 }}>
              <label htmlFor="true" className="form-label">
                Tanaman Fase (Tanggal Penanaman)
              </label>
              <Select
                options={
                  data
                    ? data.plant_data.map((e) => ({
                        value: e.key_plant,
                        label: `${e.plantData.plants_name} ${e.growth_data[0]?.data.growthPhase  || ''} (${e.planted_date})`,
                      }))
                    : []
                }
                onChange={(e) => {
                  setSelectPlant(e);
                  handlePlant(e.value);
                }}
                value={selectPlant}
              />
            </div>
          </Card>
        </div>

        {/* Google Maps */}
        {isLoaded ? mapsView() : <LoadingView />}

        {/* {users.status === ADMIN ? mapsView() : <></>} */}

        {/* Modal GMaps */}
        <Modal
          isOpen={modalShow}
          onRequestClose={() => setModalShow(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 className="text-center">Titik Sample ke-{samplenameplant}</h2>
          <h2>latitude : {latplant}</h2>
          <h2>longitude : {lngplant}</h2>
          <h2>tanggal terakhir : {moment(newDate).format("DD/MM/YYYY")}</h2>

          {latestRsc.map((e) => (
            <p>
              {e.name} : {e.rsc}
            </p>
          ))}

          <br />
          <br />
          <button
            className="btn w-full"
            type="button"
            onClick={() => setModalShow(false)}
          >
            Tutup
          </button>
        </Modal>
        {/* Grafik */}
        <div className="col-md-12">
          <Card className=" mx-auto" title="Grafik Sensor">
            <div className="row">
              <div className="col-9 text-center">
                <Card title="Data Sample" className="d-flex  py-1">
                  <div className="row">
                    <div className="col-4">
                      <label htmlFor="true" className="form-label">
                        Jenis Grafik
                        <select
                          className="form-control"
                          onChange={(e) => typeHandleGraph(e.target.value)}
                        >
                          <option value="line">Line</option>
                          <option value="area">Area</option>
                          <option value="bar">Bar</option>
                        </select>
                      </label>
                    </div>
                    <div className="col-4">
                      <label htmlFor="true" className="form-label">
                        Min
                        <input
                          name="min"
                          type="number"
                          className="form-control"
                          onChange={(e) => minHandleLimit(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="col-4">
                      <label htmlFor="true" className="form-label">
                        Max
                        <input
                          name="max"
                          type="number"
                          className="form-control"
                          onChange={(e) => maxHandleLimit(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <ReactApexChart
                    type={stateGraph.options.type}
                    height={450}
                    options={stateGraph.options}
                    series={stateGraph.series}
                  />
                </Card>
              </div>
              <div className="col-3 ">
                <Card title="Parameter" className="d-flex  py-1 ">
                  <div className="row" style={{ marginBottom: "4px" }}>
                    {keyName.map((e) => (
                      <div className="col-md-12" key={e.key}>
                        <label>
                          <input
                            type="checkbox"
                            value={e.key}
                            onChange={(f) => {
                              handlePickData(f.target.checked, f.target.value);
                            }}
                          />
                          <span style={{ marginLeft: "4px" }}>{e.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          generalView()
        )}
      </div>
    </div>
  );
}

export default KelanaAnalytics;
