import React from 'react';

function Config() {
  // View
  const headerView = () => (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          {/* Title */}
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Konfigurasi</h2>
          </div>

          {/* Actions Button */}
          {/* {users.status === ADMIN ?
              <div className='col-auto ml-auto d-print-none'>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='Layers' size={16} style={{marginRight: 10}}/> <span>Umum</span></div>
                </button>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='Calendar' size={16} style={{marginRight: 10}}/> <span>Agenda</span></div>
                </button>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='List' size={16} style={{marginRight: 10}}/> <span>Daftar Project</span></div>
                </button>
              </div>
            : <div/>} */}
        </div>
      </div>
    </>
  );

  return (
    <div className="container-xl">
      {/* header */}
      {headerView()}

      {/* content */}
      <p>Task</p>
      <ul>
        <li>Ubah status kebun</li>
        <li>tambah device</li>
      </ul>
    </div>
  );
}

export default Config;
