import React, {useState, useEffect, useCallback, useRef} from 'react';
import ReactExport from 'react-export-excel';
import {
  Link,
  useHistory,
  useParams,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import readXlsxFile from 'read-excel-file';
import Swal from 'sweetalert2';

import {newAPI, oldAPI} from '../../../services/baseAPI';
import useUserStore from '../../../stores/Users';
import Icon from '../../../components/Icon';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';
import {HabibiTable, SelectColumnFilter} from '../../../components/HabibiTable';
import '../Agriculture.css';

function Growth() {
  const ADMIN = '1';
  // State & Fetch
  const {menu, id, option, date} = useParams();
  const {register, errors, handleSubmit, setValue} = useForm();
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [dataMenu, setDataMenu] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [graph, setGraph] = useState({
    options: {
      chart: {
        id: 'graph',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        type: 'date',
        min: undefined,
        max: undefined,
        categories: [],
      },
      yaxis: {
        min: undefined,
        max: undefined,
      },
      noData: {
        text: 'Pilih Data',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      type: 'line',
    },
  });
  const [heatmap, setHeatmap] = useState({
    chart: {
      height: 350,
      type: 'heatmap',
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 30,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 2,
              name: 'Hidup',
              color: '#00A100',
            },
            {
              from: -1,
              to: 0,
              name: 'Mati',
              color: '#FF0000',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
  });
  const [data, setData] = useState([]);
  const [opsFarm, setOpsFarm] = useState(null);
  const [tumbuhan, setTumbuhan] = useState(null);
  const [konversi, setKonversi] = useState(null);
  const [parameter, setParameter] = useState([]);
  const [dataFarm, setDataFarm] = useState([]);
  const [opsPlant, setOpsPlant] = useState(null);
  const [dataOption, setDataOption] = useState([]);
  const [menuAddData, setMenuAddData] = useState('template');

  // growth
  const [dataParameter, setDataParameter] = useState([]);
  const [dataImages, setDataImages] = useState([]);
  const [dataDate, setDataDate] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState(null);
  const importData = useRef(null);

  // heatmap
  const [bedengan, setBedengan] = useState([]);
  const [jenisHeatmap, setJenisHeatmap] = useState([0, 0]);
  const [dataHeatmap, setDataHeatmap] = useState([]);

  // inisialisasi template excel
  const [dataEksport, setDataEksport] = useState([]);
  const {ExcelFile} = ReactExport;
  const {ExcelSheet} = ReactExport.ExcelFile;
  const {ExcelColumn} = ReactExport.ExcelFile;

  useEffect(() => {
    // growth
    register('growthFarm');
    register('growthPlant');
    register('growthNote');
    register('growthType');
  }, []);

  // insert data select to setValue
  const selectValue = (SelectedOption, nameValue) => {
    const value = SelectedOption.map(val => val.value);
    setValue(nameValue, JSON.stringify(value));
  };

  // fectdata
  const fetchPlant = async () => {
    // setLoading(true);
    await oldAPI
      .get('/v2/pestdisease/create', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const arrTmp = [];
        const plant = res.data.data;
        plant.forEach(item =>
          arrTmp.push({
            value: item.id,
            label: item.plants_name,
          }),
        );
        // setLoading(false);

        setDataOption(arrTmp);
      })
      .catch(error => console.log(`error ${error}`));
  };

  const fetchFarm = async () => {
    // setLoading(true);
    await oldAPI
      .get('v2/farms', {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        const arrTmp = [];
        const farm = res.data.data;
        farm.forEach(item =>
          arrTmp.push({
            value: item.id,
            label: item.name,
          }),
        );
        // setLoading(false);
        setDataFarm(arrTmp);
      })
      .catch(error => console.log(`error ${error}`));
  };

  const fetchGrowthData = async () => {
    setLoading(true);
    await newAPI
      .get(`/growth/${id}/add`)
      .then(res => {
        const growth = res.data;
        const initParam = [];
        const isiData = [];
        const dataFix = [];
        const dataParam = JSON.parse(growth[0].parameters);
        console.log(dataParam);
        const params = [];
        const paramExcel = {Tanggal: '2000-05-31'};
        dataParam.forEach(e => {
          paramExcel[`'${e}'`] = 1;
        });
        console.log(paramExcel);
        setDataParameter(JSON.parse(growth[0].parameters));
        setDataImages(JSON.parse(growth[0].images));
        setDataEksport(paramExcel);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchGrowth = async () => {
    setLoading(true);
    await newAPI
      .get(`/growth/${id}/show`)
      .then(res => {
        const growth = res.data;
        setData(growth[0][0]);
        setDataParameter(growth[1]);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDeleteGrowth = async () => {
    setLoading(true);
    await newAPI
      .delete(`/growth/${id}`)
      .then(res => {
        if (res.data === 1) {
          console.log(res.data);
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
        history.push('/agriculture/growth');
      });
  };

  const fetchGraphicGrowth = async () => {
    setLoading(true);
    await newAPI
      .get(`/growth/${id}/show`)
      .then(res => {
        console.log(res);
        const array = [];
        const graphTemp = graph;
        const arrayDate = [];
        const arrayGraphic = [];
        const growth = res.data;
        const parameterArray = growth[1];
        setData(growth[0][0]);
        const arrayColumn = (arr, n) => arr.map(x => x[n]);
        parameterArray.forEach(element => {
          const arrayTemp = JSON.parse(element.parameters_data);
          const arrayTmp = [];
          for (let i = 0; i < arrayTemp[0].length; i += 1) {
            const arraySum = arrayColumn(arrayTemp, i).reduce(
              (a, b) => parseFloat(a) + parseFloat(b),
              0,
            );
            arrayTmp.push(parseFloat(arraySum / arrayTemp.length).toFixed(2));
          }
          arrayDate.push(moment(element.created_at).format('DD/MM/Y'));
          array.push(arrayTmp);
        });
        JSON.parse(growth[0][0].parameters).forEach((e, i) => {
          const arrayTmp = {};
          const arrayDataTemp = [];
          array.forEach(arr => {
            arrayDataTemp.push(arr[i]);
          });
          arrayTmp.data = arrayDataTemp;
          arrayTmp.name = e;
          arrayTmp.key = i;
          arrayGraphic.push(arrayTmp);
        });
        graphTemp.options.xaxis.categories = arrayDate;
        setGraph(graphTemp);
        setDataParameter(arrayGraphic);
        setDataImages(JSON.parse(growth[0][0].images));
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchHeatmap = async () => {
    setLoading(true);
    await newAPI
      .get(`/growth/${id}/show`)
      .then(res => {
        const growth = res.data;
        let arrayBaris = [];
        const arrayBedengan = [];
        for (let i = 0; i < growth[0][0].bedengan; i += 1) {
          arrayBaris = [];
          for (let j = 0; j < growth[0][0].baris; j += 1) {
            arrayBaris.push([1, 0]);
          }
          arrayBedengan.push(arrayBaris);
        }
        setBedengan(arrayBedengan);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchHeatmapShow = async () => {
    await newAPI
      .get(`/growth/${id}/heatmap`)
      .then(res => {
        const array2 = [];
        let array3 = [];

        const heatmapData = JSON.parse(res.data[res.data.length - 1].data);
        heatmapData.forEach((element, i) => {
          array3 = [];
          element.forEach((e, j) => {
            array3.push({
              x: `Baris-${j + 1}`,
              y: e[0],
            });
          });

          array2.push({
            name: `Bedengan-${i + 1}`,
            data: array3,
          });
        });
        setBedengan(res.data);
        setJenisHeatmap([res.data.length - 1, 0]);
        setDataHeatmap([...array2]);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {});
  };

  const fetchDataGrowth = async () => {
    setLoading(true);
    await newAPI
      .get(`/growth/${id}/show/${dataMenu}`)
      .then(res => {
        if (res.data === undefined) {
          setDataDate([]);
        } else {
          setDataDate(JSON.parse(res.data.parameters_data));
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setLoading(false));
  };

  const fetchDeleteDataGrowth = async param => {
    setLoading(true);
    await newAPI
      .delete(`/growth/data/${param}`)
      .then(res => {
        // alert('berhasil');
        if (res.data === 1) {
          console.log(res.data);
          setDataDate([]);
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        fetchGrowth();
        setLoading(false);
      });
  };

  const fetchDeleteImages = async param => {
    setLoading(true);
    await newAPI
      .delete(`/growth/${id}/image/${param}`)
      .then(res => {
        fetchGrowthData();
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const storeDataImport = params => {
    const arrayImport = importData.current;
    // const arrayImport = importData;
    arrayImport.splice(0, 1);
    arrayImport.forEach(async e => {
      const dateImport = e.splice(0, 1);
      const bodyImport = new URLSearchParams();
      bodyImport.append('date', dateImport[0]);
      bodyImport.append('parameters', e);
      console.log('kirim');
      await newAPI
        .post(`/growth/${id}/add`, bodyImport, {
          headers: {'content-type': 'application/x-www-form-urlencoded'},
        })
        .then(res => {
          console.log({res});
          Swal.fire(
            'Saved!',
            'Data pertumbuhan berhasil ditambahkan',
            'success',
          );
        })
        .catch(error => {
          Swal.fire(
            'Gagal!',
            'Data pertumbuhan tidak berhasil ditambahkan, coba beberapa saat lagi',
            'error',
          );
          console.log(`error ${error}`);
        })
        .finally(() => {
          // setLoading(false);
        });
    });
  };

  useEffect(() => {
    if (dataMenu) {
      fetchDataGrowth();
    } else {
      fetchGrowth();
    }
  }, [dataMenu]);

  useEffect(() => {
    switch (option) {
      case 'show':
        fetchGrowth();
        break;
      case 'add':
        fetchGrowthData();
        break;
      case 'image':
        fetchGrowthData();
        break;
      case 'analytic':
        fetchGraphicGrowth();
        fetchHeatmapShow();
        break;
      case 'heatmap':
        fetchHeatmap();
        break;
      default:
        break;
    }
  }, []);

  // #region Handle Delete
  const confirmDelete = param => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Apakah anda yakin akan menghapus data ini?')) {
      if (param !== undefined) {
        fetchDeleteDataGrowth(param);
      } else {
        fetchDeleteGrowth(param);
      }
    }
  };

  // Controller
  const storeData = params => {
    setLoading(true);
    const formBody = new URLSearchParams();
    const dataBody = new FormData();
    const array = [];
    let array1 = [];
    let array2 = [];

    switch (params.menu) {
      case 'growth':
        formBody.append('growthFarm', params.growthFarm);
        formBody.append('plants_id', params.growthPlant);
        formBody.append('type', params.growthType);
        formBody.append('note', params.growthNote);
        formBody.append('parameters', tumbuhan.concat(params.parameter));
        formBody.append('convert', konversi.concat(params.convert));
        formBody.append('hst', params.hst);
        formBody.append('planted', params.planted);
        formBody.append('harvest', params.harvest);
        newAPI
          .post('/growth', formBody, {
            headers: {'content-type': 'application/x-www-form-urlencoded'},
          })
          .then(res => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch(error => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire(
              'Saved!',
              'Data pertumbuhan berhasil ditambahkan',
              'success',
            );
          });
        break;
      case 'growthadd':
        formBody.append('date', params.date);
        formBody.append('parameters', params.parameter);
        newAPI
          .post(`/growth/${id}/add`, formBody, {
            headers: {'content-type': 'application/x-www-form-urlencoded'},
          })
          .then(res => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            // alert('Berhasil');
          })
          .catch(error => {
            // alert('Gagal');
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire(
              'Saved!',
              'Data pertumbuhan berhasil ditambahkan',
              'success',
            );
          });
        break;
      case 'growthimage':
        dataBody.append('image', image);
        dataBody.append('note', params.note);
        newAPI
          .post(`/growth/${id}/image`, dataBody, {
            headers: {'content-type': 'multipart/form-data'},
          })
          .then(res => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            fetchGrowthData();
          })
          .catch(error => {
            // alert('Gagal');
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Gambar berhasil ditambahkan', 'success');
          });
        break;
      case 'growtheat':
        for (let i = 0; i < params.hidup.length; i += 1) {
          array1 = [];
          for (let j = 0; j < params.hidup[0].length; j += 1) {
            array2 = [];
            array2.push(parseInt(params.hidup[i][j], 10));
            array2.push(parseInt(params.hpt[i][j], 10));
            array1.push(array2);
          }
          array.push(array1);
        }
        dataBody.append('data', JSON.stringify(array));
        dataBody.append('date', params.date);

        newAPI
          .post(`/growth/${id}/heatmap`, dataBody, {
            headers: {'content-type': 'multipart/form-data'},
          })
          .then(res => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            fetchGrowthData();
          })
          .catch(error => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Heatmap berhasil ditambahkan', 'success');
          });
        break;
      default:
        break;
    }
  };

  const DownloadTemplate = () => (
    <>
      <div className="col-md-6">
        <label htmlFor="true" className="form-label">
          Download Template Excel
        </label>
        <ExcelFile
          element={
            <button type="button" className="btn w-100 btn-success px-5">
              Download Template
            </button>
          }>
          <ExcelSheet data={[dataEksport]} name="Template">
            {Object.keys(dataEksport).map(e => (
              <ExcelColumn key={e} label={e.replace(/'/g, '')} value={e} />
            ))}
          </ExcelSheet>
        </ExcelFile>
        <p> </p>
        <p style={{color: 'red'}}>*Jika ingin download template Excel</p>
      </div>
    </>
  );

  const ImportDataExcel = () => (
    <>
      <div className="col-md-6">
        <label htmlFor="true" className="form-label">
          Import Data Excel{' '}
          <sup style={{marginLeft: 5, color: 'red'}}>
            *Jika ingin import data dalam format file Excel
          </sup>
        </label>
        <input
          ref={register}
          name="import-file"
          type="file"
          className="form-control"
          onChange={e => {
            readXlsxFile(e.target.files[0]).then(rows => {
              importData.current = rows;
            });
          }}
        />
        {errors.register && (
          <div className="pt-1" style={{color: 'red'}}>
            Tanggal wajib diisi
          </div>
        )}
      </div>
      <div className="col-md-12">
        <input
          type="button"
          value="Import Data"
          className="btn w-100 btn-primary px-5"
          onClick={storeDataImport}
        />
      </div>
    </>
  );

  const AddDataManual = () => (
    <>
      <Card title="Form Pertumbuhan Input Manual">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="growthadd" />

            {/* Panen */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Tanggal
              </label>
              <input
                ref={register({required: true})}
                name="date"
                type="date"
                defaultValue={moment().format('YYYY-MM-DD')}
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.date && (
                <div className="pt-1" style={{color: 'red'}}>
                  Tanggal wajib diisi
                </div>
              )}
            </div>

            {dataParameter.map((x, i) => (
              <div key={x} className="col-md-6">
                <label htmlFor="true" className="form-label">
                  {x}
                </label>
                <input
                  ref={register({required: true})}
                  name={`parameter.${i}`}
                  type="number"
                  step="0.01"
                  placeholder="Masukan Parameter"
                  className="form-control"
                />
              </div>
            ))}
          </div>
          <br />
          {/* tombol */}
          <div className="col-md-12">
            <input
              className="btn w-100 btn-primary px-5"
              type="submit"
              value="Tambah Data"
            />
          </div>
        </form>
      </Card>
    </>
  );

  const growthCreate = () => (
    <>
      {/* Data Pertumbuhan */}
      <Card title="Form Pertumbuhan">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="growth" />

            {/* Kebun */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Pilih Kebun
              </label>
              <Select
                options={dataFarm}
                value={opsFarm}
                onChange={OpsFarm => {
                  setOpsFarm(OpsFarm);
                  setValue('growthFarm', OpsFarm.value);
                }}
              />
            </div>

            {/* Nama Tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Tanaman
              </label>
              <Select
                options={dataOption}
                value={opsPlant}
                onChange={params => {
                  setOpsPlant(params);
                  setValue('growthPlant', params.value);
                }}
              />
            </div>

            {/* jenis tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Pilih Jenis Tanaman
              </label>
              <Select
                options={[
                  {value: 'sayuran', label: 'Sayuran'},
                  {value: 'buah', label: 'Buah'},
                ]}
                onChange={params => {
                  setValue('growthType', params.value);
                  if (params.value === 'sayur') {
                    setTumbuhan([
                      'Tinggi (cm)',
                      'Diameter pangkal (cm)',
                      'Jumlah daun total (helai)',
                      'Jumlah daun sehat (helai)',
                      'Jumlah daun sakit (helai)',
                      'Panjang daun (cm)',
                      'Lebar daun (cm)',
                      'Indeks luas daun (cm²)',
                      'Diameter kanopi tanaman (cm)',
                      'Diameter crop (cm)',
                      'Tinggi crop (cm)',
                      'pH',
                      'N (mg)',
                      'P',
                      'K',
                      'Suhu (°C)',
                      'Nutrisi (ppm)',
                      'Kelembaban Udara (%)',
                      'Intensitas Cahaya (lux)',
                      'Tekanan (hPa)',
                      'Kelembaban Tanah (%)',
                    ]);
                    setKonversi([
                      1, 10, 1, 1, 1, 1, 1, 0.25, 1, 1, 1, 3.5714, 1, 1, 1, 1,
                      0.02, 0.5, 0.001, 0.0005, 0.5,
                    ]);
                  } else {
                    setTumbuhan([
                      'Tinggi (cm)',
                      'Diameter pangkal (cm)',
                      'Diameter 1 m dari media (cm)',
                      'Jumlah cabang (unit)',
                      'Jumlah daun total (helai)',
                      'Jumlah daun sehat (helai)',
                      'Jumlah daun rusak (helai)',
                      'Panjang daun (cm)',
                      'Lebar daun (cm)',
                      'Indeks luas daun (cm²)',
                      'Jumlah buah (buah)',
                      'Diameter buah (cm)',
                      'Panjang buah (cm)',
                      'Jumlah bunga (bunga)',
                      'Jumlah populasi hidup (tanaman)',
                      'Berat segar tanaman (kg)',
                      'Umur bunga (buah)',
                      'pH',
                      'N (mg)',
                      'P',
                      'K',
                      'Suhu (°C)',
                      'Nutrisi (ppm)',
                      'Kelembaban Udara (%)',
                      'Intensitas Cahaya (lux)',
                      'Tekanan (hPa)',
                      'Kelembaban Tanah (%)',
                    ]);
                    setKonversi([
                      0.25, 10, 10, 5, 1, 1, 1, 1, 1, 0.25, 2.5, 2.5, 2.5, 1, 1,
                      1, 1, 3.5714, 1, 1, 1, 1, 0.02, 0.5, 0.001, 0.0005, 0.5,
                    ]);
                  }
                }}
              />
            </div>

            {/* hst */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                HST
              </label>
              <input
                ref={register({required: true})}
                name="hst"
                type="number"
                className="form-control"
                placeholder="Masukan HST"
              />
              {errors.hst && (
                <div className="pt-1" style={{color: 'red'}}>
                  hst wajib diisi
                </div>
              )}
            </div>

            {/* bedengan */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jumlah Bedengan
              </label>
              <input
                ref={register({required: true})}
                name="bedengan"
                type="number"
                className="form-control"
                placeholder="Masukan bedengan"
              />
              {errors.bedengan && (
                <div className="pt-1" style={{color: 'red'}}>
                  hst wajib diisi
                </div>
              )}
            </div>

            {/* baris */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jumlah Baris
              </label>
              <input
                ref={register({required: true})}
                name="baris"
                type="number"
                className="form-control"
                placeholder="Masukan baris"
              />
              {errors.baris && (
                <div className="pt-1" style={{color: 'red'}}>
                  baris wajib diisi
                </div>
              )}
            </div>

            {/* Awal Tanam */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Awal Tanam
              </label>
              <input
                ref={register({required: true})}
                name="planted"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.planted && (
                <div className="pt-1" style={{color: 'red'}}>
                  Awal Tanam wajib diisi
                </div>
              )}
            </div>
            {/* Panen */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Panen
              </label>
              <input
                ref={register({required: true})}
                name="harvest"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.harvest && (
                <div className="pt-1" style={{color: 'red'}}>
                  Panen wajib diisi
                </div>
              )}
            </div>

            {/* Note */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Catatan
              </label>
              <CKEditor
                editor={ClassicEditor}
                data="<p>Masukan Note disini</p>"
                onChange={(event, editor) => {
                  const dataCK = editor.getData();
                  setValue('growthNote', dataCK);
                }}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                  ],
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: 'Paragraph',
                        class: 'ck-heading_paragraph',
                      },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3',
                      },
                    ],
                  },
                }}
              />
            </div>

            {parameter.map((x, i) => (
              <div key={x} className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <input
                      ref={register({required: true})}
                      name={`parameter.${x}`}
                      type="text"
                      placeholder="Masukan Parameter"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-5">
                    <input
                      ref={register({required: true})}
                      name={`convert.${x}`}
                      type="number"
                      step="0.001"
                      placeholder="Masukan Konversi"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={() => {
                        const listParameter = [...parameter];
                        listParameter.splice(i, 1);
                        setParameter(listParameter);
                      }}>
                      Hapus
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {/* tombol tambah */}
            <div className="col-md-12">
              <button
                className="btn btn-primary px-5"
                type="button"
                onClick={() => {
                  setParameter([...parameter, parameter.length]);
                }}>
                Tambah
              </button>
            </div>
          </div>
          <br />
          {/* tombol */}
          <div className="col-md-12">
            <input
              className="btn w-100 btn-primary px-5"
              type="submit"
              value="Tambah Pertumbuhan"
            />
          </div>
        </form>
      </Card>
    </>
  );

  const growthAdd = () => (
    <>
      {/* Data Pertumbuhan */}
      <div className="row row-deck row-cards">
        <Card className="col-md-12" title="Add Data Option">
          <div className="row row-decks row-cards">
            {/* Pilih Opsi Add Data */}
            <div className="col-md-6">
              <div className="ms-auto">
                <label htmlFor="true" className="form-label">
                  Choose Add Data Option
                </label>
                <select
                  name="addDataOption"
                  className="form-select form-control"
                  defaultValue="template"
                  onChange={e => {
                    setMenuAddData(e.target.value);
                  }}>
                  <option value="template">Download Template Excel</option>
                  <option value="import">Import Data Excel</option>
                  <option value="manual">Add Data Manual</option>
                </select>
              </div>
            </div>
            {menuAddData === 'template' ? <DownloadTemplate /> : <></>}
            {menuAddData === 'import' ? <ImportDataExcel /> : <></>}
            {/* {menuAddData === "manual" ? <div className="col-md-6">Input data pertumbuhan manual dapat dilakukan dengan mengisi form di bawah ini</div> : <></>} */}
          </div>
        </Card>
        {menuAddData === 'manual' ? <AddDataManual /> : <></>}
      </div>
    </>
  );

  const growthAddImage = () => (
    <>
      {/* Data Pertumbuhan */}
      <Card title="Form Pertumbuhan">
        <div className="row">
          {dataImages &&
            dataImages.map((x, i) => (
              <div key={x[0]} className="col-md-3">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <img
                      src={`https://api.habibigarden.com/growths/${x[0]}`}
                      alt={x[1]}
                      style={{height: '300px'}}
                    />
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p>{x[1]}</p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={() => {
                        fetchDeleteImages(i);
                      }}>
                      Hapus
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <hr />
        <div className="row">
          <form onSubmit={handleSubmit(storeData)}>
            <div className="row row-decks row-cards">
              <input
                ref={register}
                name="menu"
                type="hidden"
                value="growthimage"
              />
              {/* Panen */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Masukan Gambar
                </label>
                <input
                  ref={register({required: true})}
                  name="image"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={e => {
                    setImage(e.target.files[0]);
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Gambar wajib diisi
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Masukan Keterangan
                </label>
                <input
                  ref={register({required: true})}
                  name="note"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Keterangan"
                />
                {errors.note && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Keterangan wajib diisi
                  </div>
                )}
              </div>
            </div>
            <br />
            {/* tombol */}
            <div className="col-md-12">
              <input
                className="btn w-100 btn-primary px-5"
                type="submit"
                value="Tambah Gambar"
              />
            </div>
          </form>
        </div>
      </Card>
    </>
  );

  const growthShow = () => {
    const array = [];
    const parameterArray = dataParameter;
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    parameterArray.forEach(element => {
      const arrayTemp = JSON.parse(element.parameters_data);
      const arrayTmp = [];
      for (let i = 0; i < arrayTemp[0].length; i += 1) {
        const arraySum = arrayColumn(arrayTemp, i).reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0,
        );
        arrayTmp.push(parseFloat((arraySum / arrayTemp.length).toFixed(2)));
      }
      array.push(arrayTmp);
    });

    return (
      <Card className="d-flex py-1">
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <p>Nama</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.plant_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Jenis</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.type}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Kebun</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.farm_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>HST</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.hst}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tangal Tanam</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.planted).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tanngal Panen</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Catatan</p>
                </div>
                <div className="col-md-10">
                  <p>:</p>
                  <div dangerouslySetInnerHTML={{__html: data.note}} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 ">
                  <p>Hapus</p>
                </div>
                <div className="col-md-10">
                  :{' '}
                  <button
                    type="button"
                    onClick={() => confirmDelete()}
                    className="btn btn-danger"
                  >
                    Hapus <Icon name="X" size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th className="bg-dark-lt" rowSpan="2">
                        No
                      </th>
                      <th className="bg-dark-lt" rowSpan="2">
                        Parameter
                      </th>
                      <td
                        className="bg-dark-lt text-center"
                        colSpan={dataParameter.length}>
                        Tanggal
                      </td>
                    </tr>
                    <tr>
                      {dataParameter.map(x => (
                        <td key={x} className="bg-dark-lt text-center">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          {/* <a className="btn btn-blue w-50" href="#">
                          {moment(x.created_at).format('DD/MM/Y')}
                        </a> */}
                          {/* <Link
                            className="btn btn-blue w-50"
                            to={`/agriculture/${menu}/${id}/show/${moment(x.created_at).format(
                              'Y-MM-D'
                            )}`}
                          >
                            {moment(x.created_at).format('DD/MM/Y')}
                          </Link> */}
                          <button
                            type="button"
                            className="btn btn-blue btn-block"
                            onClick={() =>
                              setDataMenu(moment(x.created_at).format('Y-MM-D'))
                            }>
                            {moment(x.created_at).format('DD/MM/Y')}
                          </button>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.parameters
                      ? JSON.parse(data.parameters).map((x, i) => (
                          <tr key={x}>
                            <td>{i + 1}</td>
                            <td>{x}</td>
                            {array.map(param => (
                              <td className="text-center">{param[i]}</td>
                            ))}
                          </tr>
                        ))
                      : ''}
                  </tbody>
                  <tfoot>
                    <tr className="bg-dark-lt">
                      <td
                        className="text-center strong text-uppercase"
                        colSpan="2">
                        {}
                      </td>
                      {dataParameter.map(x => (
                        <td key={x.id} className="text-center">
                          <button
                            type="button"
                            className="btn btn-danger btn-block"
                            onClick={() => confirmDelete(x.id)}>
                            Hapus <Icon name="X" size={20} />
                          </button>
                        </td>
                      ))}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      </Card>
    );
  };

  const growthDataView = () => (
    <Card className="d-flex  py-1">
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <p>Sayuran / Buah</p>
              </div>
              <div className="col-md-10">
                <p>: {data.plant_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Kebun</p>
              </div>
              <div className="col-md-10">
                <p>: {data.farm_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>HST</p>
              </div>
              <div className="col-md-10">
                <p>: {data.hst}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tangal Tanam</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.planted).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tanngal Panen</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Catatan</p>
              </div>
              <div className="col-md-10">
                <p>:</p>
                <div dangerouslySetInnerHTML={{__html: data.note}} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Kembali</p>
              </div>
              <div className="col-md-9">
                :{' '}
                <button type="button" onClick={() => setDataMenu(null)}>
                  Kembali
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-vcenter card-table table-striped">
                <thead>
                  <tr>
                    <td rowSpan="2">No</td>
                    <td rowSpan="2">Parameter</td>
                    <td className="text-center" colSpan={dataDate.length}>
                      Nomor
                    </td>
                  </tr>
                  <tr>
                    {dataDate.map((x, i) => (
                      <td key={x} className="text-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <button
                          type="button"
                          onClick={() => {
                            fetchDeleteDataGrowth(i);
                          }}
                          className="btn btn-blue w-10">
                          {i}
                        </button>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.parameters
                    ? JSON.parse(data.parameters).map((x, i) => (
                        <tr key={x}>
                          <td>{i + 1}</td>
                          <td>{x}</td>
                          {dataDate.map(param => (
                            <td className="text-center">{param[i]}</td>
                          ))}
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </Card>
  );

  const growthAnalytic = () => (
    <>
      <div className="row row-cards">
        <div className="col-12">
          <Card title="Gambar Tanaman" className="d-flex  py-1">
            <div className="row">
              {dataImages &&
                dataImages.map((x, i) => (
                  <div key={x[0]} className="col-md-3">
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <img
                          src={`https://api.habibigarden.com/growths/${x[0]}`}
                          alt={x[1]}
                          style={{height: '300px'}}
                        />
                      </div>
                    </div>
                    <div className="row text-center">
                      <div className="col-md-12">
                        <p>{x[1]}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
      </div>

      <div className="row row-cards">
        <div className="col-9">
          <Card title="Data Tanaman" className="d-flex  py-1">
            <div className="row">
              <div className="col-4">
                <label htmlFor="true" className="form-label">
                  Jenis Grafik
                  <select
                    className="form-control"
                    onChange={e => {
                      console.log(e.target.value);
                      setGraph({
                        options: {
                          ...graph.options,
                          type: e.target.value,
                        },
                      });
                    }}>
                    <option value="line">Line</option>
                    <option value="area">Area</option>
                    <option value="bar">Bar</option>
                  </select>
                </label>
              </div>
              <div className="col-4">
                <label htmlFor="true" className="form-label">
                  Min
                  <input
                    name="min"
                    type="number"
                    className="form-control"
                    onChange={e => {
                      if (e.target.value) {
                        setGraph({
                          options: {
                            ...graph.options,
                            yaxis: {
                              ...graph.options.yaxis,
                              min: parseInt(e.target.value, 10),
                            },
                          },
                        });
                      } else {
                        setGraph({
                          options: {
                            ...graph.options,
                            yaxis: {
                              ...graph.options.yaxis,
                              min: undefined,
                            },
                          },
                        });
                      }
                    }}
                  />
                </label>
              </div>
              <div className="col-4">
                <label htmlFor="true" className="form-label">
                  Max
                  <input
                    name="max"
                    type="number"
                    className="form-control"
                    onChange={e => {
                      if (e.target.value) {
                        setGraph({
                          options: {
                            ...graph.options,
                            yaxis: {
                              ...graph.options.yaxis,
                              max: parseInt(e.target.value, 10),
                            },
                          },
                        });
                      } else {
                        setGraph({
                          options: {
                            ...graph.options,
                            yaxis: {
                              ...graph.options.yaxis,
                              max: undefined,
                            },
                          },
                        });
                      }
                    }}
                  />
                </label>
              </div>
            </div>
            <ReactApexChart
              type={graph.options.type}
              height={450}
              options={graph.options}
              series={dataDate}
            />
          </Card>
        </div>
        <div className="col-3">
          <Card title="Parameter" className="d-flex  py-1">
            {JSON.parse(data.parameters).map((x, i) => (
              <div key={x} className="row" style={{marginBottom: '4px'}}>
                <div className="col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      onChange={e => {
                        console.log(dataParameter);
                        const index = dataDate.findIndex(p => p.key === i);
                        if (e.target.checked) {
                          setDataDate([...dataDate, dataParameter[i]]);
                        } else if (dataDate.length === 1) {
                          setDataDate([]);
                        } else {
                          dataDate.splice(index, 1);
                          const dataD = dataDate.pop();
                          setDataDate([...dataDate, dataD]);
                        }
                      }}
                    />
                    <span style={{marginLeft: '4px'}}>{x}</span>
                  </label>
                </div>
              </div>
            ))}
          </Card>
        </div>
      </div>
      <div className="row row-cards">
        <div className="col-12">
          <Card title="Data HeatMap" className="d-flex  py-1">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Tanggal
                  <select
                    className="form-control"
                    name="date"
                    onChange={k => {
                      const heatmapData = JSON.parse(
                        bedengan[k.target.value].data,
                      );
                      const array2 = [];
                      let array3 = [];
                      heatmapData.forEach((element, i) => {
                        array3 = [];
                        element.forEach((e, j) => {
                          array3.push({
                            x: `Baris-${j + 1}`,
                            y: e[jenisHeatmap[1]],
                          });
                        });

                        array2.push({
                          name: `Bedengan-${i + 1}`,
                          data: array3,
                        });
                      });
                      setJenisHeatmap([k.target.value, jenisHeatmap[1]]);
                      setDataHeatmap([...array2]);
                    }}>
                    {bedengan.map((e, i) => (
                      <option key={e} selected value={i}>
                        {moment(e.date).format('DD-MM-YYYY')}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jenis Data
                  <select
                    className="form-control"
                    name="type"
                    onChange={k => {
                      const heatmapData = JSON.parse(
                        bedengan[jenisHeatmap[0]].data,
                      );
                      const array2 = [];
                      let array3 = [];
                      heatmapData.forEach((element, i) => {
                        array3 = [];
                        element.forEach((e, j) => {
                          array3.push({
                            x: `Baris-${j + 1}`,
                            y: e[k.target.value],
                          });
                        });

                        array2.push({
                          name: `Bedengan-${i + 1}`,
                          data: array3,
                        });
                      });
                      if (k.target.value === '0') {
                        setHeatmap({
                          chart: {
                            height: 350,
                            type: 'heatmap',
                          },
                          plotOptions: {
                            heatmap: {
                              shadeIntensity: 0.5,
                              radius: 30,
                              useFillColorAsStroke: true,
                              colorScale: {
                                ranges: [
                                  {
                                    from: 1,
                                    to: 2,
                                    name: 'Hidup',
                                    color: '#00A100',
                                  },
                                  {
                                    from: -1,
                                    to: 0,
                                    name: 'Mati',
                                    color: '#FF0000',
                                  },
                                ],
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 1,
                          },
                        });
                      } else {
                        setHeatmap({
                          chart: {
                            height: 350,
                            type: 'heatmap',
                          },
                          plotOptions: {
                            heatmap: {
                              shadeIntensity: 0.5,
                              radius: 30,
                              useFillColorAsStroke: true,
                              colorScale: {
                                ranges: [
                                  {
                                    from: -1,
                                    to: 0,
                                    name: 'No HPT',
                                    color: '#00A100',
                                  },
                                  {
                                    from: 1,
                                    to: 2,
                                    name: 'HPT',
                                    color: '#FF0000',
                                  },
                                ],
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 1,
                          },
                        });
                      }
                      setJenisHeatmap([jenisHeatmap[0], k.target.value]);
                      setDataHeatmap([...array2]);
                    }}>
                    <option value="0">Status</option>
                    <option value="1">HPT</option>
                  </select>
                </label>
              </div>
            </div>

            <ReactApexChart
              type="heatmap"
              height={450}
              options={heatmap}
              series={dataHeatmap}
            />
          </Card>
        </div>
      </div>
    </>
  );

  const growthHeat = () => (
    <>
      {/* Data HeatMap */}
      <Card title="Heat Map">
        <div className="row">
          <form onSubmit={handleSubmit(storeData)}>
            <input ref={register} name="menu" type="hidden" value="growtheat" />
            <div className="row row-decks row-cards">
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Tanggal
                </label>
                <input
                  ref={register({required: true})}
                  name="date"
                  type="date"
                  defaultValue={moment().format('YYYY-MM-DD')}
                  className="form-control"
                  placeholder="Masukan tanggal"
                />
                {errors.date && (
                  <div className="pt-1" style={{color: 'red'}}>
                    Tanggal wajib diisi
                  </div>
                )}
              </div>
              <div className="accordion" id="bedengan">
                {bedengan &&
                  bedengan.map((e, i) => (
                    <div key={e} className="accordion-item">
                      <h2 className="accordion-header" id="heading-1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#bedengan-${i + 1}`}
                          aria-expanded="false">
                          {`Bedengan-${i + 1}`}
                        </button>
                      </h2>
                      <div
                        id={`bedengan-${i + 1}`}
                        className="accordion-collapse collapse "
                        data-bs-parent="#bedengan">
                        {e.map((x, j) => (
                          <div key={x} className="accordion-body pt-0">
                            <div className="form-label">{`Baris #${
                              j + 1
                            }`}</div>
                            <div>
                              <label className="form-check form-check-inline">
                                <input
                                  ref={register({required: true})}
                                  name={`hidup.${i}.${j}`}
                                  className="form-check-input"
                                  type="radio"
                                  value="1"
                                  defaultChecked={x[0] === 0 ? '' : 'false'}
                                />
                                <span className="form-check-label">Hidup</span>
                              </label>
                              <label className="form-check form-check-inline">
                                <input
                                  ref={register({required: true})}
                                  name={`hidup.${i}.${j}`}
                                  className="form-check-input"
                                  type="radio"
                                  value="0"
                                  defaultChecked={x[0] === 1 ? '' : 'false'}
                                />
                                <span className="form-check-label">Mati</span>
                              </label>

                              <label className="form-check form-check-inline">
                                <input
                                  ref={register({required: true})}
                                  name={`hpt.${i}.${j}`}
                                  className="form-check-input"
                                  type="radio"
                                  value="1"
                                  defaultChecked={x[0] === 1 ? '' : 'false'}
                                />
                                <span className="form-check-label">
                                  Yes HPT
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <input
                                  ref={register({required: true})}
                                  name={`hpt.${i}.${j}`}
                                  className="form-check-input"
                                  type="radio"
                                  value="0"
                                  defaultChecked={x[0] === 0 ? '' : 'false'}
                                />
                                <span className="form-check-label">No HPT</span>
                              </label>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Tambah Data"
                />
              </div>
            </div>
          </form>
        </div>
        {/* <div classNameName="row">
          <form onSubmit={handleSubmit(storeData)}>
            <input ref={register} name="menu" type="hidden" value="growtheat" />
            <div classNameName="row row-decks row-cards">
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Tanggal
                </label>
                <input
                  ref={register({ required: true })}
                  name="date"
                  type="date"
                  className="form-control"
                  placeholder="Masukan tanggal"
                />
                {errors.date && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Tanggal wajib diisi
                  </div>
                )}
              </div>

              <div className="col-12">
                <div className="table-responsive">
                  <table
                    className="table table-vcenter card-table table-striped table-bordered"
                    style={{ border: '1px' }}
                  >
                    <tr className="text-center">
                      <td>No</td>
                      {panjang.length && panjang.map((e) => <td>{e + 1}</td>)}
                    </tr>
                    {lebar.length &&
                      lebar.map((e, i) => (
                        <tr>
                          <td>{e + 1}</td>
                          {panjang.map((x, j) => (
                            <td>
                              <input
                                ref={register({ required: true })}
                                name={`tinggi.${i}.${j}`}
                                type="number"
                                min="0"
                                className="form-control"
                              />
                              <select
                                ref={register({ required: true })}
                                name={`hidup.${i}.${j}`}
                                className="form-control"
                              >
                                <option value="1">Hidup </option>
                                <option value="0">Mati</option>
                              </select>
                              <select
                                ref={register({ required: true })}
                                name={`hpt.${i}.${j}`}
                                className="form-control"
                              >
                                <option value="1">HPT Ya</option>
                                <option value="0">HPT No</option>
                              </select>
                            </td>
                          ))}
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
              <div className="col-12">
                <input className="btn w-100 btn-primary px-5" type="submit" value="Tambah Data" />
              </div>
            </div>
          </form>
        </div>
        <br /> */}
      </Card>
    </>
  );

  const handleView = () => {
    switch (option) {
      case 'show':
        if (dataMenu) {
          return growthDataView();
        }
        return growthShow();
      case 'add':
        return growthAdd();
      case 'image':
        return growthAddImage();
      case 'analytic':
        return growthAnalytic();
      case 'heatmap':
        return growthHeat();
      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            type="button"
            aria-label="Back Button"
            onClick={() => history.push('/agriculture/growth')}
            className="col-md-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            {/* <div className="page-pretitle">Tambah</div> */}
            <h2 className="page-title">Data Pertumbuhan</h2>
          </div>
        </div>
      </div>

      <div className="row">
        {/* content */}
        {loading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
}

export default Growth;
