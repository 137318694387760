import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import idx from 'idx';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Icon from '../../../components/Icon';
import { newAPI, oldAPI } from '../../../services/baseAPI';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';
import { stroke, grid, yaxis } from '../../../components/ChartOptions';

function Sensor(props) {
  // state
  const [dose, setDose] = useState([]);
  const [dataX, setDataX] = useState([]);
  const [dataY, setDataY] = useState([]);
  const [device, setDevice] = useState(null);
  const [sensor, setSensor] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(2);

  // csv state
  const [csvData, setCsvData] = useState([['Tanggal', 'Sensor']]);

  // datepicker state
  // const startDay = new Date();
  const endDay = new Date();
  const [startDate, onChangeStartDate] = useState(moment().subtract(31, 'days').set({
    hour: 0,
    minute: 0,
    second: 0,
  }).toDate());
  const [endDate, onChangeEndDate] = useState(moment().set({
    hour: 23,
    minute: 59,
    second: 59,
  }).toDate());
  // const [startDate, onChangeStartDate] = useState(startDay.setDate(startDay.getDate() - 31));
  // const [endDate, onChangeEndDate] = useState(endDay);

  // chart state
  const seriesX = [
    {
      name: idx(sensor, (_) => _.label) ? sensor.label : '',
      data: dataY,
    },
  ];
  const chartOptions = {
    chart: {
      fontFamily: 'inherit',
      parentHeightOffset: 0,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: 'zoom',
      },
    },
    stroke,
    grid,
    yaxis,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      categories: dataX,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  useEffect(async () => {
    const farm = props.farmCode;
    // start loading
    // setLoading(true);
    // await axios
    //   .post('https://api.habibigarden.com/v3/farms/data_sensor', {
    //     device: 'dose',
    //     farmCode: props.farmCode,
    //     timeMode: 'daily',
    //   })
    //   .then((res) => {
    //     if (res.data.length >= 1) setStatusDevice(true);
    //     // init variable for raw and temporary data from dose
    //     const rawDose = res.data;
    //     const tmpDose = [];
    //     const tmpX = [];
    //     const tmpY = [];
    //     // looping all data from dose
    //     rawDose.forEach((item) => {
    //       const parseData = JSON.parse(item.data);
    //       // restructure data
    //       tmpX.push(moment(item.time).format('DD/MM/YYYY HH:mm:ss'));
    //       tmpY.push(parseData.setting.ppm_pupuk);
    //       tmpDose.push({
    //         time: moment(item.time).format('DD/MM/YYYY HH:mm:ss'),
    //         sensorTDS: parseData.sensor.tds,
    //         sensorPH: parseData.sensor.ph,
    //         sensorTemp: parseData.sensor.temp,
    //         settingVolTorrent: parseData.setting.vol_torrent,
    //         settingPumpFlow: parseData.setting.pump_flow,
    //         settingFertFlow: parseData.setting.fert_flow,
    //         settingCF: parseData.setting.cf,
    //         settingSlope: parseData.setting.slope,
    //         settingIntercept: parseData.setting.intercept,
    //         settingPPM: parseData.setting.ppm_pupuk,
    //       });
    //     });
    //     // set new data
    //     setDataX(tmpX);
    //     setDataY(tmpY);
    //     setDose(tmpDose);
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => setLoading(false));
  }, []);

  // controller
  const mean = (number) => number.reduce((prev, curr) => prev + curr, 0) / number.length;

  const modus = (number) => {
    let mode = 0;
    let count = 0;

    for (let i = 0; i < number.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (number[j] === number[i]) {
          mode = number[j];
          count += 1;
        }
      }
    }

    return mode;
  };

  const processAnalytic = async () => {
    setIsVisible(false);
    setLoading(1);
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    const from = `${moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss")}`;
    const end = `${moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")}`;
    await newAPI
      .get(`/v3/analytic/${props.farmCode}/${device}/${from}/${end}`)
      .then((res) => {
        // Format Date Grow
        if (device === 'main') {
          let growFormat;
          switch (sensor) {
            case 'temp':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Suhu', 'Suhu', 'Suhu', 'Suhu'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.temp)
                      ? sensorData.zona[i.toString()].sensor.temp
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'hum':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Kelembaban', 'Kelembaban', 'Kelembaban', 'Kelembaban'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.hum)
                      ? sensorData.zona[i.toString()].sensor.hum
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'pressure':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Tekanan', 'Tekanan', 'Tekanan', 'Tekanan'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.pressure)
                      ? sensorData.zona[i.toString()].sensor.pressure
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'soilt':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Suhu Tanah', 'Suhu Tanah', 'Suhu Tanah', 'Suhu Tanah'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.soilt)
                      ? sensorData.zona[i.toString()].sensor.soilt
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'mois':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Kandungan Air', 'Kandungan Air', 'Kandungan Air', 'Kandungan Air'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.mois)
                      ? sensorData.zona[i.toString()].sensor.mois
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'nutr':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'Nutrisi', 'Nutrisi', 'Nutrisi', 'Nutrisi'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.nutr)
                      ? sensorData.zona[i.toString()].sensor.nutr
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'N':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'N', 'N', 'N', 'N'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.N)
                      ? sensorData.zona[i.toString()].sensor.N
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'P':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'P', 'P', 'P', 'P'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.P)
                      ? sensorData.zona[i.toString()].sensor.P
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'K':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'K', 'K', 'K', 'K'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.K)
                      ? sensorData.zona[i.toString()].sensor.K
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;
            case 'ph':
              growFormat = [
                [' ', 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4'],
                ['Tanggal', 'pH', 'pH', 'pH', 'pH'],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.soilph)
                      ? sensorData.zona[i.toString()].sensor.soilph
                      : 0
                  );
                }

                growFormat.push(sensorZone);
              });
              break;

            default:
              growFormat = [
                [
                  ' ',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 1',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 2',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 3',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Zona 4',
                  'Seluruh Zona',
                ],
                [
                  'Tanggal',
                  'Suhu',
                  'Kelembaban',
                  'Kadar Air',
                  'Nutrisi',
                  'Cahaya',
                  'Batre',
                  'Tekanan',
                  'PH Tanah',
                  'Suhu Tanah',
                  'N',
                  'P',
                  'K',
                  'Suhu',
                  'Kelembaban',
                  'Kadar Air',
                  'Nutrisi',
                  'Cahaya',
                  'Batre',
                  'Tekanan',
                  'PH Tanah',
                  'Suhu Tanah',
                  'N',
                  'P',
                  'K',
                  'Suhu',
                  'Kelembaban',
                  'Kadar Air',
                  'Nutrisi',
                  'Cahaya',
                  'Batre',
                  'Tekanan',
                  'PH Tanah',
                  'Suhu Tanah',
                  'N',
                  'P',
                  'K',
                  'Suhu',
                  'Kelembaban',
                  'Kadar Air',
                  'Nutrisi',
                  'Cahaya',
                  'Batre',
                  'Tekanan',
                  'PH Tanah',
                  'Suhu Tanah',
                  'N',
                  'P',
                  'K',
                  'Relay',
                ],
              ];
              res.data.forEach((item) => {
                const sensorData = JSON.parse(item.data);
                const sensorZone = [];
                sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                for (let i = 1; i <= 4; i += 1) {
                  sensorZone.push(
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.temp)
                      ? sensorData.zona[i.toString()].sensor.temp
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.hum)
                      ? sensorData.zona[i.toString()].sensor.hum
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.mois)
                      ? sensorData.zona[i.toString()].sensor.mois
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.nutr)
                      ? sensorData.zona[i.toString()].sensor.nutr
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.light)
                      ? sensorData.zona[i.toString()].sensor.light
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.batre)
                      ? sensorData.zona[i.toString()].sensor.batre
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.pressure)
                      ? sensorData.zona[i.toString()].sensor.pressure
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.soilph)
                      ? sensorData.zona[i.toString()].sensor.soilph
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.soilt)
                      ? sensorData.zona[i.toString()].sensor.soilt
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.N)
                      ? sensorData.zona[i.toString()].sensor.N
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.P)
                      ? sensorData.zona[i.toString()].sensor.P
                      : 0,
                    idx(sensorData, (_) => _.zona[i.toString()].sensor.K)
                      ? sensorData.zona[i.toString()].sensor.K
                      : 0
                  );
                }

                sensorZone.push(
                  idx(sensor, (_) => _.info.relay) ? sensor.info.relay.toString() : '-'
                );
                growFormat.push(sensorZone);
              });
              break;
          }

          setCsvData(growFormat);
        }

        // Format Data Dose
        if (device === 'dose') {
          let doseFormat;
          switch (sensor) {
            case 'tds':
              doseFormat = [['Tanggal', 'TDS']];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),
                  idx(doseData, (_) => _.sensor.tds) ? doseData.sensor.tds : 0,
                ]);
              });
              break;
            case 'ph':
              doseFormat = [['Tanggal', 'pH']];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),

                  idx(doseData, (_) => _.sensor.ph) ? doseData.sensor.ph : 0,
                ]);
              });
              break;
            case 'temp':
              doseFormat = [['Tanggal', 'Suhu']];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),

                  idx(doseData, (_) => _.sensor.temp) ? doseData.sensor.temp : 0,
                ]);
              });
              break;
            case 'vol_torent':
              doseFormat = [
                [
                  'Tanggal',
                  'Pump',
                  'Pemupukan',
                  'TDS',
                  'pH',
                  'Suhu',
                  'Pump Flow',
                  'Flow Pupuk',
                  'CF',
                  'Slope',
                  'Intercept',
                  'PPM',
                  'Relay',
                ],
              ];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),
                  idx(doseData, (_) => _.info.sts_pump) ? doseData.info.sts_pump : '-',
                  idx(doseData, (_) => _.info.fert_valve) ? 'ON' : 'OFF',
                  idx(doseData, (_) => _.sensor.tds) ? doseData.sensor.tds : 0,
                  idx(doseData, (_) => _.sensor.ph) ? doseData.sensor.ph : 0,
                  idx(doseData, (_) => _.sensor.temp) ? doseData.sensor.temp : 0,
                  idx(doseData, (_) => _.setting.pump_flow) ? doseData.setting.pump_flow : '-',
                  idx(doseData, (_) => _.setting.fert_flow) ? doseData.setting.fert_flow : '-',
                  idx(doseData, (_) => _.setting.cf) ? doseData.setting.cf : '-',
                  idx(doseData, (_) => _.setting.slope) ? doseData.setting.slope : '-',
                  idx(doseData, (_) => _.setting.intercept) ? doseData.setting.intercept : '-',
                  idx(doseData, (_) => _.setting.ppm_pupuk) ? doseData.setting.ppm_pupuk : '-',
                  idx(doseData, (_) => _.info.relay) ? doseData.info.relay : '-',
                ]);
              });
              break;
            case 'pump_flow':
              doseFormat = [['Tanggal', 'Pump Flow']];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),

                  idx(doseData, (_) => _.setting.pump_flow) ? doseData.setting.pump_flow : '-',
                ]);
              });
              break;
            case 'fert_flow':
              doseFormat = [['Tanggal', 'Flow Pupuk']];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),

                  idx(doseData, (_) => _.setting.fert_flow) ? doseData.setting.fert_flow : '-',
                ]);
              });
              break;

            default:
              doseFormat = [
                [
                  'Tanggal',
                  'Pump',
                  'Pemupukan',
                  'TDS',
                  'pH',
                  'Suhu',
                  'Pump Flow',
                  'Flow Pupuk',
                  'CF',
                  'Slope',
                  'Intercept',
                  'PPM',
                  'Relay',
                ],
              ];
              res.data.forEach((item) => {
                const doseData = JSON.parse(item.data);
                doseFormat.push([
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss'),
                  idx(doseData, (_) => _.info.sts_pump) ? doseData.info.sts_pump : '-',
                  idx(doseData, (_) => _.info.fert_valve) ? 'ON' : 'OFF',
                  idx(doseData, (_) => _.sensor.tds) ? doseData.sensor.tds : 0,
                  idx(doseData, (_) => _.sensor.ph) ? doseData.sensor.ph : 0,
                  idx(doseData, (_) => _.sensor.temp) ? doseData.sensor.temp : 0,
                  idx(doseData, (_) => _.setting.pump_flow) ? doseData.setting.pump_flow : '-',
                  idx(doseData, (_) => _.setting.fert_flow) ? doseData.setting.fert_flow : '-',
                  idx(doseData, (_) => _.setting.cf) ? doseData.setting.cf : '-',
                  idx(doseData, (_) => _.setting.slope) ? doseData.setting.slope : '-',
                  idx(doseData, (_) => _.setting.intercept) ? doseData.setting.intercept : '-',
                  idx(doseData, (_) => _.setting.ppm_pupuk) ? doseData.setting.ppm_pupuk : '-',
                  idx(doseData, (_) => _.info.relay) ? doseData.info.relay : '-',
                ]);
              });
              break;
          }

          setCsvData(doseFormat);
        }

        // Format Data Station
        if (device === 'station') {
          let stationFormat;
          switch (sensor) {
            case 'temperature':
              stationFormat = [['Tanggal', 'Suhu']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                    idx(station, (_) => _.sensor.temperature)
                      ? station.sensor.temperature.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                  dataStation.push(idx(station, (_) => _[5]) ? station[5].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'humidity':
              stationFormat = [['Tanggal', 'Kelembaban']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                    idx(station, (_) => _.sensor.humidity)
                      ? station.sensor.humidity.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[6]) ? station[6].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'pressure':
              stationFormat = [['Tanggal', 'Tekanan']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                    idx(station, (_) => _.sensor.pressure)
                      ? station.sensor.pressure.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[7]) ? station[7].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'voltBatt':
              stationFormat = [['Tanggal', 'Volt Batre']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.voltBatt)
                      ? station.sensor.voltBatt.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[8]) ? station[8].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'currBatt':
              stationFormat = [['Tanggal', 'Arus Batre']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.currBatt)
                      ? station.sensor.currBatt.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[9]) ? station[9].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'voltSolar':
              stationFormat = [['Tanggal', 'Volt Solar']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.voltSolar)
                      ? station.sensor.voltSolar.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[10]) ? station[10].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'uv':
              stationFormat = [['Tanggal', 'UV']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.uv) ? station.sensor.uv.toString() : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[12]) ? station[12].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'light':
              stationFormat = [['Tanggal', 'Cahaya']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.light) ? station.sensor.light.toString() : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                  dataStation.push(idx(station, (_) => _[14]) ? station[14].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'windDirection':
              stationFormat = [['Tanggal', 'Arah Angin']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                    idx(station, (_) => _.sensor.windDirection)
                      ? station.sensor.windDirection.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[0]) ? station[0].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'windSpeedAverage':
              stationFormat = [['Tanggal', 'Kecepatan Angin']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.windSpeedAverage)
                      ? station.sensor.windSpeedAverage.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[1]) ? station[1].toString() : '-');
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'windSpeedMax':
              stationFormat = [['Tanggal', 'Hembusan Angin']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.windSpeedMax)
                      ? station.sensor.windSpeedMax.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));

                  dataStation.push(idx(station, (_) => _[2]) ? station[2].toString() : '-');
                }

                stationFormat.push(dataStation);
              });

              break;
            case 'rainFallOneHour':
              stationFormat = [['Tanggal', 'Hujan Per Jam']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.rainFallOneHour)
                      ? station.sensor.rainFallOneHour.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                  for (let i = 0; i < 15; i += 1) {
                    dataStation.push(idx(station, (_) => _[3]) ? station[3].toString() : '-');
                  }
                }

                stationFormat.push(dataStation);
              });
              break;
            case 'rainFallOneDay':
              stationFormat = [['Tanggal', 'Hujan Per Hari']];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),

                    idx(station, (_) => _.sensor.rainFallOneDay)
                      ? station.sensor.rainFallOneDay.toString()
                      : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                  for (let i = 0; i < 15; i += 1) {
                    dataStation.push(idx(station, (_) => _[4]) ? station[4].toString() : '-');
                  }
                }

                stationFormat.push(dataStation);
              });
              break;

            default:
              stationFormat = [
                [
                  'Tanggal',
                  'Arah Angin',
                  'Kecepatan Angin',
                  'Hembusan Angin',
                  'Hujan Per Jam',
                  'Hujan Per Hari',
                  "Akumulasi Hujan",
                  'Suhu',
                  'Kelembaban',
                  'Tekanan',
                  'Volt Batre',
                  'Arus Batre',
                  'Volt Solar',
                  'Arus Solar',
                  'UV',
                  'Solar',
                  'Cahaya',
                ],
              ];
              res.data.forEach((item) => {
                const dataStation = [];
                if (item.data.toString().charAt(0) === '{') {
                  const station = JSON.parse(item.data);
                  dataStation.push([
                    moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                    idx(station, (_) => _.sensor.windDirection)
                      ? station.sensor.windDirection.toString()
                      : '-',
                    idx(station, (_) => _.sensor.windSpeedAverage)
                      ? station.sensor.windSpeedAverage.toString()
                      : '-',
                    idx(station, (_) => _.sensor.windSpeedMax)
                      ? station.sensor.windSpeedMax.toString()
                      : '-',
                    idx(station, (_) => _.sensor.rainFallOneHour)
                      ? station.sensor.rainFallOneHour.toString()
                      : '-',
                    idx(station, (_) => _.sensor.rainFallOneDay)
                      ? station.sensor.rainFallOneDay.toString()
                      : '-',
                    idx(station, (_) => _.sensor.recordRain)
                      ? station.sensor.recordRain.toString()
                      : '-',
                    idx(station, (_) => _.sensor.temperature)
                      ? station.sensor.temperature.toString()
                      : '-',
                    idx(station, (_) => _.sensor.humidity)
                      ? station.sensor.humidity.toString()
                      : '-',
                    idx(station, (_) => _.sensor.pressure)
                      ? station.sensor.pressure.toString()
                      : '-',
                    idx(station, (_) => _.sensor.voltBatt)
                      ? station.sensor.voltBatt.toString()
                      : '-',
                    idx(station, (_) => _.sensor.currBatt)
                      ? station.sensor.currBatt.toString()
                      : '-',
                    idx(station, (_) => _.sensor.voltSolar)
                      ? station.sensor.voltSolar.toString()
                      : '-',
                    idx(station, (_) => _.sensor.currSolar)
                      ? station.sensor.currSolar.toString()
                      : '-',
                    idx(station, (_) => _.sensor.uv) ? station.sensor.uv.toString() : '-',
                    idx(station, (_) => _.sensor.solar) ? station.sensor.solar.toString() : '-',
                    idx(station, (_) => _.sensor.light) ? station.sensor.light.toString() : '-',
                  ]);
                } else {
                  const station = item.data.split(',');
                  dataStation.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
                  for (let i = 0; i < 15; i += 1) {
                    dataStation.push(idx(station, (_) => _[i]) ? station[i].toString() : '-');
                  }
                }

                stationFormat.push(dataStation);
              });
              break;
          }

          setCsvData(stationFormat);
        }

        setIsVisible(true);
        console.log('Sukses');
      })
      .catch((error) => {
        // setIsOpen(false);
        console.log('Err: ', error);
        Swal.fire('Failed processing', '', 'error')
      })
      .finally(() => {
        setLoading(0);
        Swal.fire('The process is complete, please click download', '', 'success')
      });
  };

  // view
  const menuView = (params) => {
    const selectDevice = [
      { value: 'main', label: 'Habibi Climate' },
      { value: 'dose', label: 'Habibi Dose' },
      { value: 'station', label: 'Habibi Station' },
    ];

    const selectClimate = [
      { value: 'all', label: 'Semua Sensor' },
      { value: 'temp', label: 'Suhu' },
      { value: 'hum', label: 'Kelembaban' },
      { value: 'pressure', label: 'Tekanan Udara' },
      { value: 'soilt', label: 'Suhu Tanah' },
      { value: 'mois', label: 'Kadar Air' },
      { value: 'nutr', label: 'Nutrisi Tanah' },
      { value: 'light', label: 'Cahaya' },
      { value: 'N', label: 'N' },
      { value: 'P', label: 'P' },
      { value: 'K', label: 'K' },
      { value: 'ph', label: 'pH' },
    ];

    const selectStation = [
      { value: 'all', label: 'Semua Sensor' },
      { value: 'temperature', label: 'Suhu' },
      { value: 'humidity', label: 'Kelembaban' },
      { value: 'pressure', label: 'Tekanan Udara' },
      { value: 'voltBatt', label: 'Tegangan Baterai' },
      { value: 'currBatt', label: 'Arus Baterai' },
      { value: 'voltSolar', label: 'Tegangan Panel Surya' },
      { value: 'currSolar', label: 'Arus Panel Surya' },
      // { value: 'solar', label: 'Daya Panel Surya' },
      { value: 'uv', label: 'UV' },
      { value: 'light', label: 'Cahaya' },
      { value: 'windDirection', label: 'Arah Angin' },
      { value: 'windSpeedAverage', label: 'Kecepatan Angin' },
      { value: 'windSpeedMax', label: 'Hembusan Angin' },
      { value: 'rainFallOneHour', label: 'Tetesan Hujan/Jam' },
      { value: 'rainFallOneDay', label: 'Tetesan Hujan/Hari' },
    ];

    const selectDose = [
      { value: 'all', label: 'Semua Sensor' },
      { value: 'tds', label: 'TDS' },
      { value: 'ph', label: 'pH' },
      { value: 'temp', label: 'Suhu' },
      { value: 'vol_torent', label: 'Volume Torrent' },
      { value: 'pump_flow', label: 'Flow Air' },
      { value: 'fert_flow', label: 'Flow Pupuk' },
    ];

    let selectSensor = '';

    if (device === 'main') {
      selectSensor = selectClimate;
    } else if (device === 'station') {
      selectSensor = selectStation;
    } else {
      selectSensor = selectDose;
    }

    return (
      <div className="col-md-12">
        <Card>
          {/* device & sensor */}
          <div className="row mb-3">
            {/* pilih device */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Device
              </label>
              <Select
                options={selectDevice}
                placeholder="Pilih Device"
                onChange={(e) => setDevice(e.value)}
              />
            </div>

            {/* pilih sensor */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Sensor
              </label>
              <Select
                options={selectSensor}
                isDisabled={!device}
                placeholder="Pilih Sensor"
                onChange={(e) => setSensor(e.value)}
              />
            </div>
          </div>

          {/* datepicker & proses */}
          <div className="row">
            {/* From Date */}
          <div className="col-md-4">
            <label htmlFor="true" className="form-label">
              Pilih tanggal{" "}
              <small style={{ color: "#999" }}>
                <i>(mulai)</i>
              </small>{" "}
              <span style={{ color: "#ff0000" }}>*</span>
            </label>
            <DatePicker
              // disabled={farm == null ? true : false}
              dateFormat="dd/MM/yyyy"
              placeholderText="Mulai tanggal..."
              className="form-control datepicker"
              selected={startDate}
              onChange={(date) => onChangeStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
            />
          </div>

          {/* End Date */}
          <div className="col-md-4">
            <label htmlFor="true" className="form-label">
              <small style={{ color: "#999" }}>
                <i>(berakhir)</i>
              </small>{" "}
              <span style={{ color: "#ff0000" }}>*</span>
            </label>
            <DatePicker
              // disabled={farm == null ? true : false}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sampai tanggal..."
              className="form-control datepicker"
              selected={endDate}
              onChange={(date) => onChangeEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={endDay}
            />
          </div>

            {/* proses */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label text-end text-red">
                *Klik untuk memproses
              </label>
              <button type="button" className="w-100 btn btn-blue" onClick={processAnalytic}>
                Proses
              </button>
            </div>

            {/* Keterangan */}
            <span className="col-md-12 mt-3">
              <span style={{ color: '#ff0000' }}>*</span> Download data maksimal hanya 31 hari
            </span>
          </div>
        </Card>
      </div>
    );
  };

  const sensorView = (params) => (
    <div className="col-md-12">
      <Card
        title="Download"
        rightContent={
          <a
            aria-label="Cancel Button"
            href="#close"
            onClick={() => setLoading(2)}
            className="ms-auto card-title ms-auto cursor-pointer text-decoration-none"
          >
            <Icon className="text-danger" name="XCircle" size={20} />
          </a>
        }
      >
        {/* Button Download */}
        <div className="col-12 col-md-12 ms-auto">
          <CSVLink
            data={csvData}
            filename={`Data_(${moment(startDate).format('DD-MM-YYYY')})_(${moment(endDate).format(
              'DD-MM-YYYY'
            )}).csv`}
            className="btn btn-blue w-100"
          >
            <Icon name="Download" size={16} style={{ marginRight: 5 }} /> <b>Unduh Data (CSV)</b>
          </CSVLink>
        </div>

        {/* <ReactApexChart type="area" height={300} options={chartOptions} series={seriesX} />

        <table className="text-center mt-4 table table-responsive table-bordered">
          <thead>
            <tr>
              <th>Min</th>
              <th>Max</th>
              <th>Rata-Rata</th>
              <th>Modus</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Math.min(...dataY)}</td>
              <td>{Math.max(...dataY)}</td>
              <td>{mean(dataY)}</td>
              <td>{modus(dataY)}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </table> */}
      </Card>
    </div>
  );

  const handleView = () => (
    <div id="wrapper">
      <div className="row row-deck row-cards">
        {menuView()}

        {isLoading === 1 && <LoadingView />}
        {isLoading === 0 && sensorView()}
        {isLoading === 2 && null}
      </div>
    </div>
  );

  return (
    <>
      {/* content */}
      {handleView()}
    </>
  );
}

Sensor.propTypes = {
  farmCode: PropTypes.arrayOf.isRequired,
};

export default Sensor;
