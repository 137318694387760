import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

function DropdownItem({label, url}) {
  return (
    <>
      <Link className="dropdown-item" to={url}>
        {label}
      </Link>
    </>
  );
}

DropdownItem.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default DropdownItem;
