/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import useUserStore from "../../stores/Users";
import { newAPI, oldAPI } from "../../services/baseAPI";

import Card from "../../components/SimpleCard";
import Icon from "../../components/Icon";
import LoadingView from "../../components/Loading";

const FarmEdit = () => {
  const { farmId, reportId } = useParams();
  const history = useHistory();
  const token = useUserStore((state) => state.token);

  const [data, setData] = useState(null);
  // const [tanggal, setTanggal] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [pastImage, setPastImage] = useState(null);
  const [dataSelectTanaman, setDataSelectTanaman] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { register, handleSubmit, setValue } = useForm();

  const fetchLM = async () => {
    setIsLoading(true);
    await newAPI.get(`/v3/farms/${farmId}/weekly_report/${reportId}`,{
      headers: { 
        Authorization: `Bearer ${token}` 
      }})
      .then((res) => {
        setData(res);
        console.log(res);
        setDateRange(
          JSON.parse(res.report_date).map(
            (tanggal) => new Date(moment(tanggal, "DD-MM-YYYY"))
          )
        );
        setPastImage(res.images);
      })
      .catch((error) => {
        console.log(`error ${error}`);
      });
    const dataFetchPlant = await newAPI.get("/plants");
    // console.log(dataFetchPlant);
    setDataSelectTanaman(dataFetchPlant.data);

    setIsLoading(false);
  };

  // select tanaman start
  const SelectTanaman = (datatanaman) =>
    datatanaman.map((data) => (
      <option value={`${data.id},${data.plants_name}`}>
        {data.plants_name}
      </option>
    ));
  // select tanaman end

  const editLM = async (params) => {
    console.log("params:");
    console.log(params);

    const formData = new FormData();

    // masukkan tanggal satu per satu
    params.tanggalLaporan
      ? Object.values(params.tanggalLaporan).forEach((tanggal) => {
          formData.append("tanggalLaporan", tanggal);
        })
      : formData.append("tanggalLaporan", data.report_date);

    formData.append("reportId", reportId);
    formData.append("selectTanaman", params.selectTanaman);
    formData.append("sistemKebun", params.sistemKebun);
    formData.append("habibiClimate", params.habibiClimate);
    formData.append("faseTanaman", params.faseTanaman);
    formData.append("produksiKebun", params.produksiKebun);
    formData.append("hst", params.hst);
    formData.append("jumlahTanaman", params.jumlahTanaman);

    formData.append(
      "kendala",
      params.kendalaContent ? params.kendalaContent : data.problem
    );
    formData.append(
      "budidaya",
      params.budidayaContent ? params.budidayaContent : data.cultivate
    );
    formData.append(
      "kesimpulanContent",
      params.kesimpulanContent ? params.kesimpulanContent : data.conclusion
    );

    // masukkan gambar
    Object.values(params.gambarLaporan).forEach((pic) => {
      formData.append("gambarLaporan", pic);
    });

    await axios
      .post("http://localhost:3001/edit", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(alert("berhasil!"))
      .catch((err) => {
        console.log(err);
        alert("gagal!");
      });
  };

  useEffect(() => {
    console.log("useeffect jalan");
    register("kendalaContent");
    register("budidayaContent");
    register("kesimpulanContent");
    register("tanggalLaporan");
    // fetchPlant();
    fetchLM();
  }, []);

  const editView = () => (
    <>
      {console.log(moment().format("DD-MM-YYYY"))}
      {/* tombol back dan edit start */}
      <div className="container-fluid noprint ">
        <div className="page-header">
          <div className="row align-items-center">
            {/* tombol back start */}
            <button
              aria-label="Back Button"
              type="button"
              onClick={() => history.push(`/farm/${farmId}/detail`)}
              className="col-2 "
              style={{
                marginLeft: "8px",
                marginRight: "10px",
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: "rgba(255, 0, 0, 0)",
                borderWidth: 0,
              }}
            >
              <Icon name="ArrowLeft" color="#333" size={20} />
            </button>
            <div className="col-auto">
              <div className="page-pretitle">Kembali</div>
              <h2 className="page-title">Farm</h2>
            </div>
            {/* tombol back end */}

            {/* tombol preview start */}
            <div className="col-auto">
              <button
                className="btn btn-primary ms-3"
                type="button"
                onClick={() =>
                  history.push(`/farm/${farmId}/detail/${reportId}/preview`)
                }
              >
                Preview
              </button>
            </div>
            {/* tombol preview end */}
          </div>
        </div>
      </div>
      {/* tombol back dan edit end */}

      <Card title="Form Pertumbuhan">
        <form onSubmit={handleSubmit(editLM)}>
          <div className="row row-decks row-cards">
            {/* tanggal start */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Tanggal Laporan
              </label>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selectsRange
                // startDate={new Date(moment(tanggal[0], "DD-MM-YYYY"))}
                // endDate={new Date(moment(tanggal[1], "DD-MM-YYYY"))}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  // console.log(update);
                  setDateRange(update);
                  setValue(
                    "tanggalLaporan",
                    update.map((tanggal) =>
                      tanggal
                        ? moment(tanggal).format("DD-MM-YYYY")
                        : moment(update[0]).format("DD-MM-YYYY")
                    )
                  );
                }}
                // inline
                // isClearable
                required
              />
            </div>
            {/* tanggal end */}

            {/* select tanaman start */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Komoditas
              </label>
              <select
                ref={register()}
                name="selectTanaman"
                className="form-control"
                defaultValue={data.commodity}
              >
                {SelectTanaman(dataSelectTanaman)}
              </select>
            </div>
            {/* select tanaman end */}

            {/* Sistem start */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Sistem
              </label>
              <select
                ref={register()}
                name="sistemKebun"
                className="form-control"
                defaultValue={data.system}
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
            </div>
            {/* Sistem end */}

            {/* Habibi Climate start */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Habibi Climate
              </label>
              <select
                ref={register()}
                name="habibiClimate"
                className="form-control"
                defaultValue={data.habibi_climate}
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
            </div>
            {/* Habibi Climate end */}

            {/* Fase Tanaman start */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Fase Tanaman
              </label>
              <select
                ref={register()}
                name="faseTanaman"
                className="form-control"
                defaultValue={data.plant_phase}
              >
                <option value="generatif">Generatif</option>
                <option value="vegetatif">Vegetatif</option>
              </select>
            </div>
            {/* Fase Tanaman end */}

            {/* Produksi Kebun start */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Produksi Kebun
              </label>
              <select
                ref={register()}
                name="produksiKebun"
                className="form-control"
                defaultValue={data.production}
              >
                <option value="Buah">Buah</option>
                <option value="Sayur">Sayur</option>
              </select>
            </div>
            {/* Produksi Kebun end */}

            {/* HST start */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                HST
              </label>
              <input
                ref={register()}
                name="hst"
                className="form-control"
                type="number"
                min="0"
                max="1000"
                defaultValue={data.hst}
              />
            </div>
            {/* HST end */}

            {/* Jumlah Tanaman start */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Jumlah Tanaman
              </label>
              <input
                ref={register()}
                name="jumlahTanaman"
                className="form-control"
                type="number"
                min="0"
                max="1000000"
                defaultValue={data.plant_amount}
              />
            </div>
            {/* Jumlah Tanaman end */}

            {/*  Kendala start */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Kendala
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={data.problem === "<br/>" ? "" : data.problem}
                onChange={(event, editor) => {
                  const dataCK = editor.getData();
                  setValue("kendalaContent", dataCK);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "undo",
                    "redo",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                    ],
                  },
                }}
              />
            </div>
            {/* Kendala end */}

            {/*  Budidaya start */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Budidaya
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={data.cultivate === "<br/>" ? "" : data.cultivate}
                onChange={(event, editor) => {
                  const dataCK = editor.getData();
                  // setBudidaya(data);
                  setValue("budidayaContent", dataCK);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "undo",
                    "redo",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                    ],
                  },
                }}
              />
            </div>
            {/* Budidaya end */}

            {/*  Kesimpulan start */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Kesimpulan
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={data.conclusion === "<br/>" ? "" : data.conclusion}
                onChange={(event, editor) => {
                  const dataCK = editor.getData();
                  setValue("kesimpulanContent", dataCK);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "undo",
                    "redo",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                    ],
                  },
                }}
              />
            </div>
            {/* Kesimpulan end */}

            {/* input gambar start */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Gambar (Maks 5MB)
              </label>
              <input
                ref={register}
                name="gambarLaporan"
                accept=".png, .jpg, .jpeg"
                type="file"
                className="form-control"
                onChange={(e) => {
                  console.log(e);
                  const inputUser = e.target.files[0];
                  // cek apakah input user adalah gambar
                  if (!/^image/.test(inputUser.type)) {
                    alert(`Uploaded file is not an image!`);
                    e.target.value = null;
                    return;
                  }
                  // cek apakah size gambar lebih dari 5 mb
                  if (inputUser.size > 5242880) {
                    alert(`Image size is more than 5 MB !`);
                    e.target.value = null;
                    return;
                  }

                  setImagePreview(inputUser);
                  setPastImage(null);
                }}
              />
            </div>
            {/* input gambar end */}

            {/* preview image start */}
            <div className="col-md-12">
              {imagePreview ? (
                <>
                  <label className="form-label">
                    <strong>
                      {imagePreview.name},{" "}
                      {(imagePreview.size / 1024).toFixed(2)} KB
                    </strong>
                  </label>
                  <img
                    className="img-thumbnail rounded mx-auto d-block"
                    src={URL.createObjectURL(imagePreview)}
                    alt="Preview Gambar"
                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                  />
                  <br />
                </>
              ) : null}
            </div>
            {/* preview image end */}

            {/* past image start */}
            <div className="col-md-12">
              {pastImage ? (
                <>
                  <label className="form-label">
                    <strong>{pastImage}</strong>
                  </label>
                  <img
                    className="img-thumbnail rounded mx-auto d-block"
                    src={`http://localhost:3001/images/${pastImage}`}
                    alt="Preview Gambar"
                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                  />
                  <br />
                </>
              ) : null}
            </div>
            {/* past image end */}

            {/* tombol submit start */}
            <div className="col">
              <button className="btn btn-primary " type="submit">
                SUBMIT
              </button>
            </div>
            {/* tombol submit end */}
          </div>
        </form>
      </Card>
    </>
  );
  return <>{isLoading ? <LoadingView /> : editView()}</>;
};

export default FarmEdit;
