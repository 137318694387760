import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  Polygon,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';

import Swal from 'sweetalert2';
import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';
import Card from '../../components/SimpleCard';
import { newAPI } from '../../services/baseAPI';
import Icon from '../../components/Icon';

function FarmCreate() {
  const token = useUserStore((state) => state.token);
  const history = useHistory();
  const {register, handleSubmit, onBlur, errors, setValue, setError, clearErrors} = useForm();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD-FqHubInKsX5Culh_eyNN0NRQbB9ePFs',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [device, setDevice] = useState({ 
    grow: null, 
    dose: null, 
    hws: null, 
    hws_climate: null, 
    cam: {
      cam_status: null,
      cam_master: null
    }, 
    link: {
      link_status: null,
      link_type: null
    }, 
    bardi: {
      bardi_status: null,
      bardi_master: null
    } 
  });
  // const [latLng, setLatLng] = useState(lat:-6.9353636194650825, lng: 107.67278017261582 });
  const [lati, setLati] = useState(-6.9353636194650825);
  const [long, setLong] = useState(107.67278017261582);
  const [users, setUsers] = useState({ owner: [], farmer: [] });
  const [internet, setInternet] = useState({ no_hp: null, internet: null })


  const fetchUsers = async () => {
    setIsLoading(true);
    await axios
      .all([newAPI.get('v3/users/owner'), newAPI.get('v3/users/farmer')], {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const owner = res[0].data;
        const farmer = res[1].data;
        const arrayOwner = [];
        const arrayFarmer = [];
        owner.forEach((e) => {
          arrayOwner.push({ value: e.id, label: `${e.first_name} ${e.last_name}` });
        });
        farmer.forEach((e) => {
          arrayFarmer.push({ value: e.id, label: `${e.first_name} ${e.last_name}` });
        });
        setUsers({ owner: arrayOwner, farmer: arrayFarmer });
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    register('owner');
    register('farmer');
    register('water_sources');
    register('internet');
    register('status');
    register('method_purchasing');
    register('field_type');
    fetchUsers();
  }, []);

  const storeFarm = (params) => {
    setIsLoading(true);

    // console.log(JSON.stringify(device));
    const nohp = []
    if (params.modem !== undefined) {
      nohp.push(params.modem)
    } else {
      nohp.push(null)
    }

    if (params.status !== undefined && params.internet !== undefined && params.method_purchasing !== undefined && params.owner !== undefined && params.field_type !== undefined && params.water_sources !== undefined) {    
      const body = new FormData();
      body.append('name', params.name);
      body.append('device', JSON.stringify(device));
      // body.append('grow_zone', 4);
      body.append('status', params.status);
      body.append('hws_sn', params.hws_sn);
      body.append('code_farm', (params.code_farm).toUpperCase());
      body.append('farmer', params.farmer);
      body.append('owner', params.owner);
      body.append('latitude', params.latitude);
      body.append('longitude', params.longitude);
      body.append('method_purchasing', params.method_purchasing);
      body.append('modem', nohp[0]);
      body.append('farm_type', 'Openfield');
      body.append('farm_method', 'Tanah');
      body.append('dosis_pump', 13);
      body.append('water_tank', 13);
      body.append('city', params.city);
      body.append('internet', params.internet);
      body.append('field_type', params.field_type);
      body.append('field_height', params.field_height);
      body.append('field_slope', params.field_slope);
      body.append('total_farmer', params.total_farmer);
      body.append('water_sources', params.water_sources);
      body.append('power', params.power);
      body.append('image', image);
      
      newAPI
        .post('/v3/farms', body, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log(`berhasil upload ${JSON.stringify(res)}`);
          Swal.fire('Successfully added!', res.data.message, 'success');
        })
        .catch((error) => {
          console.error(`error ${error}`);
          Swal.fire('Changes are not saved!', error.response.data.message, 'error')
        })
        .finally(() => {
          setIsLoading(false)
        });
    } else {
      Swal.fire('Tidak bisa menambah zona', 'masih terdapat data kosong', 'error')
      setIsLoading(false);
    }    
  
  };

  const fetchFarmIsAvailable = async farmCode => {
    setIsLoading(false);
    await newAPI
      .get(`/v3/farms/exist/${farmCode}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(() => {
        clearErrors('farm_code_used');
      })
      .catch(error => {
        setError('farm_code_used', error);
        console.error(`error ${error}`);
      })
      .finally(() => setIsLoading(false));
  };
  
  const handleCheckFarmCode = async (event) => {
    const farmCode = event.target.value;
    await fetchFarmIsAvailable(farmCode);
  }

  // USER INTERFACE

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farm')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Tambah</div>
            <h2 className="page-title">Kebun</h2>
          </div>
        </div>
      </div>
    </>
  );
  
  const createView = () => (
    <>
      <form onSubmit={handleSubmit(storeFarm)}>
        <div className="row row-decks row-cards">
          <Card title="Tambah Data Kebun">
            <div className="row row-decks row-cards">
              {/* nama kebun */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Kebun
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama kebun"
                />
                {errors.name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama kebun wajib diisi
                  </div>
                )}
              </div>

              {/* kode kebun */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kode Kebun
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="code_farm"
                  type="text"
                  className="form-control text-uppercase"
                  placeholder="Masukan kode kebun"
                  onBlur={handleCheckFarmCode}
                />
                {errors.farm_code_used && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kode kebun sudah digunakan
                  </div>
                )}
                {errors.code_farm && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    kode kebun wajib diisi
                  </div>
                )}
              </div>

              {/* Kota */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kota
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kota"
                />
                {errors.city && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kota wajib diisi
                  </div>
                )}
              </div>

              {/* Status */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Status
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={[{ value: 1, label: 'Aktif' },{ value: 0, label: 'Tidak aktif' }]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('status', e.value);
                  }}
                />
                {errors.status && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Status wajib diisi
                  </div>
                )}
              </div>

              {/* Internet */}
              <div className={ internet.internet === "wifi" || internet.internet === null ? "col-md-6" : "col-md-3" }>
                <label htmlFor="true" className="form-label">
                  Internet
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={[{ value: "wifi", label: 'Wifi' },{ value: "hbblink", label: 'Habibi Link' }]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('internet', e.value);
                    setInternet({ ...internet, internet: e.value });
                  }}
                />
                {errors.internet && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Internet wajib diisi
                  </div>
                )}
              </div>
              
              {/* Nomer HP */}
              {internet.internet === "hbblink" ? 
                <div className="col-md-3">
                  <label htmlFor="true" className="form-label">
                    No. Kuota Internet
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    ref={register({ required: true })}
                    name="modem"
                    type="text"
                    className="form-control"
                    placeholder="Masukan No. Kuota Internet"
                    disabled={internet.internet && false}
                  />
                  {errors.modem && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      No. Kuota Internet wajib diisi
                    </div>
                  )}
                </div>
              : <></>}

              {/* Metode Pembelian */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Metode Pembelian
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={[{ value: "beli", label: 'Beli' },{ value: "sewa", label: 'Sewa' },{ value: "yarnen", label: 'Bayar Panen' }]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('method_purchasing', e.value);
                  }}
                />
                {errors.method_purchasing && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Metode wajib diisi
                  </div>
                )}
              </div>

              {/* Pemilik */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Pemilik
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={users.owner}
                  closeMenuOnSelect
                  onChange={(e) => {
                    setValue('owner', e.value);
                  }}
                />
                {errors.owner && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Owner wajib diisi
                  </div>
                )}
              </div>

              {/* Petani */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Petani
                </label>
                <Select
                  options={[{ value: 0, label: 'Tidak Ada' }, ...users.farmer]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('farmer', e.value);
                  }}
                />
                {errors.farmer && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Petani wajib diisi
                  </div>
                )}
              </div>

              {/* Field type */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jenis Lahan
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={[{ value: "indoor", label: 'Indoor' },{ value: "outdoor", label: 'Outdoor' }]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('field_type', e.value);
                  }}
                />
                {errors.field_type && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Jenis lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Total farmer */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Jumlah Anggota Kelompok Tani
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="total_farmer"
                  type="number"
                  className="form-control"
                  placeholder="Masukan jumlah anggota kelompok tani"
                />
                {errors.total_farmer && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Jumlah anggota kelompok tani wajib diisi
                  </div>
                )}
              </div>

              {/* Field height */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Ketinggian lahan
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="field_height"
                  type="number"
                  className="form-control"
                  placeholder="Masukan ketinggian lahan"
                />
                {errors.field_height && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Ketinggian lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Field slope */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kemiringan lahan
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="field_slope"
                  type="number"
                  className="form-control"
                  placeholder="Masukan kemiringan lahan"
                />
                {errors.field_slope && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kemiringan lahan wajib diisi
                  </div>
                )}
              </div>

              {/* Power */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Daya Listrik
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="power"
                  type="number"
                  className="form-control"
                  placeholder="Masukan daya listrik"
                />
                {errors.power && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Daya Listrik wajib diisi
                  </div>
                )}
              </div>

              {/* Water sources */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Sumber Air
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={[
                    { value: "sumur", label: 'Sumur' },
                    { value: "pdam", label: 'PDAM' },
                    { value: "sungai", label: 'Sungai' },
                    { value: "bak", label: 'Bak Penampungan' }
                  ]}
                  onChange={(e) => {
                    // console.log(e.value);
                    setValue('water_sources', e.value);
                  }}
                />
                {errors.water_sources && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Sumber air wajib diisi
                  </div>
                )}
              </div>

              {/* Latitude */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Latitude
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="latitude"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Latitude"
                  onChange={item => {
                    setLati(parseFloat(item.target.value))
                    // console.log(item.target.value)
                  }}
                  value={lati}
                />
                {errors.latitude && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Latitude wajib diisi
                  </div>
                )}
              </div>

              {/* Longitude */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Longitude
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="longitude"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Longitude"
                  onChange={item => {
                    setLong(parseFloat(item.target.value))
                    // console.log(item.target.value)
                  }}
                  value={long}
                />
                {errors.longitude && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Longitude wajib diisi
                  </div>
                )}
              </div>
              <br />
              {isLoaded && (
                <div className="col-md-12">
                  <GoogleMap
                    mapContainerStyle={{
                      width: '100%',
                      height: '400px',
                    }}
                    center={{
                      lat: lati,
                      lng: long,
                    }}
                    zoom={15}
                    mapTypeId="satellite"
                    mapContainerClassName="card-body d-inline-block rounded mobile-100"
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <>
                      <Marker
                        position={{ lat: lati, lng: long }}
                        onDragEnd={(e) => {
                          // setLatlng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                          // console.log(e.latLng.lat());
                          setLati(e.latLng.lat());
                          setLong(e.latLng.lng());
                        }}
                        draggable
                      />
                    </>
                  </GoogleMap>
                </div>
              )}

              {/* gambar */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gambar
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  ref={register({ required: true })}
                  name="image"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gambar artikel wajib diisi
                  </div>
                )}
              </div>

              {/* preview & input gambar */}
              <div className="col-md-12">
                {imagePreview && (
                  <img
                    className="col-md-12 mx-auto d-block text-center"
                    alt="pic preview"
                    src={imagePreview}
                    style={{ maxWidth: '300px', maxHeight: '300px' }}
                  />
                )}
              </div>
            </div>
          </Card>
          <Card title="Tambah Device Kebun">
            <div className="row row-decks row-cards">
              {/* Grow */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="grow"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, grow: e.target.checked ? true : null });
                    }}
                  />
                  <span className="form-check-label">Tambah Habibigrow</span>
                </label>
                {errors.grow && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Grow wajib diisi
                  </div>
                )}
              </div>

              {/* Dose */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="dose"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, dose: e.target.checked ? true : null });
                    }}
                  />
                  <span className="form-check-label">Tambah Habibidose</span>
                </label>

                {errors.dose && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Dose wajib diisi
                  </div>
                )}
              </div>

              {/* hws */}
              <div className={device.hws === null || device.hws === false ? "col-md-12" : "col-md-6"}>
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="hws"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, hws: e.target.checked ? true : null });
                    }}
                  />
                  <span className="form-check-label">Tambah Habibihws</span>
                </label>
                {errors.hws && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    HWS wajib diisi
                  </div>
                )}
              </div>

              {/* Grow Setting */}
              {/* {device.grow && (
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Zona Kebun (min 0 zona & max 4 zona)
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    ref={register({ required: true })}
                    name="grow_zone"
                    type="number"
                    min="0"
                    max="4"
                    className="form-control"
                    placeholder="Masukan jumlah zona"
                  />
                  {errors.grow_zone && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Jumlah zona wajib diisi
                    </div>
                  )}
                </div>
              )} */}

              {/* HWS Setting */}
              {device.hws && (
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Serial Number HWS
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    ref={register({ required: true })}
                    name="hws_sn"
                    type="text"
                    className="form-control"
                    placeholder="Masukan Serial number"
                  />
                  {errors.hws_sn && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Serial number wajib diisi
                    </div>
                  )}
                </div>
              )}

              {/* HWS + Climate */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="hws_climate"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, hws_climate: e.target.checked ? true : null });
                    }}
                  />
                  <span className="form-check-label">Tambah Habibihws + Climate</span>
                </label>

                {errors.hws_climate && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Habibihws + Climate wajib diisi
                  </div>
                )}
              </div>

              {/* Habibi cam */}
              <div className={device.cam.cam_status === null || device.cam.cam_status === false ? "col-md-12" : "col-md-6"}>
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="cam"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, cam: {...device.cam, cam_status: e.target.checked ? true : null }});
                    }}
                  />
                  <span className="form-check-label">Tambah HabibiCam</span>
                </label>
              </div>

              {/* Hbb Cam Setting */}
              {device.cam.cam_status && (
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Master HabibiCam
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    ref={register({ required: true })}
                    name="cam_master"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setDevice({ ...device, cam: {...device.cam, cam_master: e.target.value} });
                    }}
                    placeholder="Masukan nama"
                  />
                  {errors.cam_master && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Nama wajib diisi
                    </div>
                  )}
                </div>
              )}

              {/* Feature hbb */}
              <div className={device.link.link_status === null || device.link.link_status === false ? "col-md-12" : "col-md-6"}>
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={internet.internet === "hbblink"? register({ required: true }) : register()}
                    name="link"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, link: {...device.link, link_status: e.target.checked ? true : null }});
                    }}
                  />
                  <span className="form-check-label">Tambah HabibiLink</span>
                </label>
                {errors.link && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Habibi link wajib dipilih
                    </div>
                  )}
              </div>

              {/* Feature hbb Setting */}
              {device.link.link_status && (
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Pilih Link
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    isMulti
                    options={[
                      { value: "dashboard", label: 'Dashboard' },
                      { value: "apk", label: 'Aplikasi' },
                    ]}
                    onChange={(e) => {
                      const feature = []
                      for (let i = 0; i < e.length; i+=1) {
                        feature.push(e[i].value)
                      }
                      setValue('link_type', feature);
                      setDevice({ ...device, link: {...device.link, link_type: feature} });
                    }}
                    />
                  {errors.link_type && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Tipe link wajib dipilih
                    </div>
                  )}
                </div>
              )}

              {/* Bardi */}
              <div className={device.bardi.bardi_status === null || device.bardi.bardi_status === false ? "col-md-12" : "col-md-6"}>
                <label htmlFor="true" className="form-check form-switch">
                  <input
                    ref={register()}
                    name="bardi"
                    type="checkbox"
                    className="form-check-input"
                    defaultValue="0"
                    onChange={(e) => {
                      setDevice({ ...device, bardi: {...device.bardi, bardi_status: e.target.checked ? true : null }});
                    }}
                  />
                  <span className="form-check-label">Tambah Bardi</span>
                </label>
              </div>

              {/* Bardi Setting */}
              {device.bardi.bardi_status && (
                <div className="col-md-6">
                  <label htmlFor="true" className="form-label">
                    Master
                    <span style={{color: 'red'}}>*</span>
                  </label>
                  <input
                    ref={register({ required: true })}
                    name="bardi_master"
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setDevice({ ...device, bardi: {...device.bardi, bardi_master: e.target.value} });
                    }}
                    placeholder="Masukan nama"
                  />
                  {errors.bardi_master && (
                    <div className="pt-1" style={{ color: 'red' }}>
                      Nama wajib diisi
                    </div>
                  )}
                </div>
              )}

              <div className="col-md-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Tambah Kebun"
                  disabled={errors.farm_code_used}
                />
                <div className="pt-1" style={{color: 'red'}}>
                  wajib mengisi input yang bertanda *
                </div>
              </div>
            </div>
          </Card>
        </div>
      </form>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          createView()
        )}
      </div>
    </div>
  );
}

export default FarmCreate;
