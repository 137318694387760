import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, Route } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Swal from 'sweetalert2';

import moment from 'moment';
import axios from 'axios';
import { newAPI } from '../../../services/baseAPI';
import Icon from '../../../components/Icon';
import useUserStore from '../../../stores/Users';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';

function Npk() {
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const AGRONOMIST = 6;
  const ENGINEER = 7;
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const { register, errors, handleSubmit, setValue } = useForm();
  // const [isLoading, setIsLoading] = useState();

  const [listFarm, setListFarm] = useState();
  const [farmCode, setFarmCode] = useState();
  const [farmData, setFarmData] = useState();
  const [mode, setMode] = useState();
  const [point, setPoint] = useState();
  const [sampel, setSampel] = useState();
  const [countPoint, setCountPoint] = useState(0);
  const [countSample, setCountSample] = useState(0);
  const [npkData, setNPKData] = useState();


  const fetchDataFarm = async () => {

    const tokenAuth = `Bearer ${token}`;
    await newAPI
    .get(`/v3/farms`, { headers: { Authorization: tokenAuth } })
    .then((res) => {

      const allData = []
      if (users.status === 1) {
        allData.push(res.data.data.last_data_device)
      } else {
        allData.push(res.data.last_data_device)
      }

      const allFarm = []
      const dataFarm = []
      if (users.status === 1) {
        allData[0].forEach(item => {
          if (item.jenis_data === "config") {
            dataFarm.push({
              value: item.code_farm,
              label: item.name
            })
          }
        });       
      } else {
        allData[0].forEach(item => {
          if (item.code_farm !== "") {
            dataFarm.push({
              value: item.code_farm,
              label: item.name
            })
          }
        });
      }

      allFarm.push({ value: "all", label: "Semua Kebun" }, ...dataFarm)
      setListFarm(allFarm);
    })
    .catch((error) => {
      console.log(`error ${error}`);
    });
  }

  const storeForm = async () => {
    
  }

  const handleInputChange = (e, ipoint, isample) => {
    const { name, value } = e.target;

    const list = [...npkData];
    if (name === "lat" || name === "lng") {
      list[ipoint][name] = value
      console.log(list);
    } else {
      list[ipoint].sampleNPK[isample][name] = value
      console.log(list);
      // console.log(countSample);
      // console.log(countPoint);
    }
    setNPKData(list)
  };

  const handleRemoveClick = index => {
    console.log(index);
    console.log(npkData[countPoint][index]);
    const listSample = [...npkData[countPoint]];
    const list = [...npkData];
    if (list.length > 1) {
      list.splice(countPoint, 1)
      listSample.splice(index, 1)
    }
    console.log(list);
    console.log(npkData);
    // setNPKData(list)
  };

  const handleEditClick = index => {
  
  };
  
  const storeFarmData = (index) => {
    console.log(farmCode);
    console.log(parseInt(index.sample,10));

    const titik = parseInt(index.area, 10)/400
    const dataSample = []
    for (let i = 0; i < Math.ceil(titik); i+=1) {
      const sample = []
      for (let n = 0; n < parseInt(index.sample,10); n+=1) {
        sample.push({nilai_n: "", nilai_p: "", nilai_k: ""})
        // console.log(sample);      
      }
      dataSample.push({
        date: `${moment().format('DD/MM/YY')}`,
        lng: "",
        lat: "",
        plant: index.tanaman,
        sampleNPK: sample
      })
    }
    console.log(dataSample);

    console.log(Math.ceil(titik));
    setNPKData(dataSample);
    setPoint(Math.ceil(titik));
    setSampel(parseInt(index.sample,10))
    setFarmData(index);
    setCountPoint(0)
    setCountSample(0)
  }

  const storeNPKData = async (index) => {
    // setIsLoading(true)

    const data = []
    data.push({
      farmid: farmCode.value,
      device: "rsc",
      area: index.area,
      data: npkData
    })
    console.log(JSON.stringify(data[0]));
    const body = JSON.stringify(data[0])
    // body.append('data_npk', JSON.stringify(data[0]));
    // Swal.fire('Saved!', `Data NPK kebun ${farmCode.label} berhasil disimpan`, 'success')

    await axios
      .post(
        "https://b5vell1qqi.execute-api.ap-southeast-1.amazonaws.com/Prod/rsc",
        body,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => {
        // setIsLoading(false);
        Swal.fire('Saved!', `Data NPK kebun ${farmCode.label} berhasil disimpan`, 'success')
        setFarmData()
        setCountPoint(0)
        setCountSample(0)
      })

  }

  const storeTempNPK = (param) => {
    console.log(npkData);

    if (mode === 'edit') {
      setMode('submit')
      Swal.fire('Updated!', `Sample ${countSample+1} Titik ${countPoint+1} berhasil di-update`, 'success')
      setCountSample(sampel-1)
    } else {
      setMode()
    }

    if (countSample < sampel-1 && npkData[countPoint].sampleNPK[sampel-1].nilai_k === "") {
      setCountSample(countSample+1);     
    }
  }

  useEffect(() => {
    fetchDataFarm()
  }, []);

  const headerView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Form Sample NPK</h2>
          </div>
        </div>
      </div>
    </>
  )

  const formFarm = () => (
    <>
      <form onSubmit={handleSubmit(storeFarmData)}>
        <Card>
          <div className="row row-decks row-cards">
            {/* kode kebun */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Kebun
                <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                options={listFarm}
                placeholder="Pilih kebun"
                name="code_farm"
                onChange={item => {
                  setFarmCode(item);
                }}
                closeMenuOnSelect={false}
              />
              {errors.code_farm && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Kebun wajib diisi
                </div>
              )}
            </div>

            {/* Luas lahan */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Luas Lahan (m²)
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                ref={register({ required: true })}
                name="area"
                type="number"
                className="form-control"
                placeholder="Masukan luas lahan"
              />
              {errors.area && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Luas zona wajib diisi
                </div>
              )}
            </div>

            {/* Tanaman */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Tanaman
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                ref={register({ required: true })}
                name="tanaman"
                type="text"
                className="form-control"
                placeholder="Masukan nama tanaman"
              />
              {errors.tanaman && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Nama tanaman wajib diisi
                </div>
              )}
            </div>

            {/* Banyak Sampel */}
            <div className="col-md-3">
              <label htmlFor="true" className="form-label">
                Sampel di Setiap Titik
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                ref={register({ required: true })}
                name="sample"
                type="number"
                className="form-control"
                placeholder="Masukan banyak sampel di setiap titik"
              />
              {errors.sample && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Banyak sampel wajib diisi
                </div>
              )}
            </div>

            <div className="col-md-12">
              <input className="btn w-100 btn-info px-5" type="submit" value="Save & Proses" name="data_farm" />
            </div>
          </div>
        </Card>
      </form>

    </>
  )

  const tableNPK = () => (
    <>
    <div style={{marginTop:"30px"}}>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th style={{textAlign:"center"}}>No</th>
            <th className="text-nowrap" style={{textAlign:"center"}}>nilai N</th>
            <th className="text-nowrap" style={{textAlign:"center"}}>nilai P</th>
            <th className="text-nowrap" style={{textAlign:"center"}}>nilai K</th>
            <th className="text-nowrap" style={{textAlign:"center"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {npkData[countPoint].sampleNPK.map((e, i) => (
            <>
            <tr>
              <th style={{textAlign:"center"}}>{i + 1}</th>
              <td style={{textAlign:"center"}}>{i !== countSample || countSample === sampel-1 ? e.nilai_n : ""}</td>
              <td style={{textAlign:"center"}}>{i !== countSample || countSample === sampel-1 ? e.nilai_p : ""}</td>
              <td style={{textAlign:"center"}}>{i !== countSample || countSample === sampel-1 ? e.nilai_k : ""}</td>
              <td style={{textAlign:"center"}}>
                <a label="#" href="#edit" onClick={() => {
                  setCountSample(i);
                  if (e.nilai_k !== "") {
                    setMode('edit')
                  } else {
                    setMode()
                  }
                }} className="ms-auto cursor-pointer text-decoration-none"><Icon className={i === countSample ? 'text-danger' : 'text-success'} name="Edit" size={20} /></a>
              </td>
            </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )

  const formNPK = () => (
      <>
        <div>
          <h4><span className="badge bg-primary col-md">point {countPoint + 1}</span></h4>
        </div>

        {/* latitude */}
        <div className="col-md-6" style={{marginTop: "0px"}}>
          <label htmlFor="true" className="form-label">
            Latitude
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            // ref={register({ required: true })}
            value={npkData[countPoint].lat}
            onChange={item => handleInputChange(item, countPoint, countSample)}
            name="lat"
            type="number"
            className="form-control"
            placeholder="Masukan latitude"
          />
          {errors.lat && (
            <div className="pt-1" style={{ color: 'red' }}>
              Latitude wajib diisi
            </div>
          )}
        </div>

        {/* longitude */}
        <div className="col-md-6" style={{marginTop: "0px"}}>
          <label htmlFor="true" className="form-label">
            Longitude
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            // ref={register({ required: true })}
            value={npkData[countPoint].lng}
            name="lng"
            type="number"
            onChange={item => handleInputChange(item, countPoint, countSample)}
            className="form-control"
            placeholder="Masukan longitude"
          />
          {errors.lng && (
            <div className="pt-1" style={{ color: 'red' }}>
              Latitude wajib diisi
            </div>
          )}
        </div>

        {countPoint > 0 ?
          <>
          <div className="col-md-6">
            <button className="btn w-100 btn-info px-5" type="button" onClick={() => {
              setCountPoint(countPoint-1);
              setCountSample(sampel-1)
              }}
            >
              Back
            </button>
          </div>
          </>
          : <></>
        }

        {countPoint !== (point - 1) && npkData[countPoint].sampleNPK[sampel-1].nilai_k !== "" ? 
          <>
          <div className="col-md-6">
            <button className="btn w-100 btn-yellow px-5" type="button" onClick={() => {
              setCountSample(0);
              setCountPoint(countPoint+1);
              }}
            >
              Next
            </button>
          </div>
          </>
          : <></>
        }

        {countPoint === (point-1) && npkData[countPoint].sampleNPK[sampel-1].nilai_k !== "" ?
          <>
          <div className="col-md-6">
            <input className="btn w-100 btn-danger px-5" type="submit" value="Save" name="nilai_npk" />
          </div>
          </>
          : <></>
        }

        <div> 
          <hr style={{marginTop: 10, marginBottom: 10}} />
        </div>

        {npkData[countPoint].lng !== "" ?
        <>
        <div style={{marginTop:"7px"}}>
          <h4><span className="badge bg-cyan col-md">sample {countSample + 1}</span></h4>
        </div>
        {/* nilai N */}
        <div className="col-md-3" style={{marginTop:"7px"}}>
          <label htmlFor="true" className="form-label">
            Nilai N
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            // ref={register({ required: true })}
            value={npkData[countPoint].sampleNPK[countSample].nilai_n}
            onChange={item => handleInputChange(item, countPoint, countSample)}
            name="nilai_n"
            type="number"
            className="form-control"
            placeholder="Masukan nilai N"
          />
          {errors.nilai_n && (
            <div className="pt-1" style={{ color: 'red' }}>
              Nilai N wajib diisi
            </div>
          )}
        </div>
    
        {/* nilai P */}
        <div className="col-md-3" style={{marginTop:"7px"}}>
          <label htmlFor="true" className="form-label">
            Nilai P
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            value={npkData[countPoint].sampleNPK[countSample].nilai_p}
            onChange={item => handleInputChange(item, countPoint, countSample)}
            name="nilai_p"
            type="number"
            className="form-control"
            placeholder="Masukan nilai P"
          />
          {errors.nilai_p && (
            <div className="pt-1" style={{ color: 'red' }}>
              Nilai P wajib diisi
            </div>
          )}
        </div> 
    
        {/* nilai K */}
        <div className="col-md-3" style={{marginTop:"7px"}}>
          <label htmlFor="true" className="form-label">
            Nilai K
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            value={npkData[countPoint].sampleNPK[countSample].nilai_k}
            onChange={item => handleInputChange(item, countPoint, countSample)}
            name="nilai_k"
            type="number"
            className="form-control"
            placeholder="Masukan nilai K"
          />
          {errors.nilai_k && (
            <div className="pt-1" style={{ color: 'red' }}>
              Nilai K wajib diisi
            </div>
          )}
        </div>
        
        <div className="col-md-3" style={{marginTop:"35px"}}>
          <button className="btn w-100 btn-success px-5" type="button" onClick={() => storeTempNPK()}
          >
            Submit
          </button>
        </div>
        </>
        : <></>}

        </>
  )

  const tabForm = () => (
    <>
      <div className="col-md-12">
        {formFarm()}
        
        <form onSubmit={handleSubmit(storeNPKData)}>
          <Card style={{marginTop:"10px"}}>
            <div className="row row-decks row-cards">
              {farmData !== undefined ? formNPK() : <></>}
              {farmData !== undefined ? tableNPK() : <></>}
            </div>
          </Card>
        </form>
      </div>
    </>
  )
  return (
    <div className="container-xl">
      {/* header */}
      {users.status === ADMIN || users.status === AGRONOMIST ? null : headerView()}
      
      {/* content */}
      <div>
      {tabForm()}
      </div>

    </div>
  )

}

export default Npk;