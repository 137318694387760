import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer footer-transparent noprint">
      <div className="container">
        <div className="row text-center align-items-center flex-row-reverse">
          <div className="col-lg-auto ml-lg-auto">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                <a href="./docs/index.html" className="link-secondary">
                  Documentation
                </a>
              </li>
              <li className="list-inline-item">
                <a href="./faq.html" className="link-secondary">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            Copyright © 2020
            <a href="https://habibigarden.com" className="link-secondary">
              {" "}
              Habibi Garden
            </a>
            . All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
