import React, { useState, useEffect, useCallback } from 'react';
import PropTypes, { array } from 'prop-types';
import Icon from '../../../components/Icon';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';

function StockIn() {

  const [action, setAction] = useState();

  const menuStyle = {
    // margin: "1rem",
    textAlign: "center",
    borderRadius: 10,
    height: 30,
    width: 30,
    color: 'white',
    backgroundColor: "#d63384"
  };

  const reportView = (params) => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards">
          {/* Title */}
          <div className="col-md-12">
            <div className="card-body py-0 border-bottom">
              <h3 style={{ marginBottom: 13, marginTop: 13 }}>Laporan Kebun</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
  const badgeTable = () => {
    let option = '---';
    if (action === "detail") option = 'Detail';
    if (action === "delete") option = 'Delete';
    console.log(action);

    return (
      <>
        <div className="dropdown">
          <button className="btn" type="button" style={menuStyle} id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
            <b style={{marginBottom: 6}}>. . .</b>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li><button className="dropdown-item" type="button" onClick={() => setAction('detail')}>Detail</button></li>
            <li><button className="dropdown-item" type="button">Delete</button></li>
            <li><button className="dropdown-item" type="button">Something else here</button></li>
          </ul>
        </div>
      </>
    )
  }

  const stockinTable = () => (
    <>
      <div className="table-responsive">
        <table
          className="table table-vcenter" style={{marginTop:20}}>
          <thead>
            <tr>
              <th style={{width: 350}}>ID Stok Masuk</th>
              <th>Oleh</th>
              <th>Tanggal</th>
              <th className="w-1">Ket</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Paweł Kuna</td>
              <td className="text-muted" >
                UI Designer, Training
              </td>
              <td className="text-muted" >tes</td>
              <td>
                {badgeTable()}
              </td>
            </tr>
            <tr>
              <td>Paweł Kuna</td>
              <td className="text-muted" >
                UI Designer, Training
              </td>
              <td className="text-muted" >tes</td>
              <td>
                {badgeTable()}
              </td>
            </tr>
            <tr>
              <td>Paweł Kuna</td>
              <td className="text-muted" >
                UI Designer, Training
              </td>
              <td className="text-muted" >tes</td>
              <td>
                {badgeTable()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    <>
    <div className="row row-deck row-cards">
      <Card>
        <div className="row">
          <div className="col-md-3">
            <div className="d-flex align-items-center">
              <div>Show</div>
                <div className="ms-auto">
                  <select
                    name="dropdownFilter"
                    className="form-select form-control"
                    style={{width:170}}
                    // onChange={(e) => {
                    //   setMenuReport(e.target.value);
                    //   fetchDataFarm();
                    // }}
                  >
                    <option value="a">10</option>
                    <option value="b">50</option>
                    <option value="c">100</option>
                  </select>
                </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="d-flex align-items-center">
              <div className="ms-auto">
                <form className="d-flex">
                  <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-blue" type="submit">Search</button>
                </form>
              </div>
            </div>
          </div>

          {/* Table Stock In */}
          {stockinTable()}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a className="page-link" href="#stockin-table" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item"><a className="page-link" href="#test1">1</a></li>
              <li className="page-item"><a className="page-link" href="#test2">2</a></li>
              <li className="page-item"><a className="page-link" href="#test3">3</a></li>
              <li className="page-item">
                <a className="page-link" href="#next" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Card>
    </div>
    </>
  );
}

export default StockIn;
