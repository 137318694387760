import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import ReactExport from "react-export-excel";
import moment from "moment";
import { awsKelanaAPI, newAPI } from "../../services/baseAPI";
import Icon from "../../components/Icon";
import useUserStore from "../../stores/Users";
import Card from "../../components/SimpleCard";
import LoadingView from "../../components/Loading";
import { HabibiTable, SelectColumnFilter } from "../../components/HabibiTable";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

function KelanaData() {
  const history = useHistory();
  const token = useUserStore((state) => state.token);

  // State
  const [data, setData] = useState(null);
  const [plant, setPlant] = useState({});
  const [selectPlant, setSelectPlant] = useState({
    value: 0,
    label: `Pilih Tanaman`,
  });
  const [excelRSC, setExcelRSC] = useState([]);
  const [opsRSC, setOpsRSC] = useState([]);

  const [excelGrowth, setExcelGrowth] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [RSCEdit, SetRSCEdit] = useState([]);
  const [RSCDate, setRSCDate] = useState([]);
  // Request Data
  const getDataPlantsBatch = useCallback(
    (kelanaid) =>
      newAPI
        .get(`/kelana/${kelanaid}/plants`)
        .then((res) => res.data)
        .catch((error) => console.log(`error ${error}`)),
    []
  );

  const fetchFarm = useCallback(async () => {
    setLoading(true);
    await awsKelanaAPI
      .get(`/${id}`)
      .then(async (res) => {
        const dataFarm = res.data.Item;
        const plantData = dataFarm.plant_data;
        const plantKey = [...new Set(plantData.map((e) => e.plant_id))].join();
        const dataPlantsBatch = await getDataPlantsBatch(plantKey);
        const newDataPlant = plantData.map((e) => ({
          ...e,
          plantData: dataPlantsBatch.find((f) => f.id === e.plant_id),
        }));
        setData({ ...dataFarm, plant_data: newDataPlant });
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchFarm();
  }, []);

  // Controller
  const handlePlant = (keyPlant) => {
    setPlant(data.plant_data.find((e) => e.key_plant === keyPlant));
  };

  const processRSC = () => {
    const plantTemp = data.plant_data.find(
      (e) => e.key_plant === selectPlant.value
    );
    const arrayData = plantTemp.rsc_data.map((e) => {
      const measurementTemp = e.measurement.find(
        (f) =>
          moment(f.dateTime).format("DD/MM/YYYY") ===
          moment(opsRSC.value).format("DD/MM/YYYY")
      );
      return {
        location: e?.key_location,
        temp: measurementTemp?.temp,
        rh: measurementTemp?.rh,
        ph: measurementTemp?.ph,
        tds: measurementTemp?.tds,
        n: measurementTemp?.n,
        p: measurementTemp?.p,
        k: measurementTemp?.k,
      };
    });
    setExcelRSC(arrayData);
  };
  const processGrowth = () => {
    const plantTemp = data.plant_data.find(
      (e) => e.key_plant === selectPlant.value
    );

    const keyArray = plantTemp.growth_data.length
      ? Object.keys(plantTemp.growth_data[0].data)
      : [];
    if (keyArray.length) {
      const arrayData = plantTemp.growth_data.map((e) => {
        const objectArray = { Tanggal: e.date };
        keyArray.forEach((f) => {
          objectArray[f] = e.data[f];
        });
        return objectArray;
      });
      setExcelGrowth({ key: ["Tanggal", ...keyArray], data: arrayData });
    } else {
      setExcelGrowth([]);
    }
  };
  const editRSC = () => {
    const plantTemp = data.plant_data.find(
      (e) => e.key_plant === selectPlant.value
    );
    const arrayTemp = plantTemp.rsc_data.map((e) => {
      const measurementTemp = e.measurement.find(
        (f) =>
          moment(f.dateTime).format("DD/MM/YYYY") ===
          moment(RSCDate.value).format("DD/MM/YYYY")
      );
      return {
        location: e?.key_location,
        temp: measurementTemp?.temp,
        rh: measurementTemp?.rh,
        ph: measurementTemp?.ph,
        tds: measurementTemp?.tds,
        n: measurementTemp?.n,
        p: measurementTemp?.p,
        k: measurementTemp?.k,
      };
    });

    SetRSCEdit(arrayTemp);
    console.log(RSCEdit);
  };

  const deleteHandler = (e, a) => {
    console.log("tanggal", e.value, "sample", a);
  };
  const keyName = [
    {
      name: "Suhu (°C)",
      key: "temp",
    },
    {
      name: "Nitrogen (mg/kg)",
      key: "n",
    },
    {
      name: "Phospor (mg/kg)",
      key: "p",
    },
    {
      name: "pH",
      key: "ph",
    },
    {
      name: "rH(%)",
      key: "rh",
    },
    {
      name: "TDS (ppm)",
      key: "tds",
    },
    {
      name: "Kalium (mg/kg)",
      key: "k",
    },
  ];
  // View
  const HeaderView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push("/kelana")}
            className="col-md-2"
            style={{
              marginLeft: "8px",
              marginRight: "10px",
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: "rgba(255, 0, 0, 0)",
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Kelana Data Kebun</h2>
          </div>
        </div>
      </div>
    </>
  );

  const KelanaDataView = () => (
    <div className="row row-deck row-cards">
      <Card title="Pilih Tanaman" styleBody={{ padding: 0, paddingTop: 20 }}>
        {/* Pilih Device */}
        <div className="col-md-6" style={{ marginBottom: 20 }}>
          <label htmlFor="true" className="form-label">
            Tanaman Fase (Tanggal Penanaman)
          </label>
          <Select
            options={
              data
                ? data.plant_data.map((e) => ({
                    value: e.key_plant,
                    label: `${e.plantData.plants_name} ${e.growth_data[0]?.data.growthPhase  || ''} (${e.planted_date})`,
                  }))
                : []
            }
            placeholder="Pilih perangkat"
            onChange={(e) => {
              setSelectPlant(e);
              handlePlant(e.value);
              setOpsRSC([]);
              setRSCDate([]);
            }}
            value={selectPlant}
          />
        </div>
      </Card>
      <Card
        title="Download Data RSC"
        styleBody={{ padding: 0, paddingTop: 20 }}
      >
        {/* Pilih Device */}
        <div className="row ">
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Pilih Tanggal
            </label>
            <Select
              options={
                Object.keys(plant).length &&
                plant?.rsc_data[0]?.measurement !== undefined
                  ? plant.rsc_data[0].measurement.map((e) => ({
                      value: e.dateTime,
                      label: moment(e.dateTime).format("DD/MM/YYYY"),
                    }))
                  : []
              }
              placeholder="Pilih Tanggal"
              onChange={(e) => setOpsRSC(e)}
              value={opsRSC}
              isDisabled={Object.keys(plant).length ? 0 : 1}
            />
          </div>
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Proses
            </label>
            <button
              type="button"
              className="btn btn-blue w-100"
              onClick={() => processRSC()}
            >
              <b>Proses</b>
            </button>
          </div>

          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Download Data RSC
            </label>
            {excelRSC.length ? (
              <ExcelFile
                filename={`Data RSC Kebun ${data.farm_name} Tanggal ${moment(
                  opsRSC.value
                ).format("DD-MM-YYYY")}`}
                element={
                  <button type="button" className="btn w-100 btn-success px-5">
                    Download
                  </button>
                }
              >
                <ExcelSheet data={excelRSC} name="Data RSC">
                  <ExcelColumn label="Lokasi Sample" value="location" />
                  <ExcelColumn label="Suhu (°C)" value="temp" />
                  <ExcelColumn label="RH (%)" value="rh" />
                  <ExcelColumn label="pH" value="ph" />
                  <ExcelColumn label="TDS (ppm)" value="tds" />
                  <ExcelColumn label="N" value="n" />
                  <ExcelColumn label="P" value="p" />
                  <ExcelColumn label="K" value="k" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <button
                type="button"
                disabled={1}
                className="btn w-100 btn-success px-5"
              >
                {" "}
                Proses Dulu Atuh !!!{" "}
              </button>
            )}
          </div>
        </div>
      </Card>

      <Card
        title="Download Data Pertumbuhan"
        styleBody={{ padding: 0, paddingTop: 20 }}
      >
        {/* Pilih Device */}
        <div className="row ">
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Proses
            </label>
            <button
              type="button"
              className="btn btn-blue w-100"
              onClick={() => processGrowth()}
            >
              <b>Proses</b>
            </button>
          </div>

          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Download Data Growth
            </label>
            {excelGrowth.key?.length ? (
              <ExcelFile
                filename={`Data Pertumbuhan Kebun ${data.farm_name}`}
                element={
                  <button type="button" className="btn w-100 btn-success px-5">
                    Download
                  </button>
                }
              >
                <ExcelSheet data={excelGrowth.data} name="Data RSC">
                  {excelGrowth.key.map((e) => (
                    <ExcelColumn label={e} value={e} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <button
                type="button"
                disabled={1}
                className="btn w-100 btn-success px-5"
              >
                {" "}
                Proses Dulu Atuh !!!{" "}
              </button>
            )}
          </div>
        </div>
      </Card>
      {/* <Card
        title="Edit Data RSC"
        styleBody={{ padding: 0, paddingTop: 20, paddingBottom: 30 }}
      >
        <div className="row ">
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Pilih Tanggal
            </label>
            <Select
              options={
                Object.keys(plant).length &&
                plant?.rsc_data[0]?.measurement !== undefined
                  ? plant.rsc_data[0].measurement.map((e) => ({
                      value: e.dateTime,
                      label: moment(e.dateTime).format("DD/MM/YYYY"),
                    }))
                  : []
              }
              placeholder="Pilih Tanggal"
              onChange={(e) => setRSCDate(e)}
              value={RSCDate}
              isDisabled={Object.keys(plant).length ? 0 : 1}
            />
          </div>
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <label htmlFor="true" className="form-label">
              Proses
            </label>
            <button
              type="button"
              className="btn btn-blue w-100"
              onClick={() => editRSC()}
            >
              <b>Proses</b>
            </button>
          </div>
          <div className="table-responsive-sm">
            <table className="table table-responsive">
              <thead className="text-center">
                <tr>
                  <td>Sample</td>
                  {keyName && keyName.map((val) => <td>{val.name}</td>)}
                  <td>Action</td>
                </tr>
              </thead>
              <tbody className="text-center">
                {RSCEdit &&
                  RSCEdit.map((value) => (
                    <tr>
                      <td>{value.location}</td>
                      {keyName &&
                        keyName.map((val) => <td>{value[val.key]}</td>)}
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger col-7 col-sm-10 col-md-6"
                          onClick={() => {
                            deleteHandler(RSCDate, value.location);
                          }}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card> */}
    </div>
  );

  return (
    <div className="container-xl">
      {/* header */}
      <HeaderView />
      {/* content */}
      {!loading ? <KelanaDataView /> : <LoadingView />}
    </div>
  );
}

export default KelanaData;
