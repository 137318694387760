import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "./Icon";
import useUserStore from "../stores/Users";
import "./Navbar.css";

function Navbar() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const AGRONOMIS = 6;
  const ENGINEER = 7;
  const user = useUserStore((state) => state.user);
  const logout = useUserStore((state) => state.logout);

  // Controller & Config
  const Linked = ({ to, title, children }) => {
    const location = useLocation();
    const pathName = location.pathname.split("/")[1];
    return (
      <li
        className={
          to === `/${pathName}`
            ? "nav-item Navbar_item Navbar_active"
            : "nav-item Navbar_item"
        }
      >
        <Link className="nav-link Navbar_nav-link" to={to}>
          <span className="nav-link-icon Navbar_icon d-md-none d-lg-inline-block">
            {children}
          </span>
          <span className="Navbar_title">{title}</span>
        </Link>
      </li>
    );
  };

  Linked.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout(null);
  };

  // View
  return (
    <div className="sticky-top noprint">
      {/* header */}
      <header className="navbar navbar-expand-md navbar-dark Navbar_dark sticky-top d-print-none">
        <div className="container-fluid">
          {/* leftside */}
          <button
            aria-label="Navbar Menu"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/static/hagelong.svg`}
                alt="Habibi Garden"
                height="30"
                className="navbar-brand-image"
                style={{ height: 18 }}
              />
              {/* <img src={process.env.PUBLIC_URL + "./static/logo-white.svg"} width='110' height='32' alt='Tabler' className='navbar-brand-image' /> */}
            </Link>
          </h1>

          {/* rightside */}
          <div className="navbar-nav flex-row order-md-last">
            {/* notifikasi */}
            {/* <div className='nav-item Navbar_nav-item dropdown d-none d-md-flex me-3'>
              <Link
                className='nav-link Navbar_nav-link px-0'
                data-bs-toggle='dropdown'
                tabindex='-1'
                aria-label='Show notifications'>
                <Icon name='Bell' size={17} color={"#fff"} />
                <span className='badge bg-red'></span>
              </Link>
              <div className='dropdown-menu dropdown-menu-end dropdown-menu-card'>
                <div className='card'>
                  <div className='card-body'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad amet
                    consectetur exercitationem fugiat in ipsa ipsum, natus odio quidem quod
                    repudiandae sapiente. Amet debitis et magni maxime necessitatibus ullam.
                  </div>
                </div>
              </div>
            </div> */}

            {/* profile */}
            <div className="nav-item Navbar_nav-item dropdown">
              <a
                href="#user"
                className="nav-link Navbar_nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/avatar.jpg`}
                  className="avatar Navbar_avatar avatar-sm"
                  alt="users"
                />
                <div className="d-none d-xl-block ps-2">
                  <div className="username">
                    <strong>
                      {user.first_name} {user.last_name}
                    </strong>
                  </div>
                  <div className="mt-1 small">{user.email}</div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                {/* <Link to='/profile' className='dropdown-item'>Set status</Link>
                <Link to='/profile' className='dropdown-item'>Profile & account</Link>
                <Link to='/profile' className='dropdown-item'>Feedback</Link> */}
                <Link to="/password" className="dropdown-item">
                  Ubah Password
                </Link>
                <a
                  href="#handleLogout"
                  onClick={(e) => handleLogout(e)}
                  className="dropdown-item"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* menu */}
      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-fluid">
              <ul className="navbar-nav">
                {/* dashboard */}
                <Linked to="/" title="Dashboard">
                  <Icon name="Home" size={17} />
                </Linked>

                {/* farm */}
                <Linked to="/farm" title="Kelola Kebun">
                  <Icon name="Map" size={17} />
                </Linked>

                {/* farmer */}
                <Linked
                  to="/farmer"
                  title={user.status === ADMIN ? "Pengguna" : "Petani"}
                >
                  <Icon name="Users" size={17} />
                </Linked>

                {/* form input sample npk */}
                {user.status === ADMIN || user.status === AGRONOMIS ? null : (
                  <Linked to="/npk" title="Form NPK">
                    <Icon name="Droplet" size={17} />
                  </Linked>
                )}

                {/* agriculture */}
                {user.status === ADMIN || user.status === AGRONOMIS ? (
                  <Linked to="/agriculture/plant" title="Pertanian">
                    <Icon name="Grid" size={17} />
                  </Linked>
                ) : (
                  <></>
                )}

                {user.status === ADMIN ||
                  user.status === AGRONOMIS ||
                  user.status === ENGINEER ? (
                  <>
                    {/* notification */}
                    <Linked to="/notification" title="Notifikasi">
                      <Icon name="Bell" size={17} />
                    </Linked>

                    {/* issues */}
                    {/* <Linked to="/issues" title="Daftar Issues">
                      <Icon name="List" size={17} />
                    </Linked> */}

                    {/* report */}
                    <Linked to="/report" title="Laporan">
                      <Icon name="Archive" size={17} />
                    </Linked>

                    {/* warehouse */}
                    {/* <Linked to="/warehouse" title="Warehouse">
                      <Icon name="Package" size={17} />
                    </Linked> */}

                    {/* config */}
                    {/* <Linked to="/config" title={'Konfigurasi'}>
                      <Icon name="Settings" size={17} />
                    </Linked> */}
                  </>
                ) : (
                  <div />
                )}

                {(user.status === ADMIN ||
                  user.status === AGRONOMIS ||
                  user.status === ENGINEER ||
                  user.status === OWNER) && (
                    <>
                      {/* kelana */}
                      <Linked to="/kelana" title="Kelana">
                        <Icon name="Command" size={17} />
                      </Linked>
                    </>
                  )}

              </ul>

              {/* search bar */}
              {/* <div className='my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last'>
                <form action='.' method='get'>
                  <div className='input-icon'>
                    <span className='input-icon-addon'>
                      <Icon name='Search' size={17} />
                    </span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search…'
                      aria-label='Search in website'
                    />
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
