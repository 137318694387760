import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';
import { stroke, grid, yaxis } from '../../../components/ChartOptions';

function SensorDose(props) {
  const [statusDevice, setStatusDevice] = useState(false);
  const [dose, setDose] = useState([]);
  const [dataX, setDataX] = useState([]);
  const [dataY, setDataY] = useState([]);
  const [sensorMenu, setSensorMenu] = useState('ppm');
  const [isLoading, setLoading] = useState(false);

  useEffect(async () => {
    // start loading
    setLoading(true);

    await axios
      .post('https://api.habibigarden.com/v3/farms/data_sensor', {
        device: 'dose',
        farmCode: props.farmCode,
        timeMode: 'weekly',
      })
      .then((res) => {
        if (res.data.length >= 1) setStatusDevice(true);

        // init variable for raw and temporary data from dose
        const rawDose = res.data;
        const tmpDose = [];
        const tmpX = [];
        const tmpY = [];

        // looping all data from dose
        rawDose.forEach((item) => {
          const parseData = JSON.parse(item.data);

          // restructure data
          tmpX.push(moment(item.time).format('DD/MM/YYYY HH:mm:ss'));
          tmpY.push(parseData.setting.ppm_pupuk);
          tmpDose.push({
            time: moment(item.time).format('DD/MM/YYYY HH:mm:ss'),
            sensorTDS: parseData.sensor.tds,
            sensorPH: parseData.sensor.ph,
            sensorTemp: parseData.sensor.temp,
            settingVolTorrent: parseData.setting.vol_torrent,
            settingPumpFlow: parseData.setting.pump_flow,
            settingFertFlow: parseData.setting.fert_flow,
            settingCF: parseData.setting.cf,
            settingSlope: parseData.setting.slope,
            settingIntercept: parseData.setting.intercept,
            settingPPM: parseData.setting.ppm_pupuk,
          });
        });

        // set new data
        setDataX(tmpX);
        setDataY(tmpY);
        setDose(tmpDose);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleSensor = (menu) => {
    const tmpY = [];

    // looping all data from dose
    dose.forEach((item) => {
      if (menu === 'tds') {
        tmpY.push(item.sensorTDS);
      } else if (menu === 'ph') {
        tmpY.push(item.sensorPH);
      } else if (menu === 'temp') {
        tmpY.push(item.sensorTemp);
      } else if (menu === 'vol_torent') {
        tmpY.push(item.settingVolTorrent);
      } else if (menu === 'pump_flow') {
        tmpY.push(item.settingPumpFlow);
      } else if (menu === 'fert_flow') {
        tmpY.push(item.settingFertFlow);
      } else if (menu === 'cf') {
        tmpY.push(item.settingCF);
      } else if (menu === 'slope') {
        tmpY.push(item.settingSlope);
      } else if (menu === 'intercept') {
        tmpY.push(item.settingIntercept);
      } else {
        tmpY.push(item.settingPPM);
      }
    });

    setSensorMenu(menu);
    setDataY(tmpY);
  };

  const mean = (number) => number.reduce((prev, curr) => prev + curr, 0) / number.length;

  const modus = (number) => {
    let mode = 0;
    let count = 0;

    for (let i = 0; i < number.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (number[j] === number[i]) {
          mode = number[j];
          count += 1;
        }
      }
    }

    return mode;
  };

  const doseView = (params) => {
    const selectOptions = [
      { value: 'tds', label: 'TDS' },
      { value: 'ph', label: 'pH' },
      { value: 'temp', label: 'Suhu' },
      { value: 'vol_torent', label: 'Volume Torrent' },
      { value: 'pump_flow', label: 'Flow Air' },
      { value: 'fert_flow', label: 'Flow Pupuk' },
      { value: 'cf', label: 'CF' },
      { value: 'slope', label: 'Slope' },
      { value: 'intercept', label: 'Intercept' },
      { value: 'ppm', label: 'PPM Pupuk' },
    ];

    const seriesX = [
      {
        name: selectOptions.filter((opt) => opt.value === sensorMenu)[0].label,
        data: dataY,
      },
    ];

    const chartOptions = {
      chart: {
        fontFamily: 'inherit',
        parentHeightOffset: 0,
        toolbar: {
          show: false,
        },
      },
      stroke,
      grid,
      yaxis,
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
        },
        categories: dataX,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    };

    return (
      <div id="wrapper">
        <div className="row row-deck row-cards">
          {/* Column Filter */}
          <div className="col-md-12">
            <Card title="Menu Dose">
              <div className="row">
                {/* pilih sensor */}
                <div className="col-md-12">
                  <label htmlFor="true" className="form-label">
                    Pilih Sensor
                  </label>
                  <Select
                    value={selectOptions.filter((opt) => opt.value === sensorMenu)}
                    options={selectOptions}
                    placeholder="Pilih Sensor"
                    onChange={(e) => handleSensor(e.value)}
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* Chart */}
          <div className="col-md-12">
            <Card title={selectOptions.filter((opt) => opt.value === sensorMenu)[0].label}>
              <ReactApexChart type="area" height={300} options={chartOptions} series={seriesX} />

              <table className="text-center mt-4 table table-responsive table-bordered">
                <thead>
                  <tr>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Rata-Rata</th>
                    <th>Modus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Math.min(...dataY)}</td>
                    <td>{Math.max(...dataY)}</td>
                    <td>{mean(dataY)}</td>
                    <td>{modus(dataY)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </div>
    );
  };

  const handleView = () => {
    if (statusDevice) {
      return doseView();
    }

    return (
      <div className="col-md-12">
        <Card title="Informasi" className="border-warning text-warning">
          Mohon maaf, kebun ini belum memasang Habibi Dose.
          <br />
          Silahkan hubungi nomor berikut 0812-7763-9698 untuk memasang Habibi Dose.
        </Card>
      </div>
    );
  };

  return (
    <>
      {/* content */}
      {isLoading ? <LoadingView /> : handleView()}
    </>
  );
}

SensorDose.propTypes = {
  farmCode: PropTypes.arrayOf.isRequired,
};

export default SensorDose;
