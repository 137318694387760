import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Card from '../../components/SimpleCard';
import useUserStore from '../../stores/Users';

function Password() {
  const id = useUserStore((state) => state.user.id);
  const { register, errors, handleSubmit } = useForm();
  const [password, setPassword] = useState({
    old_password: null,
    new_password: null,
    confirm_password: null,
  });
  const changePassword = (params) => {
    console.log(params);
  };

  const passwordView = () => (
    <>
      <Card title="Ubah Password">
        <form onSubmit={handleSubmit(changePassword)}>
          <div className="row row-decks row-cards">
            <div className="row">
              {/* Password lama */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Password Lama
                </label>
                <input
                  ref={register({ required: true })}
                  name="old_password"
                  type="password"
                  className="form-control"
                  placeholder="Masukan Password Lama"
                />
                {errors.old_password && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Password Lama wajib diisi
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              {/* Password Baru */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Password Baru
                </label>
                <input
                  ref={register({ required: true })}
                  name="new_password"
                  type="password"
                  className="form-control"
                  placeholder="Masukan Password Baru"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setPassword({ ...password, new_password: e.target.value });
                  }}
                />
                {errors.new_password && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Password Baru wajib diisi
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              {/* Konfirmasi Password */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Konfirmasi Password
                </label>
                <input
                  ref={register({ required: true })}
                  name="confirm_password"
                  type="password"
                  className="form-control"
                  placeholder="Masukan Konfirmasi Password Baru"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setPassword({ ...password, confirm_password: e.target.value });
                  }}
                />
                {errors.confirm_password && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Konfirmasi Password wajib diisi
                  </div>
                )}
                {password.confirm_password !== password.new_password &&
                password.confirm_password !== '' ? (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Password Tidak Sama
                  </div>
                ) : null}
                {password.confirm_password === password.new_password &&
                password.confirm_password !== '' &&
                password.new_password !== '' ? (
                  <div className="pt-1" style={{ color: 'green' }}>
                    Password Sesuai
                  </div>
                ) : null}
              </div>
            </div>
            <br />
            {/* tombol */}
            <div className="col-md-12">
              <input
                disabled={
                  password.confirm_password !== password.new_password ||
                  password.confirm_password === ''
                    ? 1
                    : 0
                }
                className="btn w-100 btn-primary px-5"
                type="submit"
                value="Ubah Password"
              />
            </div>
          </div>
        </form>
      </Card>
    </>
  );
  return (
    <div className="container-xl">
      <div className="row">
        {/* content */}
        {passwordView()}
      </div>
    </div>
  );
}
export default Password;
