import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

function NotificationEdit() {
  const id = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit, setValue } = useForm();
  const [notifTitle, setnotifTitle] = useState('');
  const [sensorType, setsensorType] = useState('');
  const [notifMin, setnotifMin] = useState('');
  const [notifMax, setnotifMax] = useState('');
  const [notif, setnotif] = useState('');

  const storeData = (params) => {};

  const submitData = () => {
    const tmpData = [];
    tmpData.push(...notif, {
      sensor: sensorType,
      notification_name: notifTitle,
      notification_min: notifMin,
      notification_max: notifMax,
    });

    console.log(tmpData);
    setnotif(tmpData);
  };

  const updateData = (params) => {};

  const headerView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/notification')}
            className="col-md-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Ubah</div>
            <h2 className="page-title">Notifikasi</h2>
          </div>
        </div>
      </div>
    </>
  );

  const notificationAddView = () => {
    const optSelect = [];

    return (
      <div className="row">
        <div className="col-md-12">
          {/* Data Pertumbuhan */}
          <Card title="Form Notifikasi">
            <div className="card">
              <div className="card-body">
                <div className="row g-2 mt-3">
                  <div className="col md">
                    <label htmlFor="floatingInputGrid" className="fw-bold">Judul Notifikasi</label>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInputGrid"
                        placeholder="Judul Notifikasi"
                        onChange={(e) => setnotifTitle(e.target.value)}
                      />
                      <label htmlFor="floatingInput">Masukkan Judul Notifikasi</label>
                    </div>
                  </div>

                  <div className="col md">
                    <label htmlFor="floatingSelect" className="fw-bold">Jenis Sensor</label>
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        aria-label="Jenis Sensor"
                        onChange={(e) => setsensorType(e.target.value)}
                      >
                        <option>Jenis Sensor</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label className="fw-bold" htmlFor="floatingSelect">
                        Jenis Sensor
                      </label>
                    </div>
                  </div>

                  <div className="row g-2 mb-5 ">
                    <div className="col md form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: '100px' }}
                        onChange={(e) => setnotifMin(e.target.value)}
                      />
                      <label htmlFor="floatingTextarea2">Comments</label>
                    </div>

                    <div className="col md form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: '100px' }}
                        onChange={(e) => setnotifMin(e.target.value)}
                      />
                      <label htmlFor="floatingTextarea2">Comments</label>
                    </div>
                  </div>

                  <div className="d-grid gap-2 ">
                    <button type="button" className="btn btn-success btn-lg" onClick={submitData}>
                      Edit Notifikasi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="container-xl">
      {headerView()}

      {/* content */}
      {loading ? <LoadingView /> : notificationAddView()}
    </div>
  );
}

export default NotificationEdit;
