import axios from 'axios';

const oldAPI = axios.create({
  baseURL: 'https://habibigarden.com/dashboard/public/api',
});
const newAPI = axios.create({ baseURL: 'https://api.habibigarden.com' });
const awsKelanaAPI = axios.create({
  baseURL: 'https://7bqnkxh22a.execute-api.ap-southeast-1.amazonaws.com/Prod',
});
const awsHabibigardenAPI = axios.create({
  baseURL: 'https://c0dtyd3z10.execute-api.ap-southeast-1.amazonaws.com/prod',
});

// instance.defaults.headers.common['Authorization'] = token;
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export {awsKelanaAPI, oldAPI, newAPI, awsHabibigardenAPI};
