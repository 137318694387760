import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import Swal from 'sweetalert2';

import useUserStore from '../../stores/Users';
import { oldAPI, newAPI } from '../../services/baseAPI';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';

function AgricultureEdit() {
  // State & Fetch
  const { menu, id } = useParams();
  const history = useHistory();
  const token = useUserStore((state) => state.token);
  const { register, errors, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);

  // Plant ops
  const [opsSeedingMonth, setOpsSeedingMonth] = useState(null);
  const [opsHarvestMonth, setOpsHarvestMonth] = useState(null);
  const [opsCultivationMonth, setOpsCultivationMonth] = useState(null);
  const [opsSeeding, setOpsSeeding] = useState(null);
  const [opsSeedingPlace, setOpsSeedingPlace] = useState(null);

  // disease ops
  // const [dataOption, setDataOption] = useState([]);
  const [opsHpt, setOpsHpt] = useState(null);
  const [opsUnit, setOpsUnit] = useState(null);
  const [opsDanger, setOpsDanger] = useState(null);
  const [opsPlant, setOpsPlant] = useState(null);
  const [opsPlantData, setOpsPlantData] = useState(null);

  // insert data select to setValue
  const selectValue = (SelectedOption, nameValue) => {
    const value = SelectedOption.map((val) => val.value);
    setValue(nameValue, JSON.stringify(value));
  };

  // insert data select to multiple select
  const arraySetOps = (arrayFilter, arrayVal) => {
    const arrayOps = [];
    const arrayOpsVal = JSON.parse(arrayVal);
    arrayOpsVal.forEach((element) => {
      arrayOps.push(arrayFilter.filter((item) => item.value === element)[0]);
    });
    return arrayOps;
  };

  const fetchPlants = useCallback(async () => {
    setIsLoading(true);
    await oldAPI
      .get('/v2/pestdisease/create', { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const arrTmpPlant = [];
        const plant = res.data.data;
        plant.forEach((item) =>
          arrTmpPlant.push({
            value: item.id,
            label: item.plants_name,
          })
        );
        setOpsPlantData(arrTmpPlant);
        setIsLoading(false);
      })
      .catch((error) => console.log(`error ${error}`));
  }, []);

  const fetchPlant = useCallback(async () => {
    setIsLoading(true);
    await newAPI
      .get(`/plants/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const fields = [
          'plants_name',
          'scientific_name',
          'family',
          'genus',
          'country_origin',
          'harvest_est',
          'min_temperature',
          'max_temperature',
          'min_humidity',
          'max_humidity',
          'min_soil',
          'max_soil',
          'min_nutrient',
          'max_nutrient',
          'min_light',
          'max_light',
          'harvested_part',
          'seeding',
          'seeding_place',
          'cultivation_month',
          'harvest_month',
          'seeding_month',
          'ease_care',
          'tolerance',
          'water_needs',
          'pot_volume',
          'population',
          'min_germination',
          'max_germination',
          'min_mature',
          'max_mature',
          'min_seed_distance',
          'max_seed_distance',
          'min_planting_distance',
          'max_planting_distance',
          'min_planting_row_distance',
          'max_planting_row_distance',
          'min_plant_depth',
          'max_plant_depth',
          'calc_cultivation',
          'calc_water_needs',
          'calc_kc_in',
          'calc_kc_dev',
          'calc_kc_mid',
          'calc_kc_late',
          'calc_l_in',
          'calc_l_dev',
          'calc_l_mid',
          'calc_l_late',
          'calc_kai_in',
          'calc_kai_dev',
          'calc_kai_mid',
          'calc_kai_late',
          'note',
          'description',
        ];
        const months = [
          { value: 'januari', label: 'Januari' },
          { value: 'februari', label: 'Februari' },
          { value: 'maret', label: 'Maret' },
          { value: 'april', label: 'April' },
          { value: 'mei', label: 'Mei' },
          { value: 'juni', label: 'Juni' },
          { value: 'juli', label: 'Juli' },
          { value: 'agustus', label: 'Agustus' },
          { value: 'september', label: 'September' },
          { value: 'oktober', label: 'Oktober' },
          { value: 'november', label: 'November' },
          { value: 'desember', label: 'Desember' },
        ];

        const planting = [
          { value: 'penyemaian', label: 'Penyemaian' },
          { value: 'tanam Biji', label: 'Tanam Biji' },
          { value: 'bibit', label: 'Bibit' },
        ];

        const location = [
          { value: 'langsung tanam', label: 'Langsung Tanam' },
          { value: 'pindah tanam', label: 'Pindah tanam' },
          { value: 'greenhouse', label: 'Greenhouse' },
          { value: 'jendela', label: 'Jendela' },
        ];
        const plant = res.data[0];
        setOpsSeedingMonth(arraySetOps(months, plant.seeding_month));
        setOpsHarvestMonth(arraySetOps(months, plant.harvest_month));
        setOpsCultivationMonth(arraySetOps(months, plant.cultivation_month));
        setOpsSeeding(arraySetOps(planting, plant.seeding));
        setOpsSeedingPlace(arraySetOps(location, plant.seeding_place));
        setImagePreview(`https://api.habibigarden.com/plants/${plant.image}`);
        setData(plant);
        fields.forEach((field) => setValue(field, plant[field]));
        setIsLoading(false);
      })
      .catch((error) => console.log(`error ${error}`));
  }, []);

  const fetchDisease = useCallback(async () => {
    await oldAPI
      .get(`/v2/pestdisease/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const fields = [
          'name',
          'host',
          'scientific',
          'type_pesticide',
          'area',
          'dvs_0',
          'dvs_15',
          'dvs_20',
          'dvs_25',
          'average_dosis',
        ];
        const arrTmp = [];
        res.data.data.plant.forEach((item) =>
          arrTmp.push({
            value: item.id,
            label: item.plants_name,
          })
        );
        const hpt = [
          { value: 1, label: 'Hama' },
          { value: 2, label: 'Virus' },
          { value: 3, label: 'Jamur' },
          { value: 4, label: 'Bakteri' },
          { value: 5, label: 'Tular Tanah' },
        ];

        const unit = [
          { value: 'Kg/l', label: 'Kg/l' },
          { value: 'ml/l', label: 'ml/l' },
          { value: 'g/l', label: 'g/l' },
          { value: 'L/l', label: 'L/l' },
        ];
        const danger = [
          { value: 1, label: 'Cukup Berbahaya' },
          { value: 2, label: 'Sangat Berbahaya Sekali' },
          { value: 3, label: 'Berbahaya Sekali' },
          { value: 4, label: 'Berbahaya' },
          { value: 5, label: 'Tidak Berbahaya Pada Pemakaian Normal' },
        ];

        setOpsHpt(hpt.filter((item) => item.value === res.data.data.pest_disease.hpt));
        setOpsUnit(unit.filter((item) => item.value === res.data.data.pest_disease.unit));
        setOpsDanger(danger.filter((item) => item.value === res.data.data.pest_disease.danger));
        setData(res.data.data.pest_disease);

        setOpsPlant(arrTmp);

        setImagePreview(
          `https://habibigarden.com/dashboard/public/dist/img/hamapenyakit/${res.data.data.pest_disease.img}`
        );
        fields.forEach((field) => setValue(field, res.data.data.pest_disease[field]));
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchArticle = useCallback(async () => {
    await newAPI
      .get(`/articles/${id}`)
      .then((res) => {
        setData(res.data[0]);
        const fields = ['title', 'category', 'content'];
        fields.forEach((field) => setValue(field, res.data[0][field]));
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    switch (menu) {
      case 'article':
        fetchArticle();
        break;
      case 'disease':
        fetchPlants();
        fetchDisease();
        break;
      case 'plant':
        fetchPlant();
        break;
      default:
        break;
    }

    // register react-hook-form for CKEditor
    register('content');

    // disease
    register('hpt');
    register('plant');
    register('desc');
    register('treatment');
    register('symptoms');
    register('active_ingredient');
    register('unit');
    register('use_instruction');
    register('danger');
    register('precaution');

    // Plant
    register('plantDescription');
    register('plantNote');
    register('plantSeeding_month');
    register('plantHarvest_month');
    register('cultivation_month');
    register('plantSeeding');
    register('plantSeeding_place');
  }, []);

  // Controller
  const updateData = (params) => {
    setIsLoading(true);

    const body = new FormData();

    switch (params.menu) {
      case 'article':
        console.log(image);
        console.log(params);
        body.append('title', params.title);
        body.append('content', params.content);
        body.append('category', params.category);
        // body.append('image', image);
        newAPI
          .put(`/articles/${id}`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            fetchArticle();
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setIsLoading(false);
            Swal.fire('Saved!', 'Artikel berhasil di-update', 'success')
          });
        break;
      case 'plant':
        body.append('name', params.plants_name);
        body.append('scientific_name', params.scientific_name);
        body.append('family', params.family);
        body.append('genus', params.genus);
        body.append('country_origin', params.country_origin);
        body.append('harvest_est', params.harvest_est);

        body.append('min_temperature', params.min_temperature);
        body.append('max_temperature', params.max_temperature);
        body.append('min_humidity', params.min_humidity);
        body.append('max_humidity', params.max_humidity);
        body.append('min_soil', params.min_soil);
        body.append('max_soil', params.max_soil);
        body.append('min_nutrient', params.min_nutrient);
        body.append('max_nutrient', params.max_nutrient);
        body.append('min_light', params.min_light);
        body.append('max_light', params.max_light);

        body.append('seeding_month', params.seeding_month);
        body.append('harvest_month', params.harvest_month);
        body.append('cultivation_month', params.cultivation_month);
        body.append('seeding', params.seeding);
        body.append('seeding_place', params.seeding_place);

        body.append('harvested_part', params.harvested_part);
        body.append('ease_care', params.ease_care);
        body.append('tolerance', params.tolerance);
        body.append('water_needs', params.water_needs);
        body.append('pot_volume', params.pot_volume);
        body.append('min_germination', params.min_germination);
        body.append('max_germination', params.max_germination);
        body.append('min_mature', params.min_mature);
        body.append('max_mature', params.max_mature);
        body.append('min_seed_distance', params.min_seed_distance);
        body.append('max_seed_distance', params.max_seed_distance);
        body.append('min_planting_row_distance', params.min_planting_row_distance);
        body.append('max_planting_row_distance', params.max_planting_row_distance);
        body.append('min_plant_depth', params.min_plant_depth);
        body.append('max_plant_depth', params.max_plant_depth);
        body.append('calc_cultivation', params.calc_cultivation);
        body.append('calc_water_needs', params.calc_water_needs);
        body.append('calc_kc_in', params.calc_kc_in);
        body.append('calc_kc_dev', params.calc_kc_dev);
        body.append('calc_kc_mid', params.calc_kc_mid);
        body.append('calc_kc_late', params.calc_kc_late);
        body.append('calc_l_in', params.calc_l_in);
        body.append('calc_l_dev', params.calc_l_dev);
        body.append('calc_l_mid', params.calc_l_mid);
        body.append('calc_l_late', params.calc_l_late);
        body.append('calc_kai_in', params.calc_kai_in);
        body.append('calc_kai_dev', params.calc_kai_dev);
        body.append('calc_kai_mid', params.calc_kai_mid);
        body.append('calc_kai_late', params.calc_kai_late);
        body.append('note', params.note);
        body.append('description', params.description);

        // body.append('image', image);

        newAPI
          .put(`/plants/${id}`, body, {
            headers: {
              // Accept: 'application/json',
              // Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setIsLoading(false);
            Swal.fire('Saved!', 'Tanaman berhasil di-update', 'success')
          });
        break;
      case 'disease':
        body.append('data_tanaman', params.diseasePlant);
        body.append('name', params.diseaseName);
        body.append('img', image);
        body.append('desc', params.diseaseDesc);
        body.append('treatment', params.diseaseTreatment);
        body.append('scientific', params.diseaseScientific);
        body.append('hpt', params.diseaseHpt);
        body.append('symptoms', params.diseaseSymptoms);
        body.append('type_pesticide', params.diseaseType_pesticide);
        body.append('active_ingredient', params.diseaseActive_ingredient);
        body.append('dvs_0', params.diseaseDvs_0);
        body.append('dvs_15', params.diseaseDvs_15);
        body.append('dvs_20', params.diseaseDvs_20);
        body.append('dvs_25', params.diseaseDvs_25);
        body.append('average_dosis', params.diseaseAverage_dosis);
        body.append('use_instruction', params.diseaseUse_instruction);
        body.append('danger', params.diseaseDanger);
        body.append('precaution', params.diseasePrecaution);
        body.append('area', params.diseaseArea);
        body.append('unit', params.diseaseUnit);
        body.append('host', params.diseaseHost);

        oldAPI
          .put(`/v2/pestdisease/${id}`, body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setIsLoading(false);
            Swal.fire('Saved!', 'Hama penyakit berhasil di-update', 'success')
          });
        break;
      default:
        break;
    }
  };

  // View
  const headerView = () => {
    let name = '';
    if (menu === 'article') name = 'Artikel';
    if (menu === 'disease') name = 'Hama Penyakit';
    if (menu === 'growth') name = 'Data Pertumbuhan';
    if (menu === 'plant') name = 'Tanaman';
    if (menu === 'cultivation') name = 'Data Budidaya';

    return (
      <>
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <button
              aria-label="Back Button"
              type="button"
              onClick={() => history.push(`/agriculture/${menu}`)}
              className="col-md-2"
              style={{
                marginLeft: '8px',
                marginRight: '10px',
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: 'rgba(255, 0, 0, 0)',
                borderWidth: 0,
              }}
            >
              <Icon name="ArrowLeft" color="#333" size={20} />
            </button>
            <div className="col">
              <div className="page-pretitle">Ubah</div>
              <h2 className="page-title">{name}</h2>
            </div>
          </div>
        </div>
      </>
    );
  };

  const articleView = () => (
    <>
      {/* Data Artikel */}
      <Card title="Form Artikel">
        <form onSubmit={handleSubmit(updateData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="article" />
            {/* judul artikel */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Judul Artikel
              </label>
              <input
                ref={register({ required: true })}
                name="title"
                type="text"
                className="form-control"
                placeholder="Masukan judul artikel"
                defaultValue={data.title}
              />
              {errors.title && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Judul artikel wajib diisi
                </div>
              )}
            </div>

            {/* preview & input gambar */}
            {imagePreview ? (
              <img
                className="col-md-12 mx-auto d-block"
                alt="pic preview"
                height={350}
                src={imagePreview}
              />
            ) : (
              <img
                className="col-md-12 mx-auto d-block"
                height={350}
                alt="pic preview"
                src={`https://api.habibigarden.com/articles/${data.image}`}
              />
            )}

            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Gambar <span className="text-danger small">(Klik untuk mengubah gambar)</span>
              </label>
              <input
                ref={register}
                name="image"
                type="file"
                className="form-control"
                placeholder="Masukan gambar"
                onChange={(e) => setImagePreview(URL.createObjectURL(e.target.files[0]))}
              />
              {errors.image && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Gambar artikel wajib diisi
                </div>
              )}
            </div>

            {/* kategori */}
            <div className="col-md-6">
              <div className="form-label">Kategori</div>
              <select ref={register} name="category" className="form-select form-control">
                <option value="DEFAULT" disabled>
                  Pilih salah satu
                </option>
                <option selected={data.category === 'berita' ? 1 : false} value="berita">
                  Berita
                </option>
                <option selected={data.category === 'budidaya' ? 1 : false} value="budidaya">
                  Budidaya
                </option>
                <option selected={data.category === 'inspirasi' ? 1 : false} value="inspirasi">
                  Inspirasi
                </option>
                <option selected={data.category === 'teknologi' ? 1 : false} value="teknologi">
                  Teknologi
                </option>
                <option selected={data.category === 'hama' ? 1 : false} value="hama">
                  Hama Penyakit
                </option>
                <option selected={data.category === 'informasi' ? 1 : false} value="informasi">
                  Informasi
                </option>
                <option selected={data.category === 'slibmkg' ? 1 : false} value="slibmkg">
                  SLI BMKG
                </option>
                <option selected={data.category === 'lain-lain' ? 1 : false} value="lain-lain">
                  Lain-Lain
                </option>
              </select>
              {errors.category && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Kategori wajib diisi
                </div>
              )}
            </div>

            {/* konten artikel */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Konten Artikel
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={data.content}
                onChange={(event, editor) => {
                  const dataEditor = editor.getData();
                  setValue('content', dataEditor);
                }}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                  ],
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3',
                      },
                    ],
                  },
                }}
              />
              {errors.content && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Kontent artikel wajib diisi
                </div>
              )}
            </div>

            {/* tombol */}
            <div className="col-md-12">
              <input className="btn btn-primary w-100 px-5" type="submit" value="Ubah" />
            </div>
          </div>
        </form>
      </Card>
    </>
  );

  const diseaseView = () => {
    const hpt = [
      { value: 1, label: 'Hama' },
      { value: 2, label: 'Virus' },
      { value: 3, label: 'Jamur' },
      { value: 4, label: 'Bakteri' },
      { value: 5, label: 'Tular Tanah' },
    ];

    const unit = [
      { value: 'Kg/l', label: 'Kg/l' },
      { value: 'ml/l', label: 'ml/l' },
      { value: 'g/l', label: 'g/l' },
      { value: 'L/l', label: 'L/l' },
    ];
    const danger = [
      { value: 1, label: 'Cukup Berbahaya' },
      { value: 2, label: 'Sangat Berbahaya Sekali' },
      { value: 3, label: 'Berbahaya Sekali' },
      { value: 4, label: 'Berbahaya' },
      { value: 5, label: 'Tidak Berbahaya Pada Pemakaian Normal' },
    ];
    return (
      <>
        {/* Data hama penyakit */}
        <Card title="Form Hama Penyakit">
          <form onSubmit={handleSubmit(updateData)}>
            <div className="row row-decks row-cards">
              <input ref={register} name="menu" type="hidden" value="disease" />

              {/* Nama */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama
                </label>
                <input
                  ref={register({ required: true })}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama hama/penyakit"
                />
                {errors.name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul nama hama/penyakit wajib diisi
                  </div>
                )}
              </div>

              {/* Nama Tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Tanaman
                </label>
                <Select
                  options={opsPlantData}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsPlant}
                  onChange={(params) => {
                    setOpsPlant(params);
                    selectValue(params, 'diseasePlant');
                  }}
                />
              </div>

              {/*  Tanaman Inang  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Tanaman Inang
                </label>
                <input
                  ref={register()}
                  name="host"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama Tanaman Inang"
                />
                {errors.host && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul Tanaman Inang wajib diisi
                  </div>
                )}
              </div>

              {/*   Nama Ilmiah   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Ilmiah
                </label>
                <input
                  ref={register()}
                  name="scientific"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama Nama Ilmiah"
                />
                {errors.scientific && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul Nama Ilmiah wajib diisi
                  </div>
                )}
              </div>

              {/* preview & input gambar */}
              {imagePreview ? (
                <img className="col-md-12 mx-auto d-block" alt="pic preview" src={imagePreview} />
              ) : null}

              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gambar
                </label>
                <input
                  ref={register}
                  name="image"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gambar artikel wajib diisi
                  </div>
                )}
              </div>

              {/* Deksripsi */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Deksripsi
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.desc}
                  onChange={(event, editor) => {
                    const dataCk = editor.getData();
                    setValue('desc', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.desc && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Deskripsi wajib diisi
                  </div>
                )}
              </div>

              {/* Saran Perlakuan */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Saran Perlakuan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.treatment}
                  onChange={(event, editor) => {
                    const dataCk = editor.getdataCk();
                    setValue('treatment', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.treatment && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Saran Perlakuan wajib diisi
                  </div>
                )}
              </div>

              <hr />

              {/* Jenis HPT */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Jenis HPT
                </label>
                <Select
                  options={hpt}
                  closeMenuOnSelect={false}
                  value={opsHpt}
                  onChange={(params) => {
                    setOpsHpt(params);
                    selectValue(params, 'hpt');
                  }}
                />
              </div>

              {/* Gejala */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gejala
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.symptoms}
                  onChange={(event, editor) => {
                    const dataCk = editor.getData();
                    setValue('symptoms', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.symptoms && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gejala wajib diisi
                  </div>
                )}
              </div>

              <hr />
              {/*    Jenis Pestisida    */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Jenis Pestisida
                </label>
                <input
                  ref={register()}
                  name="type_pesticide"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Jenis Pestisida"
                />
                {errors.type_pesticide && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Jenis Pestisida wajib diisi
                  </div>
                )}
              </div>

              {/*  Bahan Aktif  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Bahan Aktif
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.active_ingredient}
                  onChange={(event, editor) => {
                    const dataCk = editor.getData();
                    setValue('active_ingredient', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.active_ingredient && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Bahan Aktif wajib diisi
                  </div>
                )}
              </div>

              {/*     Luas Area(ha)     */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Luas Area(ha)
                </label>
                <input
                  ref={register()}
                  name="area"
                  type="text"
                  className="form-control"
                  placeholder="Masukan  Luas Area(ha) "
                  step="0.01"
                />
                {errors.area && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Luas Area(ha) wajib diisi
                  </div>
                )}
              </div>

              {/* Satuan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Satuan
                </label>
                <Select
                  options={unit}
                  closeMenuOnSelect={false}
                  value={opsUnit}
                  onChange={(params) => {
                    setOpsUnit(params);
                    selectValue(params, 'unit');
                  }}
                />
              </div>

              {/*  0 (Kg/L)  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  0 (Kg/L)
                </label>
                <input
                  ref={register()}
                  name="dvs_0"
                  type="number"
                  className="form-control"
                  placeholder="Masukan 0 (Kg/L)  "
                  step="0.01"
                />
                {errors.dvs_0 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    0 (Kg/L) wajib diisi
                  </div>
                )}
              </div>

              {/*   15 (g/L)   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  15 (g/L)
                </label>
                <input
                  ref={register()}
                  name="dvs_15"
                  type="number"
                  className="form-control"
                  placeholder="Masukan 15 (g/L)   "
                  step="0.01"
                />
                {errors.dvs_15 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    15 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*    20 (g/L)    */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  20 (g/L)
                </label>
                <input
                  ref={register()}
                  name="dvs_20"
                  type="number"
                  className="form-control"
                  placeholder="Masukan  20 (g/L)"
                  step="0.01"
                />
                {errors.dvs_20 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    20 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*     25 (g/L)    */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  25 (g/L)
                </label>
                <input
                  ref={register()}
                  name="dvs_25"
                  type="number"
                  className="form-control"
                  placeholder="Masukan   25 (g/L)"
                  step="0.01"
                />
                {errors.dvs_25 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    25 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*      Dosis rata-rata (g/L)    */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Dosis rata-rata (g/L)
                </label>
                <input
                  ref={register()}
                  name="average_dosis"
                  type="number"
                  className="form-control"
                  placeholder="Masukan    Dosis rata-rata (g/L)"
                  step="0.01"
                />
                {errors.average_dosis && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Dosis rata-rata (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*  Petunjuk Penggunaan  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Petunjuk Penggunaan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.use_instruction}
                  onChange={(event, editor) => {
                    const dataCk = editor.getData();
                    setValue('use_instruction', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.use_instruction && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gejala wajib diisi
                  </div>
                )}
              </div>

              {/*  Tingkat Berbahaya  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Tingkat Berbahaya
                </label>
                <Select
                  options={danger}
                  closeMenuOnSelect={false}
                  value={opsDanger}
                  onChange={(params) => {
                    setOpsDanger(params);
                    selectValue(params, 'danger');
                  }}
                />
              </div>

              {/*   Tindak Pencegahan   */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Tindak Pencegahan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.precaution}
                  onChange={(event, editor) => {
                    const dataCk = editor.getData();
                    setValue('precaution', dataCk);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.precaution && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Tindak Pencegahan wajib diisi
                  </div>
                )}
              </div>

              {/* tombol */}
              <div className="col-md-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Tambah Artikel"
                />
              </div>
            </div>
          </form>
        </Card>
      </>
    );
  };

  const plantView = () => {
    const months = [
      { value: 'januari', label: 'Januari' },
      { value: 'februari', label: 'Februari' },
      { value: 'maret', label: 'Maret' },
      { value: 'april', label: 'April' },
      { value: 'mei', label: 'Mei' },
      { value: 'juni', label: 'Juni' },
      { value: 'juli', label: 'Juli' },
      { value: 'agustus', label: 'Agustus' },
      { value: 'september', label: 'September' },
      { value: 'oktober', label: 'Oktober' },
      { value: 'november', label: 'November' },
      { value: 'desember', label: 'Desember' },
    ];

    const planting = [
      { value: 'penyemaian', label: 'Penyemaian' },
      { value: 'tanam Biji', label: 'Tanam Biji' },
      { value: 'bibit', label: 'Bibit' },
    ];

    const location = [
      { value: 'langsung tanam', label: 'Langsung Tanam' },
      { value: 'pindah tanam', label: 'Pindah tanam' },
      { value: 'greenhouse', label: 'Greenhouse' },
      { value: 'jendela', label: 'Jendela' },
    ];

    return (
      <>
        {/* Data Tanaman */}
        <Card title="Form Tanaman">
          <form onSubmit={handleSubmit(updateData)}>
            <div className="row row-decks row-cards">
              <input ref={register} name="menu" type="hidden" value="plant" />

              {/* nama tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Tanaman
                </label>
                <input
                  ref={register({ required: true })}
                  name="plants_name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama tanaman"
                />
                {errors.plants_name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* nama ilmiah tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Ilmiah Tanaman
                </label>
                <input
                  ref={register({ required: true })}
                  name="scientific_name"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama ilmiah tanaman"
                />
                {errors.scientific_name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama ilmiah tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* Family */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Family
                </label>
                <input
                  ref={register({ required: true })}
                  name="family"
                  type="text"
                  className="form-control"
                  placeholder="Masukan keluarga tanaman"
                />
                {errors.family && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama keluarga wajib diisi
                  </div>
                )}
              </div>

              {/* Genus */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Genus
                </label>
                <input
                  ref={register({ required: true })}
                  name="genus"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Genus tanaman"
                />
                {errors.genus && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama genus wajib diisi
                  </div>
                )}
              </div>

              {/*  Negara Asal  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Negara Asal
                </label>
                <input
                  ref={register({ required: true })}
                  name="country_origin"
                  type="text"
                  className="form-control"
                  placeholder="Masukan  Negara Asal  tanaman"
                />
                {errors.country_origin && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama Negara Asal wajib diisi
                  </div>
                )}
              </div>

              {/*  Estimasi Panen (HST)  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Estimasi Panen (HST)
                </label>
                <input
                  ref={register({ required: true })}
                  name="harvest_est"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Estimasi Panen (HST) tanaman"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama Estimasi Panen (HST) wajib diisi
                  </div>
                )}
              </div>
              <hr />

              {/* Suhu */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="min_temperature"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_temperature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Suhu wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Suhu °C
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="max_temperature"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_temperature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Suhu wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kelembaban */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="min_humidity"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_humidity && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Kelembaban wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kelembaban %
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="max_humidity"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_humidity && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kelembaban wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kelembaban Tanah % */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="min_soil"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_soil && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Kelembaban Tanah % wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kelembaban Tanah %
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="max_soil"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_soil && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kelembaban Tanah % wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Nutrisi Tanah */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="min_nutrient"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_nutrient && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Nutrisi Tanah Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Nutrisi Tanah
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="max_nutrient"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_nutrient && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Nutrisi Tanah Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Cahaya */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="min_light"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_light && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Cahaya
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="max_light"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_light && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />
              {/* Waktu penyemaian */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Penyemaian
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeedingMonth}
                  onChange={(OpsSeedingMonth) => {
                    setOpsSeedingMonth(OpsSeedingMonth);
                    selectValue(OpsSeedingMonth, 'seeding_month');
                  }}
                />
              </div>

              {/* Waktu Pemeliharaan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Pemeliharaan
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsCultivationMonth}
                  onChange={(OpsCultivationMonth) => {
                    setOpsCultivationMonth(OpsCultivationMonth);
                    selectValue(OpsCultivationMonth, 'cultivation_month');
                  }}
                />
              </div>

              {/* Waktu Panen */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Panen
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsHarvestMonth}
                  onChange={(OpsHarvestMonth) => {
                    setOpsHarvestMonth(OpsHarvestMonth);
                    selectValue(OpsHarvestMonth, 'harvest_month');
                  }}
                />
              </div>

              {/* Bagian yang dipanen  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Bagian yang dipanen
                </label>
                <input
                  ref={register()}
                  name="harvested_part"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Bagian yang dipanen "
                />
              </div>

              {/*  Cara Penanaman  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Cara Penanaman
                </label>
                <Select
                  options={planting}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeeding}
                  onChange={(OpsSeeding) => {
                    setOpsSeeding(OpsSeeding);
                    selectValue(OpsSeeding, 'seeding');
                  }}
                />
              </div>

              {/* Lokasi Penanaman? */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Lokasi Penanaman
                </label>
                <Select
                  options={location}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeedingPlace}
                  onChange={(OpsSeedingPlace) => {
                    setOpsSeedingPlace(OpsSeedingPlace);
                    selectValue(OpsSeedingPlace, 'seeding_place');
                  }}
                />
              </div>

              {/* Kemudaahan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kemudahan Perawatan Tanaman
                </label>
                <input
                  ref={register()}
                  name="ease_care"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kemudahan Perawatan Tanaman "
                />
              </div>

              {/*  Toleransi Terhadap  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Toleransi Terhadap
                </label>
                <input
                  ref={register()}
                  name="tolerance"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Toleransi Terhadap "
                />
              </div>

              {/*   Kebutuhan Air   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kebutuhan Air
                </label>
                <input
                  ref={register()}
                  name="water_needs"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kebutuhan Air "
                />
              </div>

              {/*   Volume Pot   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Volume Pot
                </label>
                <input
                  ref={register()}
                  name="pot_volume"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Volume Pot "
                />
              </div>

              {/*   Populasi dalam 1 (m²)   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Populasi dalam 1 (m²)
                </label>
                <input
                  ref={register()}
                  name="population"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Populasi dalam 1 (m²) "
                />
              </div>

              {/* Lama Perkecambahan Benih (hari) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_germination"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_germination && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Lama Perkecambahan Benih (hari) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Lama Perkecambahan Benih (hari)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_germination"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_germination && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Lama Perkecambahan Benih (hari) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Lama Hari Hingga Dewasa (hari) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_mature"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_mature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Lama Hari Hingga Dewasa (hari) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Lama Hari Hingga Dewasa (hari)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_mature"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_mature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Lama Hari Hingga Dewasa (hari) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Penyemaian Benih (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_seed_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_seed_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Penyemaian Benih (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Penyemaian Benih (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_seed_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_seed_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Penyemaian Benih (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Penanaman (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_planting_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_planting_row_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Penanaman (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_planting_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_planting_row_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Baris Tanam (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_planting_row_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Baris Tanam (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Baris Tanam (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_planting_row_distance"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Baris Tanam (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kedalaman Penanaman (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="min_plant_depth"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Min"
                    />
                    {errors.min_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Kedalaman Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kedalaman Penanaman (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="max_plant_depth"
                      type="number"
                      className="form-control"
                      step="0.01"
                      placeholder="Max"
                    />
                    {errors.max_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kedalaman Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />

              {/*  Lama Budidaya (hari)  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Lama Budidaya (hari)
                </label>
                <input
                  ref={register()}
                  name="calc_cultivation"
                  type="number"
                  className="form-control"
                  placeholder="Masukan  Lama Budidaya "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Lama Budidaya wajib diisi
                  </div>
                )}
              </div>

              {/*  Kebutuhan Air Total  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kebutuhan Air Total
                </label>
                <input
                  ref={register()}
                  name="calc_water_needs"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kebutuhan Air Total "
                  step="0.1"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kebutuhan Air Total wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Kc-In
                </label>
                <input
                  ref={register()}
                  name="calc_kc_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-In "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kc-In wajib diisi
                  </div>
                )}
              </div>
              {/*  Kc-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Kc-Dev
                </label>
                <input
                  ref={register()}
                  name="calc_kc_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Dev "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kc-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KcMid
                </label>
                <input
                  ref={register()}
                  name="calc_kc_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Mid "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KcMid wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KcLate
                </label>
                <input
                  ref={register()}
                  name="calc_kc_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Late  "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KcLate wajib diisi
                  </div>
                )}
              </div>

              {/*  L-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-In
                </label>
                <input
                  ref={register()}
                  name="calc_l_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-In "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-In wajib diisi
                  </div>
                )}
              </div>
              {/*  L-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Dev
                </label>
                <input
                  ref={register()}
                  name="calc_l_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Dev "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  L-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Mid
                </label>
                <input
                  ref={register()}
                  name="calc_l_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Mid "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Mid wajib diisi
                  </div>
                )}
              </div>

              {/*  L-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Late
                </label>
                <input
                  ref={register()}
                  name="calc_l_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Late  "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Late wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-In
                </label>
                <input
                  ref={register()}
                  name="calc_kai_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-In "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-In wajib diisi
                  </div>
                )}
              </div>
              {/*  KAI-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Dev
                </label>
                <input
                  ref={register()}
                  name="calc_kai_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Dev "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Mid
                </label>
                <input
                  ref={register()}
                  name="calc_kai_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Mid "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Mid wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Late
                </label>
                <input
                  ref={register()}
                  name="calc_kai_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Late  "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Late wajib diisi
                  </div>
                )}
              </div>

              <hr />

              {/*  Catatan Tambahan  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Catatan Tambahan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.note}
                  onChange={(event, editor) => {
                    const dataCK = editor.getData();
                    setValue('note', dataCK);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>

              {/*   Keterangan Tanaman   */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Keterangan Tanaman
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data.description}
                  onChange={(event, editor) => {
                    const dataCK = editor.getData();
                    setValue('description', dataCK);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>

              {/* preview & input gambar */}
              {imagePreview ? (
                <img className="col-md-12 mx-auto d-block" alt="pic preview" src={imagePreview} />
              ) : null}

              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Gambar
                </label>
                <input
                  ref={register}
                  name="plantImage"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gambar tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* tombol */}
              <div className="col-md-12">
                <input
                  className="btn btn-primary px-5 btn-block"
                  type="submit"
                  value="Ubah Tanaman"
                />
              </div>
            </div>
          </form>
        </Card>
      </>
    );
  };

  // const betaView = () => (
  //   <>
  //     <Card className="d-flex align-items-center py-1">
  //       <p className="text-red">Under Construction</p>
  //     </Card>
  //   </>
  // );

  const handleView = () => {
    switch (menu) {
      case 'article':
        return articleView();

      case 'plant':
        return plantView();

      case 'disease':
        return diseaseView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">
        {/* content */}
        {isLoading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
}

export default AgricultureEdit;
