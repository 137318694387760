import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import useUserStore from "./stores/Users";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingView from "./components/Loading";

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/dashboard';
import Farm from './pages/farm';
import FarmCreate from './pages/farm/FarmCreate';
import FarmDetail from './pages/farm/FarmDetail';
import Farmer from './pages/farmer';
import FarmerCreate from './pages/farmer/FarmerCreate';
import FarmerEdit from './pages/farmer/FarmerEdit';
import FarmerShow from './pages/farmer/FarmerShow';
import Growth from './pages/agriculture/growth';
import Agriculture from './pages/agriculture';
import AgricultureAdd from './pages/agriculture/AgricultureAdd';
import AgricultureShow from './pages/agriculture/AgricultureShow';
import AgricultureEdit from './pages/agriculture/AgricultureEdit';
import Notification from './pages/notification';
import NotificationAdd from './pages/notification/NotificationAdd';
import NotificationEdit from './pages/notification/NotificationEdit';
import Issues from './pages/issues';
import Report from './pages/report';
import Npk from './pages/agriculture/npk';
import Config from './pages/config';
import Warehouse from './pages/warehouse';
import Password from './pages/user/password';
import Kelana from './pages/kelana';
import KelanaData from './pages/kelana/KelanaData';
import KelanaCreateFarm from './pages/kelana/kelanaCreateFarm';
import KelanaEditFarm from './pages/kelana/kelanaEditFarm';
import KelanaAnalytics from './pages/kelana/KelanaAnalytics';
import KelanaHarvestPlant from './pages/kelana/kelanaHarvestPlant';

// report
import FarmShow from "./pages/farm/FarmShow";
import FarmEdit from "./pages/farm/FarmEdit";


function App() {
  // state
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const AGRONOMIS = 6;
  const ENGINEER = 7;
  const loading = JSON.parse(useUserStore((state) => state.loading));
  const token = useUserStore((state) => state.token);
  const users = useUserStore((state) => state.user);
  const refreshSession = useUserStore((state) => state.refreshSession);
  let dataShow;

  // component mount and unmount
  useEffect(() => {
    refreshSession();
  }, [refreshSession]);

  // view
  const hasAuthView = () => (
    <div className="page">
      <Navbar />
      <div className="content">
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/password">
            <Password />
          </Route>
          <Route exact path="/farm">
            <Farm />
          </Route>
          <Route exact path="/farm/create">
            <FarmCreate />
          </Route>
          {/* editan hasani */}
          <Route path="/farm/:farmId/detail/:reportId/preview">
            <FarmShow />
          </Route>
          <Route path="/farm/:farmId/detail/:reportId/edit">
            <FarmEdit />
          </Route>
          {/* end editan hasani */}
          <Route path="/farm/:farmId/detail">
            <FarmDetail />
          </Route>
          <Route exact path="/farmer">
            <Farmer />
          </Route>
          <Route exact path="/farmer/:id/edit">
            <FarmerEdit />
          </Route>
          <Route exact path="/farmer/:id/show">
            <FarmerShow />
          </Route>
          <Route exact path="/farmer/create">
            <FarmerCreate />
          </Route>
          <Route exact path="/npk">
            <Npk />
          </Route>
          <Route exact path="/agriculture/:page">
            {users.status === ADMIN || users.status === AGRONOMIS ? (
              <Agriculture />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/agriculture/growth/:id/:option">
            {users.status === ADMIN || users.status === AGRONOMIS ? (
              <Growth />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/agriculture/:menu/create">
            {users.status === ADMIN || users.status === AGRONOMIS ? (
              <AgricultureAdd />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/agriculture/:menu/:id/show">
            {users.status === ADMIN || users.status === AGRONOMIS ? (
              <AgricultureShow />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/agriculture/:menu/:id/edit">
            {users.status === ADMIN || users.status === AGRONOMIS ? (
              <AgricultureEdit />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/notification">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <Notification />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/notification/create">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <NotificationAdd />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/notification/:id/edit">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <NotificationEdit />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/issues">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <Issues />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/report">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <Report />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/warehouse">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <Warehouse />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/config">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ? (
              <Config />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ||
              users.status === OWNER ? (
              <Kelana />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana/:id/data">
            {users.status === ADMIN ||
              users.status === AGRONOMIS ||
              users.status === ENGINEER ||
              users.status === OWNER ? (
              <KelanaData />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana/:id/edit">
            {users.status === ADMIN || users.status === AGRONOMIS || users.status === ENGINEER ? (
              <KelanaEditFarm />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana/:id/analytics">
            {users.status === ADMIN || users.status === AGRONOMIS || users.status === ENGINEER ||
              users.status === OWNER ? (
              <KelanaAnalytics />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana/create">
            {users.status === ADMIN || users.status === AGRONOMIS || users.status === ENGINEER ? (
              <KelanaCreateFarm />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/kelana/:id/harvest">
            {users.status === ADMIN || users.status === AGRONOMIS || users.status === ENGINEER ||
              users.status === OWNER ? (
              <KelanaHarvestPlant />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
    </div>
  );

  const authView = () => (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <Redirect to="/" />
    </Switch>
  );

  if (loading) {
    dataShow = <LoadingView />;
  } else if (token) {
    dataShow = hasAuthView();
  } else {
    dataShow = authView();
  }

  return dataShow;
}

export default App;
