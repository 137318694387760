import React from 'react';
import PropTypes from 'prop-types';
import DropdownItem from './DropdownItem';

function Dropdown({items, name, url}) {
  return (
    <div className="text-end">
      <span className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle align-text-top"
          data-bs-boundary="viewport"
          data-bs-toggle="dropdown">
          {name}
        </button>

        <div className="dropdown-menu dropdown-menu-end">
          {items.map(item => (
            <DropdownItem
              label={item.label}
              url={`${url}/${item.action}`}
            />
          ))}
        </div>
      </span>
    </div>
  );
}

Dropdown.propTypes = {
  items: PropTypes.objectOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Dropdown;
