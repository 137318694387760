/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import ReactExport from "react-export-excel";
import moment from "moment";
import { awsKelanaAPI, newAPI } from "../../services/baseAPI";
import Icon from "../../components/Icon";
import useUserStore from "../../stores/Users";
import Card from "../../components/SimpleCard";
import LoadingView from "../../components/Loading";
import { HabibiTable, SelectColumnFilter } from "../../components/HabibiTable";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

function KelanaHarvestPlant() {
  const history = useHistory();
  const token = useUserStore((state) => state.token);

  // State
  const [data, setData] = useState(null);
  const [plant, setPlant] = useState({});
  const [selectPlant, setSelectPlant] = useState({
    value: 0,
    label: `Pilih Tanaman`,
  });
  const [excelRSC, setExcelRSC] = useState([]);
  const [opsRSC, setOpsRSC] = useState([]);

  const [excelGrowth, setExcelGrowth] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [RSCEdit, SetRSCEdit] = useState([]);
  const [RSCDate, setRSCDate] = useState([]);
  // Request Data
  const getDataPlantsBatch = useCallback(
    (kelanaid) =>
      newAPI
        .get(`/kelana/${kelanaid}/plants`)
        .then((res) => res.data)
        .catch((error) => console.log(`error ${error}`)),
    []
  );

  const fetchFarm = useCallback(async () => {
    setLoading(true);
    await awsKelanaAPI
      .get(`/${id}`)
      .then(async (res) => {
        const dataFarm = res.data.Item;
        const plantData = dataFarm.plant_data;
        const plantKey = [...new Set(plantData.map((e) => e.plant_id))].join();
        const dataPlantsBatch = await getDataPlantsBatch(plantKey);
        const newDataPlant = plantData.map((e) => ({
          ...e,
          plantData: dataPlantsBatch.find((f) => f.id === e.plant_id),
        }));
        setData({ ...dataFarm, plant_data: newDataPlant });
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchFarm();
  }, []);

  // Controller
  const handlePlant = (keyPlant) => {
    setPlant(data.plant_data.find((e) => e.key_plant === keyPlant));
  };

  // View
  const HeaderView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            type="button"
            onClick={() => history.push("/kelana")}
            className="col-md-2"
            style={{
              marginLeft: "8px",
              marginRight: "10px",
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: "rgba(255, 0, 0, 0)",
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Kelana Prediksi Panen</h2>
          </div>
        </div>
      </div>
    </>
  );

  const KelanaHarvestView = () => (
    <div className="row row-deck row-cards">
      <Card title="Pilih Tanaman" styleBody={{ padding: 0, paddingTop: 20 }}>
        {/* Pilih Device */}
        <div className="col-md-6" style={{ marginBottom: 20 }}>
          <label htmlFor="true" className="form-label">
            Tanaman Fase (Tanggal Penanaman)
          </label>
          <Select
            options={
              data
                ? data.plant_data.map((e) => ({
                    value: e.key_plant,
                    label: `${e.plantData.plants_name} ${e.growth_data[0]?.data.growthPhase  || ''} (${e.planted_date})`,
                  }))
                : []
            }
            placeholder="Pilih perangkat"
            onChange={(e) => {
              setSelectPlant(e);
              handlePlant(e.value);
            }}
            value={selectPlant}
          />
        </div>
        {/* Tabel */}
        <div className="col-12" style={{ marginBottom: 20 }}>
          <div className="table-responsive">
            <table className="table table-vcenter card-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Kebun</th>
                  <th>Tanaman</th>
                  <th>Tanggal Panen</th>
                  {/* <th>Umur Tanam</th> */}
                  <th>Frekuensi Panen</th>
                  <th>Estimasi Panen (Kg)</th>
                  <th>Panen (Kg)</th>
                  <th>Gap</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(plant).length !== 0 && (
                 plant.harvestData?.map((harvest, harvestIdx) =>(
                  <tr>
                    <td>{harvestIdx+1}</td>
                    <td>{data.farm_name}</td>
                    <td>{plant.plantData.plants_name}</td>
                    <td>{moment(harvest.harvestDate).format('DD/MM/YYYY')}</td>
                    {/* <td>{moment(harvest.harvestDate).diff(moment(harvest.plantedDate), 'days')}</td> */}
                    <td>{plant.frequentHarvest}</td>
                    <td>{(plant.population * plant.harvest / 1000) || 0}</td>
                    <td>{harvest.harvest}</td>
                    <td>{(((harvest.harvest / (plant.population * plant.harvest / 1000))-1)* 100 ).toFixed(0)  || 0}%</td>
                    {/* <td>
                      {harvestIdx === 0 ? (((harvest.harvest / (plant.population * plant.harvest / 1000))-1) * 100 )  :  (((harvest.harvest / (plant.population * plant.harvest / 1000))-1) * 100 )}
                    </td> */}
                </tr>
                 ))
                )}
                
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <div className="container-xl">
      {/* header */}
      <HeaderView />
      {/* content */}
      {!loading ? <KelanaHarvestView /> : <LoadingView />}
    </div>
  );
}

export default KelanaHarvestPlant;
