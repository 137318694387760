import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, Route } from 'react-router-dom';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

// Content
import StockIn from './gudang/StockIn';

function Warehouse() {

  const [isLoading, setIsLoading] = useState(true);
  const { page } = useParams();
  const [menu, setMenu] = useState('ticket');
  const [menuNext, setMenuNext] = useState();
  const [menuAdd, setMenuAdd] = useState();

  const fetchWarehouse = useCallback(async () => {

  })

  const fetchProduction = useCallback(async () => {

  })

  const menuStyle = {
    // margin: "1rem",
    textAlign: "left",
    color: 'white',
    backgroundColor: "#94bbf5"
  };

  useEffect(() => {
    setMenu(page);

    switch (page) {
      case 'ticket':
        fetchProduction();
        break;
      case 'device':
        setMenuNext('listdev');
        fetchProduction();
        break;
      case 'tool':
        fetchWarehouse();
        break;
      case 'inventory':
        setMenuNext('stock');
        fetchWarehouse();
        break;
      
      default:
        break;
    }
  }, [page])

  const headerView = () => {
    let name = '';
    let name2 = '';
    if (menu === 'ticket') name = 'Ticketing';
    if (menu === 'device') name = 'Device';
    if (menu === 'tool') name = 'Alat & Komponen';
    if (menu === 'inventory') name = 'Inventori';
    if (menuNext === 'listdev') name2 = 'List Device';
    if (menuNext === 'devset') name2 = 'Device Setting';
    if (menuNext === 'stock') name2 = 'Stok';
    if (menuNext === 'stockin') name2 = 'Stok Masuk';
    if (menuNext === 'stockout') name2 = 'Stok Keluar';
    if (menuNext === 'stockop') name2 = 'Stok Opname';
    return (
      <>
        <div className="page-header">
          <div className="row align-items-center">
            {/* Title */}
            <div className="col">
              <div className="page-pretitle">Menu</div>
              <h2 className="page-title">
                <span>Warehouse</span>
                <span className="mx-2"> / </span>
                <span>{name}</span>
                {menu !== "ticket" && menu !== "tool" ? (
                  <>
                  <span className="mx-2"> / </span>
                  <span>{name2}</span>
                  </>
                ) : null}
              </h2>
            </div>

            {/* Action Button */}
            {menuAdd === "stockin" ? (
              <div className="col-auto ml-auto d-print-none">
                <Link className="btn btn-gray" to='#belum'>
                  <div className="d-flex align-items-center">
                    <Icon name="PlusCircle" size={16} style={{ marginRight: 5 }} />{' '}
                    <span>Tambah {name2}</span>
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  };

  const sidebarView = () => (
    <>
    <div className="col-md-3">
      <div className="row row-deck row-cards">
        <div className="col-12">
          <Route>
            <Card title="Menu" styleBody={{ padding: '12px', textAlign: 'center' }}>
              <h2 style={{ textAlign: "left" }}>Produksi</h2>
                <div className="col-md-12">
                  <>
                    {/* Production Navigation */}
                      <Link
                        to="/warehouse/ticket"
                        className={`btn my-1 w-100 ${menu === 'ticket' ? 'btn-blue' : ''}`}
                      >
                        <Icon name="Clipboard" size={20} style={{ marginRight: 5 }} />{' '}
                        Ticketing
                      </Link>
                      <Link
                        to="/warehouse/device"
                        className={`btn my-1 w-100 dropdown-toggle ${menu === 'device' ? 'btn-blue' : ''}`}
                      >
                        <Icon name="Server" size={20} style={{ marginRight: 5 }} />{' '}
                        Device
                      </Link>

                      {menu === 'device' ? (
                        <>
                          <div className="list-group">
                            <button type="button" className="list-group-item list-group-item-action" style={menuNext === "listdev" ? menuStyle : {textAlign: "left"}} onClick={() => setMenuNext('listdev')}>List Device</button>
                            <button type="button" className="list-group-item list-group-item-action" style={menuNext === "devset" ? menuStyle : {textAlign: "left"}} onClick={() => setMenuNext('devset')}>Device Setting</button>
                          </div>
                        </>
                      ) : null}
                  </>
                </div>
              <hr />
              <h2 style={{ textAlign: "left" }}>Gudang</h2>
                <div className="col-md-12">
                  <>
                    {/* Warehouse Navigation */}
                    <Link
                      to="/warehouse/tool"
                      className={`btn my-1 w-100 ${menu === 'tool' ? 'btn-blue' : ''}`}
                    >
                      <Icon name="Tool" size={20} style={{ marginRight: 5 }} />{' '}
                      Alat & Komponen
                    </Link>
                    <Link
                      to="/warehouse/inventory"
                      className={`btn my-1 w-100 dropdown-toggle ${menu === 'inventory' ? 'btn-blue' : ''}`}
                    >
                      <Icon name="Package" size={20} style={{ marginRight: 5 }} />{' '}
                      Inventori
                    </Link>
                    {/* <button 
                      type="button" 
                      className={`btn my-1 w-100 dropdown-toggle ${menu === 'invent' ? 'btn-blue' : ''}`} 
                      onClick={() => {
                        setMenu('invent')
                      }}
                    >
                      <Icon name="Package" size={20} style={{ marginRight: 5 }} />{' '}
                      Inventori
                    </button> */}

                    {menu === 'inventory' ? (
                      <>
                        <div className="list-group">
                          <button type="button" className="list-group-item list-group-item-action" style={menuNext === "stock" ? menuStyle : {textAlign: "left"}} onClick={() => setMenuNext('stock')}>Stok</button>
                          <button type="button" className="list-group-item list-group-item-action" style={menuNext === "stockin" ? menuStyle : {textAlign: "left"}} onClick={() => {
                            setMenuNext('stockin');
                            setMenuAdd('stockin');
                            }}
                          >
                            Stok Masuk
                          </button>
                          <button type="button" className="list-group-item list-group-item-action" style={menuNext === "stockout" ? menuStyle : {textAlign: "left"}} onClick={() => setMenuNext('stockout')}>Stok Keluar</button>
                          <button type="button" className="list-group-item list-group-item-action" style={menuNext === "stockop" ? menuStyle : {textAlign: "left"}} onClick={() => setMenuNext('stockop')}>Stok Opname</button>
                        </div>
                      </>
                    ) : null}
                  </>
                </div>
            </Card>
          </Route>
        </div>
      </div>
    </div>
    
    </>
  )

  const tabTicketing = () => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards">
          {/* Content */}
          <Card>
            <p>heh</p>
          </Card>
          {/* {menu === "ticket" ? <StockIn /> : <></>} */}
        </div>
      </div>
    </>
  )

  const tabDevice = () => (
    <>
      <div className="col-md-9">
        <div className="row row-deck row-cards">
          {/* Content */}
          <Card>
            <p>heh</p>
          </Card>
          {/* {menu === "ticket" ? <StockIn /> : <></>} */}
        </div>
      </div>
    </>
  )

  // console.log(menuNext === 'stockin' ? true : <></>);
  const tabInventory = () => (
    <>
      <div className="col-md-9">
          {/* Content */}          
          {menuNext === 'stockin' ? <StockIn /> : <></>}
      </div>
    </>
  )

  const handleView = () => {
    switch (page) {
      default:
        tabTicketing();
        break;
      case 'ticket':
        return tabTicketing();
      case 'device':
        return tabDevice();
      case 'inventory':
        return tabInventory();
    }
    return""
  }

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">
        {sidebarView()}

        {/* Content */}
        {/* {isLoading ? (
          <div className="col-md-9">
            <LoadingView />
          </div>
        ) : (
          )} */}
          {handleView()}
      </div>
    </div>
  );

}

export default Warehouse;