import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import Axios from 'axios';
import Swal from 'sweetalert2';

import useUserStore from '../../stores/Users';
import { newAPI, oldAPI } from '../../services/baseAPI';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import Icon from '../../components/Icon';

function AgricultureAdd() {
  // State & Fetch
  const { menu, id, option } = useParams();
  const history = useHistory();
  const token = useUserStore((state) => state.token);
  const { register, errors, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);

  // Plant ops
  const [opsSeedingMonth, setOpsSeedingMonth] = useState(null);
  const [opsHarvestMonth, setOpsHarvestMonth] = useState(null);
  const [opsCultivationMonth, setOpsCultivationMonth] = useState(null);
  const [opsSeeding, setOpsSeeding] = useState(null);
  const [opsSeedingPlace, setOpsSeedingPlace] = useState(null);

  // disease ops
  const [dataOption, setDataOption] = useState([]);
  const [opsHpt, setOpsHpt] = useState(null);
  const [opsUnit, setOpsUnit] = useState(null);
  const [opsDanger, setOpsDanger] = useState(null);
  const [opsPlant, setOpsPlant] = useState(null);

  // growth ops
  const [opsFarm, setOpsFarm] = useState(null);
  const [tumbuhan, setTumbuhan] = useState(null);
  const [konversi, setKonversi] = useState(null);
  const [parameter, setParameter] = useState([]);
  const [dataFarm, setDataFarm] = useState([]);

  // growth
  const [dataParameter, setDataParameter] = useState([]);

  useEffect(() => {
    register('articleContent');

    // Plant
    register('plantDescription');
    register('plantNote');
    register('plantSeeding_month');
    register('plantHarvest_month');
    register('plantCultivation_month');
    register('plantSeeding');
    register('plantSeeding_place');

    // disease
    register('diseaseHpt');
    register('diseasePlant');
    register('diseaseDesc');
    register('diseaseTreatment');
    register('diseaseSymptoms');
    register('diseaseActive_ingredient');
    register('diseaseUnit');
    register('diseaseUse_instruction');
    register('diseaseDanger');
    register('diseasePrecaution');

    // growth
    register('growthFarm');
    register('growthPlant');
    register('growthNote');
    register('growthType');
  }, []);

  // insert data select to setValue
  const selectValue = (SelectedOption, nameValue) => {
    const value = SelectedOption.map((val) => val.value);
    setValue(nameValue, JSON.stringify(value));
  };

  // fectdata
  const fetchPlant = async () => {
    // setLoading(true);
    await oldAPI
      .get('/v2/pestdisease/create', { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const arrTmp = [];
        const plant = res.data.data;
        plant.forEach((item) =>
          arrTmp.push({
            value: item.id,
            label: item.plants_name,
          })
        );
        // setLoading(false);

        setDataOption(arrTmp);
      })
      .catch((error) => console.log(`error ${error}`));
  };

  const fetchFarm = async () => {
    // setLoading(true);
    // await oldAPI
    //   .get('v2/farms', { headers: { Authorization: `Bearer ${token}` } })
    //   .then((res) => {
    //     const arrTmp = [];
    //     const farm = res.data.data;
    //     farm.forEach((item) =>
    //       arrTmp.push({
    //         value: item.id,
    //         label: item.name,
    //       })
    //     );
    //     // setLoading(false);
    //     setDataFarm(arrTmp);
    //   })
    //   .catch((error) => console.log(`error ${error}`));

    await newAPI
      .get('/v3/farms', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const tmpFarms = [];
        const farms = res.data.data.last_data_device;

        farms.forEach((item) => {
          if (item.jenis_data === 'config') {
            tmpFarms.push({
              label: item.name,
              value: item.code_farm,
              id: item.id,
            });
          }
        });
        const sortFarms = tmpFarms.sort((a, b) => (a.label > b.label ? 1 : -1));
        setDataFarm(sortFarms);

      })
      .catch((error) => {
        console.log(`error ${error}`);
      });
  };

  const fetchGrowthData = async () => {
    await Axios.get(`http://localhost:3001/growth/${id}/add`)
      .then((res) => {
        const growth = res.data;
        setDataParameter(JSON.parse(growth[0].parameters));
      })
      .catch((error) => console.log(`error ${error}`));
  };

  useEffect(() => {
    switch (menu) {
      case 'disease':
        fetchPlant();
        break;
      case 'growth':
        switch (option) {
          case 'add':
            fetchGrowthData();
            break;
          case 'image':
            fetchGrowthData();
            break;
          default:
            fetchFarm();
            fetchPlant();
            break;
        }
        break;
      default:
        break;
    }
  }, []);

  // Controller
  const storeData = (params) => {
    setLoading(true);
    const body = new FormData();
    const formBody = new URLSearchParams();
    switch (params.menu) {
      case 'article':
        body.append('title', params.articleTitle);
        body.append('content', params.articleContent);
        body.append('category', params.articleCategory);
        body.append('image', image);
        newAPI
          .post('/articles', body, {
            headers: {
              // Accept: 'application/json',
              // Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
            Swal.fire('Changes are not saved', 'there is something wrong', 'error')
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Artikel berhasil ditambahkan', 'success')
          });
        break;
      case 'plant':
        body.append('name', params.plantName);
        body.append('scientific_name', params.plantNameScientific);
        body.append('family', params.plantFamily);
        body.append('genus', params.plantGenus);
        body.append('country_origin', params.plantCountry_origin);
        body.append('harvest_est', params.plantHarvest_est);
        body.append('n', params.plantn);
        body.append('p', params.plantp);
        body.append('k', params.plantk);

        body.append('min_temperature', params.plantMin_temperature);
        body.append('max_temperature', params.plantMax_temperature);
        body.append('min_humidity', params.plantMin_humidity);
        body.append('max_humidity', params.plantMax_humidity);
        body.append('min_soil', params.plantMin_soil);
        body.append('max_soil', params.plantMax_soil);
        body.append('min_nutrient', params.plantMin_nutrient);
        body.append('max_nutrient', params.plantMax_nutrient);
        body.append('min_light', params.plantMin_light);
        body.append('max_light', params.plantMax_light);
        body.append('min_ph', params.plantMin_ph);
        body.append('max_ph', params.plantMax_ph);
        body.append('min_moisture', params.plantMin_moisture);
        body.append('max_moisture', params.plantMax_moisture);

        body.append('seeding_month', params.plantSeeding_month);
        body.append('harvest_month', params.plantHarvest_month);
        body.append('cultivation_month', params.plantCultivation_month);
        body.append('seeding', params.plantSeeding);
        body.append('seeding_place', params.plantSeeding_place);

        body.append('harvested_part', params.plantHarvested_part);
        body.append('ease_care', params.plantEase_care);
        body.append('tolerance', params.plantTolerance);
        body.append('water_needs', params.plantWater_needs);
        body.append('pot_volume', params.plantPot_volume);
        body.append('population', params.plantPopulation);
        body.append('min_germination', params.plantMin_germination);
        body.append('max_germination', params.plantMax_germination);
        body.append('min_mature', params.plantMin_mature);
        body.append('max_mature', params.plantMax_mature);
        body.append('min_seed_distance', params.plantMin_seed_distance);
        body.append('max_seed_distance', params.plantMax_seed_distance);
        body.append('min_planting_distance', params.plantMin_planting_distance);
        body.append('max_planting_distance', params.plantMax_planting_distance);
        body.append('min_planting_row_distance', params.plantMin_planting_row_distance);
        body.append('max_planting_row_distance', params.plantMax_planting_row_distance);
        body.append('min_plant_depth', params.plantMin_plant_depth);
        body.append('max_plant_depth', params.plantMax_plant_depth);
        body.append('calc_cultivation', params.plantCalc_cultivation);
        body.append('calc_water_needs', params.plantCalc_water_needs);
        body.append('calc_kc_in', params.plantCalc_kc_in);
        body.append('calc_kc_dev', params.plantCalc_kc_dev);
        body.append('calc_kc_mid', params.plantCalc_kc_mid);
        body.append('calc_kc_late', params.calc_kc_late);
        body.append('calc_l_in', params.plantCalc_l_in);
        body.append('calc_l_dev', params.plantCalc_l_dev);
        body.append('calc_l_mid', params.plantCalc_l_mid);
        body.append('calc_l_late', params.calc_l_late);
        body.append('calc_kai_in', params.plantCalc_kai_in);
        body.append('calc_kai_dev', params.plantCalc_kai_dev);
        body.append('calc_kai_mid', params.plantCalc_kai_mid);
        body.append('calc_kai_late', params.calc_kai_late);

        body.append('produktifitas', params.plantProduktifitas);
        body.append('note', params.plantNote);
        body.append('description', params.plantDescription);

        body.append('image', image);

        newAPI
          .post('plants', body, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Tanaman berhasil ditambahkan', 'success');
          });
        break;
      case 'disease':
        body.append('data_tanaman', params.diseasePlant);
        body.append('name', params.diseaseName);
        body.append('desc', params.diseaseDesc);
        body.append('treatment', params.diseaseTreatment);
        body.append('scientific', params.diseaseScientific);
        body.append('hpt', params.diseaseHpt);
        body.append('symptoms', params.diseaseSymptoms);
        body.append('type_pesticide', params.diseaseType_pesticide);
        body.append('active_ingredient', params.diseaseActive_ingredient);
        body.append('dvs_0', params.diseaseDvs_0);
        body.append('dvs_15', params.diseaseDvs_15);
        body.append('dvs_20', params.diseaseDvs_20);
        body.append('dvs_25', params.diseaseDvs_25);
        body.append('average_dosis', params.diseaseAverage_dosis);
        body.append('use_instruction', params.diseaseUse_instruction);
        body.append('danger', params.diseaseDanger);
        body.append('precaution', params.diseasePrecaution);
        body.append('area', params.diseaseArea);
        body.append('unit', params.diseaseUnit);
        body.append('host', params.diseaseHost);
        body.append('image', image);

        newAPI
          .post('/v4/disease/', body, {
            headers: {
              // Accept: 'application/json',
              // Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Hama penyakit berhasil ditambahkan', 'success')
          });
        break;
      case 'growth':
        formBody.append('growthFarm', params.growthFarm);
        formBody.append('plants_id', params.growthPlant);
        formBody.append('note', params.growthNote);
        formBody.append('type', params.growthType);
        if (params.parameter) {
          formBody.append('parameters', tumbuhan.concat(params.parameter));
          formBody.append('convert', konversi.concat(params.convert));
        } else {
          formBody.append('parameters', tumbuhan);
          formBody.append('convert', konversi);
        }
        formBody.append('hst', params.hst);
        formBody.append('baris', params.baris);
        formBody.append('bedengan', params.bedengan);
        formBody.append('planted', params.planted);
        formBody.append('harvest', params.harvest);
        newAPI
          .post('/growth', formBody, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
          })
          .catch((error) => {
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Data pertumbuhan berhasil ditambahkan', 'success')
          });
        break;
      case 'growthadd':
        formBody.append('date', params.date);
        formBody.append('parameters', params.parameter);
        newAPI
          .post(`/growth/${id}/add`, formBody, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
          })
          .then((res) => {
            console.log(`berhasil upload ${JSON.stringify(res)}`);
            // alert('Berhasil');
          })
          .catch((error) => {
            // alert('Gagal');
            console.log(`error ${error}`);
          })
          .finally(() => {
            setLoading(false);
            Swal.fire('Saved!', 'Data pertumbuhan berhasil ditambahkan', 'success')
          });
        break;
      default:
        break;
    }
  };

  // View
  const headerView = () => {
    let name = '';
    if (menu === 'article') name = 'Artikel';
    if (menu === 'disease') name = 'Hama Penyakit';
    if (menu === 'growth') name = 'Data Pertumbuhan';
    if (menu === 'plant') name = 'Tanaman';
    if (menu === 'cultivation') name = 'Data Budidaya';

    return (
      <>
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <button
              aria-label="Back Button"
              type="button"
              onClick={() => history.push(`/agriculture/${menu}`)}
              className="col-md-2"
              style={{
                marginLeft: '8px',
                marginRight: '10px',
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: 'rgba(255, 0, 0, 0)',
                borderWidth: 0,
              }}
            >
              <Icon name="ArrowLeft" color="#333" size={20} />
            </button>
            <div className="col">
              <div className="page-pretitle">Tambah</div>
              <h2 className="page-title">{name}</h2>
            </div>
          </div>
        </div>
      </>
    );
  };

  const articleView = () => (
    <>
      {/* Data Artikel */}
      <Card title="Form Artikel">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="article" />

            {/* judul artikel */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Judul Artikel
              </label>
              <input
                ref={register({ required: true })}
                name="articleTitle"
                type="text"
                className="form-control"
                placeholder="Masukan judul artikel"
              />
              {errors.title && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Judul artikel wajib diisi
                </div>
              )}
            </div>

            {/* preview & input gambar */}
            {imagePreview ? (
              <img className="col-md-12 mx-auto d-block" alt="pic preview" src={imagePreview} />
            ) : null}

            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Gambar
              </label>
              <input
                ref={register}
                name="articleImage"
                type="file"
                className="form-control"
                placeholder="Masukan gambar"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setImagePreview(URL.createObjectURL(e.target.files[0]));
                }}
              />
              {errors.image && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Gambar artikel wajib diisi
                </div>
              )}
            </div>

            {/* kategori */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Kategori
              </label>
              <select
                ref={register}
                name="articleCategory"
                className="form-select form-control"
                defaultValue="DEFAULT"
              >
                <option value="DEFAULT" disabled>
                  Pilih salah satu
                </option>
                <option value="berita">Berita</option>
                <option value="budidaya">Budidaya</option>
                <option value="inspirasi">Inspirasi</option>
                <option value="teknologi">Teknologi</option>
                <option value="hama">Hama Penyakit</option>
                <option value="informasi">Informasi</option>
                <option value="slibmkg">SLI BMKG</option>
                <option value="lain-lain">Lain-Lain</option>
              </select>
              {errors.category && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Kategori wajib diisi
                </div>
              )}
            </div>

            {/* konten artikel */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Konten Artikel
              </label>
              <CKEditor
                editor={ClassicEditor}
                data="<p>Masukan konten disini</p>"
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue('articleContent', data);
                }}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                  ],
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3',
                      },
                    ],
                  },
                }}
              />
              {errors.content && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Kontent artikel wajib diisi
                </div>
              )}
            </div>

            {/* tombol */}
            <div className="col-md-12">
              <input className="btn w-100 btn-primary px-5" type="submit" value="Tambah Artikel" />
            </div>
          </div>
        </form>
      </Card>
    </>
  );

  const plantView = () => {
    const months = [
      { value: 'januari', label: 'Januari' },
      { value: 'februari', label: 'Februari' },
      { value: 'maret', label: 'Maret' },
      { value: 'april', label: 'April' },
      { value: 'mei', label: 'Mei' },
      { value: 'juni', label: 'Juni' },
      { value: 'juli', label: 'Juli' },
      { value: 'agustus', label: 'Agustus' },
      { value: 'september', label: 'September' },
      { value: 'oktober', label: 'Oktober' },
      { value: 'november', label: 'November' },
      { value: 'desember', label: 'Desember' },
    ];

    const planting = [
      { value: 'penyemaian', label: 'Penyemaian' },
      { value: 'tanam Biji', label: 'Tanam Biji' },
      { value: 'bibit', label: 'Bibit' },
    ];

    const location = [
      { value: 'langsung tanam', label: 'Langsung Tanam' },
      { value: 'pindah tanam', label: 'Pindah tanam' },
      { value: 'greenhouse', label: 'Greenhouse' },
      { value: 'jendela', label: 'Jendela' },
    ];

    return (
      <>
        {/* Data Artikel */}
        <Card title="Form Tanaman">
          <form onSubmit={handleSubmit(storeData)}>
            <div className="row row-decks row-cards">
              <input ref={register} name="menu" type="hidden" value="plant" />

              {/* nama tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Tanaman
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantName"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama tanaman"
                />
                {errors.plants_name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* nama ilmiah tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Ilmiah Tanaman
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantNameScientific"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama ilmiah tanaman"
                />
                {errors.scientific_name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama ilmiah tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* Family */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Family
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantFamily"
                  type="text"
                  className="form-control"
                  placeholder="Masukan keluarga tanaman"
                />
                {errors.family && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama keluarga wajib diisi
                  </div>
                )}
              </div>

              {/* Genus */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Genus
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantGenus"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Genus tanaman"
                />
                {errors.genus && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama genus wajib diisi
                  </div>
                )}
              </div>

              {/*  Negara Asal  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Negara Asal
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantCountry_origin"
                  type="text"
                  className="form-control"
                  placeholder="Masukan  Negara Asal  tanaman"
                />
                {errors.country_origin && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama Negara Asal wajib diisi
                  </div>
                )}
              </div>

              {/*  Estimasi Panen (HST)  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Estimasi Panen (HST)
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantHarvest_est"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Estimasi Panen (HST) tanaman"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nama Estimasi Panen (HST) wajib diisi
                  </div>
                )}
              </div>

              {/*  N  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  N
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantn"
                  type="number"
                  className="form-control"
                  placeholder="Masukan nilai N"
                />
                {errors.plantn && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nilai N wajib diisi
                  </div>
                )}
              </div>
              {/*  P  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  P
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantp"
                  type="number"
                  className="form-control"
                  placeholder="Masukan nilai n"
                />
                {errors.plantp && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nilai P wajib diisi
                  </div>
                )}
              </div>
              {/*  K  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  K
                </label>
                <input
                  ref={register({ required: true })}
                  name="plantk"
                  type="number"
                  className="form-control"
                  placeholder="Masukan nilai K"
                />
                {errors.plantk && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Nilai K wajib diisi
                  </div>
                )}
              </div>

              <hr />

              {/* Suhu */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_temperature"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_temperature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Suhu wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Suhu °C
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_temperature"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_temperature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Suhu wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kelembaban */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_humidity"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_humidity && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Kelembaban wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kelembaban %
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_humidity"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_humidity && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kelembaban wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kelembaban Tanah % */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_soil"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_soil && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama min Kelembaban Tanah % wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kelembaban Tanah %
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_soil"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_soil && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kelembaban Tanah % wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Nutrisi Tanah */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_nutrient"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_nutrient && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Nutrisi Tanah Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Nutrisi Tanah
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_nutrient"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_nutrient && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Nutrisi Tanah Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Cahaya */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_light"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_light && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Cahaya
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_light"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_light && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* pH */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_ph"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_ph && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      pH
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_ph"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_ph && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* moist */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMin_moisture"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_moisture && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Moisture
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register({ required: true })}
                      name="plantMax_moisture"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_moisture && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Cahaya Tanah wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />
              {/* Waktu penyemaian */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Penyemaian
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeedingMonth}
                  onChange={(OpsSeedingMonth) => {
                    setOpsSeedingMonth(OpsSeedingMonth);
                    selectValue(OpsSeedingMonth, 'plantSeeding_month');
                  }}
                />
              </div>

              {/* Waktu Pemeliharaan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Pemeliharaan
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsCultivationMonth}
                  onChange={(OpsCultivationMonth) => {
                    console.log(OpsCultivationMonth);
                    setOpsCultivationMonth(OpsCultivationMonth);
                    selectValue(OpsCultivationMonth, 'plantCultivation_month');
                  }}
                />
              </div>

              {/* Waktu Panen */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Waktu Panen
                </label>
                <Select
                  options={months}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsHarvestMonth}
                  onChange={(OpsHarvestMonth) => {
                    setOpsHarvestMonth(OpsHarvestMonth);
                    selectValue(OpsHarvestMonth, 'plantHarvest_month');
                  }}
                />
              </div>

              {/* Bagian yang dipanen  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Bagian yang dipanen
                </label>
                <input
                  ref={register()}
                  name="plantHarvested_part"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Bagian yang dipanen "
                />
              </div>

              {/*  Cara Penanaman  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Cara Penanaman
                </label>
                <Select
                  options={planting}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeeding}
                  onChange={(OpsSeeding) => {
                    setOpsSeeding(OpsSeeding);
                    selectValue(OpsSeeding, 'plantSeeding');
                  }}
                />
              </div>

              {/* Lokasi Penanaman? */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Lokasi Penanaman
                </label>
                <Select
                  options={location}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsSeedingPlace}
                  onChange={(OpsSeedingPlace) => {
                    setOpsSeedingPlace(OpsSeedingPlace);
                    selectValue(OpsSeedingPlace, 'plantSeeding_place');
                  }}
                />
              </div>

              {/* Kemudaahan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kemudahan Perawatan Tanaman
                </label>
                <input
                  ref={register()}
                  name="plantEase_care"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kemudahan Perawatan Tanaman "
                />
              </div>

              {/*  Toleransi Terhadap  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Toleransi Terhadap
                </label>
                <input
                  ref={register()}
                  name="plantTolerance"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Toleransi Terhadap "
                />
              </div>

              {/*   Kebutuhan Air   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Kebutuhan Air
                </label>
                <input
                  ref={register()}
                  name="plantWater_needs"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Kebutuhan Air "
                />
              </div>

              {/*   Volume Pot   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Volume Pot
                </label>
                <input
                  ref={register()}
                  name="plantPot_volume"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Volume Pot "
                />
              </div>

              {/*   Populasi dalam 1 (m²)   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Populasi dalam 1 (m²)
                </label>
                <input
                  ref={register()}
                  name="plantPopulation"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Populasi dalam 1 (m²) "
                />
              </div>

              {/* Lama Perkecambahan Benih (hari) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_germination"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_germination && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Lama Perkecambahan Benih (hari) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Lama Perkecambahan Benih (hari)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_germination"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_germination && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Lama Perkecambahan Benih (hari) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Lama Hari Hingga Dewasa (hari) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_mature"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_mature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Lama Hari Hingga Dewasa (hari) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Lama Hari Hingga Dewasa (hari)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_mature"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_mature && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Lama Hari Hingga Dewasa (hari) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Penyemaian Benih (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_seed_distance"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_seed_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Penyemaian Benih (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Penyemaian Benih (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_seed_distance"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_seed_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Penyemaian Benih (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Penanaman (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_planting_row_distance"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_planting_row_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Penanaman (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_planting_row_distance"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_planting_row_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Jarak Baris Tanam (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_planting_distance"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_planting_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Jarak Baris Tanam (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Jarak Baris Tanam (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_planting_distance"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_planting_distance && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Jarak Baris Tanam (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Kedalaman Penanaman (cm) */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMin_plant_depth"
                      type="number"
                      className="form-control"
                      placeholder="Min"
                    />
                    {errors.min_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Min Kedalaman Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="true" className="form-label text-center">
                      Kedalaman Penanaman (cm)
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      ref={register()}
                      name="plantMax_plant_depth"
                      type="number"
                      className="form-control"
                      placeholder="Max"
                    />
                    {errors.max_plant_depth && (
                      <div className="pt-1" style={{ color: 'red' }}>
                        Nama Max Kedalaman Penanaman (cm) wajib diisi
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />
              {/*  Lama Budidaya (hari)  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Lama Budidaya (hari)
                </label>
                <input
                  ref={register()}
                  name="plantCalc_cultivation"
                  type="number"
                  className="form-control"
                  placeholder="Masukan  Lama Budidaya "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Lama Budidaya wajib diisi
                  </div>
                )}
              </div>

              {/*  Kebutuhan Air Total  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Kebutuhan Air Total
                </label>
                <input
                  ref={register()}
                  name="plantCalc_water_needs"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kebutuhan Air Total "
                  step="0.1"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kebutuhan Air Total wajib diisi
                  </div>
                )}
              </div>

              {/*  Produktifitas  */}
              <div className="col-md-4">
                <label htmlFor="true" className="form-label">
                  Produktifitas
                </label>
                <input
                  ref={register()}
                  name="plantProduktifitas"
                  type="number"
                  className="form-control"
                  step="0.1"
                  placeholder="Masukan Produktifitas tanaman"
                />
                {errors.produktifitas && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Produktifitas wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Kc-In
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kc_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-In "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kc-In wajib diisi
                  </div>
                )}
              </div>
              {/*  Kc-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  Kc-Dev
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kc_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Dev "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Kc-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KcMid
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kc_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Mid "
                  step="0.01"
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KcMid wajib diisi
                  </div>
                )}
              </div>

              {/*  Kc-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KcLate
                </label>
                <input
                  ref={register()}
                  name="calc_kc_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan Kc-Late  "
                  step="0.01"
                />
                {errors.calc_kc_late && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KcLate wajib diisi
                  </div>
                )}
              </div>

              {/*  L-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-In
                </label>
                <input
                  ref={register()}
                  name="plantCalc_l_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-In "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-In wajib diisi
                  </div>
                )}
              </div>
              {/*  L-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Dev
                </label>
                <input
                  ref={register()}
                  name="plantCalc_l_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Dev "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  L-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Mid
                </label>
                <input
                  ref={register()}
                  name="plantCalc_l_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Mid "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Mid wajib diisi
                  </div>
                )}
              </div>

              {/*  L-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  L-Late
                </label>
                <input
                  ref={register()}
                  name="calc_l_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan L-Late  "
                />
                {errors.calc_l_late && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    L-Late wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-In  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-In
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kai_in"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-In "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-In wajib diisi
                  </div>
                )}
              </div>
              {/*  KAI-Dev  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Dev
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kai_dev"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Dev "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Dev wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-Mid  */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Mid
                </label>
                <input
                  ref={register()}
                  name="plantCalc_kai_mid"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Mid "
                />
                {errors.harvest_est && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Mid wajib diisi
                  </div>
                )}
              </div>

              {/*  KAI-Late   */}
              <div className="col-md-3">
                <label htmlFor="true" className="form-label">
                  KAI-Late
                </label>
                <input
                  ref={register()}
                  name="calc_kai_late"
                  type="number"
                  className="form-control"
                  placeholder="Masukan KAI-Late  "
                />
                {errors.calc_kai_late && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    KAI-Late wajib diisi
                  </div>
                )}
              </div>

              <hr />
              {/*  Catatan Tambahan  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Catatan Tambahan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan konten disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('plantNote', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>

              {/*   Keterangan Tanaman   */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Keterangan Tanaman
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan konten disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('plantDescription', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
              </div>

              {/* preview & input gambar */}
              {imagePreview ? (
                <img className="col-md-12 mx-auto d-block" alt="pic preview" src={imagePreview} />
              ) : null}

              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Gambar
                </label>
                <input
                  ref={register}
                  name="plantImage"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gambar tanaman wajib diisi
                  </div>
                )}
              </div>

              {/* tombol */}
              <div className="col-md-12">
                <input className="btn btn-primary px-5 btn-block" type="submit" value="Tambah" />
              </div>
            </div>
          </form>
        </Card>
      </>
    );
  };

  const diseaseView = () => {
    const hpt = [
      { value: 1, label: 'Hama' },
      { value: 2, label: 'Virus' },
      { value: 3, label: 'Jamur' },
      { value: 4, label: 'Bakteri' },
      { value: 5, label: 'Tular Tanah' },
    ];

    const unit = [
      { value: 'Kg/l', label: 'Kg/l' },
      { value: 'ml/l', label: 'ml/l' },
      { value: 'g/l', label: 'g/l' },
      { value: 'L/l', label: 'L/l' },
    ];
    const danger = [
      { value: 1, label: 'Cukup Berbahaya' },
      { value: 2, label: 'Sangat Berbahaya Sekali' },
      { value: 3, label: 'Berbahaya Sekali' },
      { value: 4, label: 'Berbahaya' },
      { value: 5, label: 'Tidak Berbahaya Pada Pemakaian Normal' },
    ];

    const plants = dataOption;

    return (
      <>
        {/* Data hama penyakit */}
        <Card title="Form Hama Penyakit">
          <form onSubmit={handleSubmit(storeData)}>
            <div className="row row-decks row-cards">
              <input ref={register} name="menu" type="hidden" value="disease" />

              {/* Nama */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Penyakit
                </label>
                <input
                  ref={register({ required: true })}
                  name="diseaseName"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama hama/penyakit"
                />
                {errors.name && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul nama hama/penyakit wajib diisi
                  </div>
                )}
              </div>

              {/* Nama Tanaman */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Tanaman
                </label>
                <Select
                  options={plants}
                  closeMenuOnSelect={false}
                  isMulti
                  value={opsPlant}
                  onChange={(params) => {
                    setOpsPlant(params);
                    selectValue(params, 'diseasePlant');
                  }}
                />
              </div>

              {/*  Tanaman Inang  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Tanaman Inang
                </label>
                <input
                  ref={register()}
                  name="diseaseHost"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama Tanaman Inang"
                />
                {errors.host && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul Tanaman Inang wajib diisi
                  </div>
                )}
              </div>

              {/*   Nama Ilmiah   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Nama Ilmiah
                </label>
                <input
                  ref={register()}
                  name="diseaseScientific"
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama Nama Ilmiah"
                />
                {errors.scientific && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Judul Nama Ilmiah wajib diisi
                  </div>
                )}
              </div>

              {/* preview & input gambar */}
              {imagePreview ? (
                <img className="col-md-12 mx-auto d-block" alt="pic preview" src={imagePreview} />
              ) : null}

              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gambar
                </label>
                <input
                  ref={register}
                  name="diseaseImage"
                  type="file"
                  className="form-control"
                  placeholder="Masukan gambar"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImagePreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {errors.image && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gambar artikel wajib diisi
                  </div>
                )}
              </div>

              {/* Deksripsi */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Deksripsi
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan Dekskripsi disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseaseDesc', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.desc && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Deskripsi wajib diisi
                  </div>
                )}
              </div>

              {/* Saran Perlakuan */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Saran Perlakuan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan Dekskripsi disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseaseTreatment', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.treatment && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Saran Perlakuan wajib diisi
                  </div>
                )}
              </div>

              <hr />

              {/* Jenis HPT */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Jenis HPT
                </label>
                <Select
                  options={hpt}
                  closeMenuOnSelect={false}
                  value={opsHpt}
                  onChange={(params) => {
                    console.log(params);
                    setOpsHpt(params);
                    setValue('diseaseHpt', params.value);

                  }}
                />
              </div>

              {/* Gejala */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Gejala
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan Gejala disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseaseSymptoms', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.symptoms && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gejala wajib diisi
                  </div>
                )}
              </div>

              <hr />
              {/*    Jenis Pestisida    */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Jenis Pestisida
                </label>
                <input
                  ref={register()}
                  name="diseaseType_pesticide"
                  type="text"
                  className="form-control"
                  placeholder="Masukan Jenis Pestisida"
                />
                {errors.type_pesticide && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Jenis Pestisida wajib diisi
                  </div>
                )}
              </div>

              {/*  Bahan Aktif  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Bahan Aktif
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan  Bahan Aktif  disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseaseActive_ingredient', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.active_ingredient && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Bahan Aktif wajib diisi
                  </div>
                )}
              </div>

              {/*     Luas Area(ha)     */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Luas Area(ha)
                </label>
                <input
                  ref={register()}
                  name="diseaseArea"
                  type="text"
                  className="form-control"
                  placeholder="Masukan  Luas Area(ha) "
                  step="0.01"
                />
                {errors.area && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Luas Area(ha) wajib diisi
                  </div>
                )}
              </div>

              {/* Satuan */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  Satuan
                </label>
                <Select
                  options={unit}
                  closeMenuOnSelect={false}
                  value={opsUnit}
                  onChange={(params) => {
                    setOpsUnit(params);
                    setValue('diseaseUnit', params.value);
                  }}
                />
              </div>

              {/*  0 (Kg/L)  */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  0 (Kg/L)
                </label>
                <input
                  ref={register()}
                  name="diseaseDvs_0"
                  type="number"
                  className="form-control"
                  placeholder="Masukan 0 (Kg/L)  "
                  step="0.01"
                />
                {errors.dvs_0 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    0 (Kg/L) wajib diisi
                  </div>
                )}
              </div>

              {/*   15 (g/L)   */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  15 (g/L)
                </label>
                <input
                  ref={register()}
                  name="diseaseDvs_15"
                  type="number"
                  className="form-control"
                  placeholder="Masukan 15 (g/L)   "
                  step="0.01"
                />
                {errors.dvs_15 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    15 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*    20 (g/L)    */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  20 (g/L)
                </label>
                <input
                  ref={register()}
                  name="diseaseDvs_20"
                  type="number"
                  className="form-control"
                  placeholder="Masukan  20 (g/L)"
                  step="0.01"
                />
                {errors.dvs_20 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    20 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*     25 (g/L)    */}
              <div className="col-md-6">
                <label htmlFor="true" className="form-label">
                  25 (g/L)
                </label>
                <input
                  ref={register()}
                  name="diseaseDvs_25"
                  type="number"
                  className="form-control"
                  placeholder="Masukan   25 (g/L)"
                  step="0.01"
                />
                {errors.dvs_25 && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    25 (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*      Dosis rata-rata (g/L)    */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Dosis rata-rata (g/L)
                </label>
                <input
                  ref={register()}
                  name="diseaseAverage_dosis"
                  type="number"
                  className="form-control"
                  placeholder="Masukan    Dosis rata-rata (g/L)"
                  step="0.01"
                />
                {errors.average_dosis && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Dosis rata-rata (g/L) wajib diisi
                  </div>
                )}
              </div>

              {/*  Petunjuk Penggunaan  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Petunjuk Penggunaan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan  Petunjuk Penggunaan  disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseaseUse_instruction', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.use_instruction && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Gejala wajib diisi
                  </div>
                )}
              </div>

              {/*  Tingkat Berbahaya  */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Tingkat Berbahaya
                </label>
                <Select
                  options={danger}
                  closeMenuOnSelect={false}
                  value={opsDanger}
                  onChange={(params) => {
                    setOpsDanger(params);
                    setValue('diseaseDanger', params.value);
                  }}
                />
              </div>

              {/*   Tindak Pencegahan   */}
              <div className="col-md-12">
                <label htmlFor="true" className="form-label">
                  Tindak Pencegahan
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>Masukan   Tindak Pencegahan   disini</p>"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('diseasePrecaution', data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      'undo',
                      'redo',
                    ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                      ],
                    },
                  }}
                />
                {errors.precaution && (
                  <div className="pt-1" style={{ color: 'red' }}>
                    Tindak Pencegahan wajib diisi
                  </div>
                )}
              </div>

              {/* tombol */}
              <div className="col-md-12">
                <input
                  className="btn w-100 btn-primary px-5"
                  type="submit"
                  value="Tambah Artikel"
                />
              </div>
            </div>
          </form>
        </Card>
      </>
    );
  };

  const growthView = () => (
    <>
      {/* Data Pertumbuhan */}
      <Card title="Form Pertumbuhan">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="growth" />

            {/* Kebun */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Pilih Kebun
              </label>
              <Select
                options={dataFarm}
                value={opsFarm}
                onChange={(OpsFarm) => {
                  setOpsFarm(OpsFarm);
                  setValue('growthFarm', OpsFarm.id);
                }}
              />
            </div>

            {/* Nama Tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Tanaman
              </label>
              <Select
                options={dataOption}
                value={opsPlant}
                onChange={(params) => {
                  setOpsPlant(params);
                  setValue('growthPlant', params.value);
                }}
              />
            </div>

            {/* jenis tanaman */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Pilih Jenis Tanaman
              </label>
              <Select
                options={[
                  { value: 'sayuran', label: 'Sayuran' },
                  { value: 'buah', label: 'Buah' },
                ]}
                onChange={(params) => {
                  setValue('growthType', params.value);
                  if (params.value === 'sayuran') {
                    setTumbuhan([
                      'Tinggi (cm)',
                      'Diameter pangkal (cm)',
                      'Jumlah daun total (helai)',
                      'Jumlah daun sehat (helai)',
                      'Jumlah daun sakit (helai)',
                      'Panjang daun (cm)',
                      'Lebar daun (cm)',
                      'Indeks luas daun (cm²)',
                      'Diameter kanopi tanaman (cm)',
                      'Diameter crop (cm)',
                      'Tinggi crop (cm)',
                      'pH',
                      'N (mg)',
                      'P',
                      'K',
                      'Suhu (°C)',
                      'Nutrisi (ppm)',
                      'Kelembaban Udara (%)',
                      'Intensitas Cahaya (lux)',
                      'Tekanan (hPa)',
                      'Kelembaban Tanah (°C)',
                    ]);
                    setKonversi([
                      0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                      0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                    ]);
                  } else {
                    setTumbuhan([
                      'Tinggi (cm)',
                      'Diameter pangkal (cm)',
                      'Diameter 1 m dari media (cm)',
                      'Jumlah cabang (unit)',
                      'Jumlah daun total (helai)',
                      'Jumlah daun sehat (helai)',
                      'Jumlah daun rusak (helai)',
                      'Panjang daun (cm)',
                      'Lebar daun (cm)',
                      'Indeks luas daun (cm²)',
                      'Jumlah buah (buah)',
                      'Diameter buah (cm)',
                      'Panjang buah (cm)',
                      'Jumlah bunga (bunga)',
                      'pH',
                      'N (mg)',
                      'P',
                      'K',
                      'Suhu (°C)',
                      'Nutrisi (ppm)',
                      'Kelembaban Udara (%)',
                      'Intensitas Cahaya (lux)',
                      'Tekanan (hPa)',
                      'Kelembaban Tanah (°C)',
                    ]);
                    setKonversi([
                      0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                      0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                    ]);
                  }
                }}
              />
            </div>

            {/* hst */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                HST
              </label>
              <input
                ref={register({ required: true })}
                name="hst"
                type="number"
                className="form-control"
                placeholder="Masukan HST"
              />
              {errors.hst && (
                <div className="pt-1" style={{ color: 'red' }}>
                  hst wajib diisi
                </div>
              )}
            </div>

            {/* bedengan */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jumlah Bedengan
              </label>
              <input
                ref={register({ required: true })}
                name="bedengan"
                type="number"
                className="form-control"
                placeholder="Masukan bedengan"
              />
              {errors.bedengan && (
                <div className="pt-1" style={{ color: 'red' }}>
                  hst wajib diisi
                </div>
              )}
            </div>

            {/* baris */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jumlah Baris
              </label>
              <input
                ref={register({ required: true })}
                name="baris"
                type="number"
                className="form-control"
                placeholder="Masukan baris"
              />
              {errors.baris && (
                <div className="pt-1" style={{ color: 'red' }}>
                  baris wajib diisi
                </div>
              )}
            </div>

            {/* Awal Tanam */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Awal Tanam
              </label>
              <input
                ref={register({ required: true })}
                name="planted"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.planted && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Awal Tanam wajib diisi
                </div>
              )}
            </div>
            {/* Panen */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Panen
              </label>
              <input
                ref={register({ required: true })}
                name="harvest"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.harvest && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Panen wajib diisi
                </div>
              )}
            </div>

            {/* Note */}
            <div className="col-md-12">
              <label htmlFor="true" className="form-label">
                Catatan
              </label>
              <CKEditor
                editor={ClassicEditor}
                data="<p>Masukan Note disini</p>"
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue('growthNote', data);
                }}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                  ],
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3',
                      },
                    ],
                  },
                }}
              />
            </div>

            {parameter.map((x, i) => (
              <div key={x} className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <input
                      ref={register({ required: true })}
                      name={`parameter.${x}`}
                      type="text"
                      placeholder="Masukan Parameter"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-5">
                    <input
                      ref={register({ required: true })}
                      name={`convert.${x}`}
                      type="number"
                      step="0.001"
                      placeholder="Masukan Konversi"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={() => {
                        const listParameter = [...parameter];
                        listParameter.splice(i, 1);
                        setParameter(listParameter);
                      }}
                    >
                      Hapus
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {/* tombol tambah */}
            <div className="col-md-12">
              <button
                className="btn btn-primary px-5"
                type="button"
                onClick={() => {
                  setParameter([...parameter, parameter.length]);
                }}
              >
                Tambah
              </button>
            </div>
          </div>
          <br />
          {/* tombol */}
          <div className="col-md-12">
            <input
              className="btn w-100 btn-primary px-5"
              type="submit"
              value="Tambah Pertumbuhan"
            />
          </div>
        </form>
      </Card>
    </>
  );

  const growthAdd = () => (
    <>
      {/* Data Pertumbuhan */}
      <Card title="Form Pertumbuhan">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="growthadd" />
            {/* Panen */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Tanggal
              </label>
              <input
                ref={register({ required: true })}
                name="date"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.date && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Tanggal wajib diisi
                </div>
              )}
            </div>

            {dataParameter.map((x, i) => (
              <div key={x} className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="true" className="form-label">
                      {x}
                    </label>
                    <input
                      ref={register({ required: true })}
                      name={`parameter.${i}`}
                      type="number"
                      step="0.01"
                      placeholder="Masukan Parameter"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          {/* tombol */}
          <div className="col-md-12">
            <input className="btn w-100 btn-primary px-5" type="submit" value="Tambah Data" />
          </div>
        </form>
      </Card>
    </>
  );

  const growthAddImage = () => (
    <>
      {/* Data Pertumbuhan */}
      <Card title="Form Pertumbuhan">
        <form onSubmit={handleSubmit(storeData)}>
          <div className="row row-decks row-cards">
            <input ref={register} name="menu" type="hidden" value="growthimage" />
            {/* Panen */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Tanggal
              </label>
              <input
                ref={register({ required: true })}
                name="date"
                type="date"
                className="form-control"
                placeholder="Masukan tanggal"
              />
              {errors.date && (
                <div className="pt-1" style={{ color: 'red' }}>
                  Tanggal wajib diisi
                </div>
              )}
            </div>

            {dataParameter.map((x, i) => (
              <div key={x} className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="true" className="form-label">
                      {x}
                    </label>
                    <input
                      ref={register({ required: true })}
                      name={`parameter.${i}`}
                      type="number"
                      step="0.01"
                      placeholder="Masukan Parameter"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          {/* tombol */}
          <div className="col-md-12">
            <input className="btn w-100 btn-primary px-5" type="submit" value="Tambah Data" />
          </div>
        </form>
      </Card>
    </>
  );

  const betaView = () => (
    <>
      <Card className="d-flex align-items-center py-1">
        <p className="text-red">Under Construction</p>
      </Card>
    </>
  );

  const handleView = () => {
    switch (menu) {
      case 'article':
        return articleView();

      case 'disease':
        return diseaseView();

      case 'growth':
        switch (option) {
          case 'add':
            return articleView();
          case 'image':
            return growthAddImage();
          default:
            return growthView();
        }
      case 'plant':
        return plantView();

      case 'cultivation':
        return betaView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">
        {/* content */}
        {loading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
}

export default AgricultureAdd;
