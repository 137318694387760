import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import { newAPI } from '../../services/baseAPI';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

function FarmerShow() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchUsers = async () => {
    setIsLoading(true);
    await newAPI
      .get(`/v3/users/${id}/show`)
      .then((res) => {
        const dataUsers = res.data[0];
        setData(dataUsers);
      })
      .catch((error) => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/farmer')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}
          >
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <h2 className="page-title">Lihat Petani</h2>
          </div>
        </div>
      </div>
    </>
  );

  const ShowView = () => (
    <>
      <Card title="Form Petani">
        <div className="row row-decks row-cards">
          {/* First Name */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Nama Awal :</b>
              <p>{data.first_name}</p>
            </label>
          </div>
          {/* Last Name */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Nama Akhir :</b>
              <p>{data.last_name}</p>
            </label>
          </div>
          {/* Email */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Email :</b>
              <p>{data.email}</p>
            </label>
          </div>
          {/* Ulang tahun */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Tanggal Lahir :</b>
              <p>{moment(data.birth_date).format('DD-MM-YYYY')}</p>
            </label>
          </div>
          {/* jenis Kelamin */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Tanggal Lahir :</b>
              <p>{data.gender}</p>
            </label>
          </div>
          {/* TElepong */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>No Telepon :</b>
              <p>{data.phone}</p>
            </label>
          </div>
          {/* Kota */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Kota :</b>
              <p>{data.city}</p>
            </label>
          </div>
          {/* status */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <b>Status :</b>
              <p>{data.role_id === 1 && 'Admin'}</p>
              <p>{data.role_id === 2 && 'Owner'}</p>
              <p>{data.role_id === 3 && 'Petani'}</p>
              <p>{data.role_id === 4 && 'Penjual'}</p>
              {/* <p>{data.role_id === 5 && "Admin"}</p> */}
              <p>{data.role_id === 6 && 'Agronomist'}</p>
              <p>{data.role_id === 7 && 'Engineer & Mekanikal'}</p>
            </label>
          </div>
        </div>
      </Card>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          ShowView()
        )}
      </div>
    </div>
  );
}

export default FarmerShow;
